import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash';
import { TrashIcon } from '@heroicons/react/24/outline';
import { FeatureSpecial, MapSchema, RegistriesObjectState } from '@/types/types';
import { useDeleteRegistryByMapMutation } from '../../../../features/registry';
import Button from '../../../ui/general/Button';
import { actionsNotification } from '../../../../features/notificationSlice';

export default function MapFeatureOptions({
  map,
  selectedFeature,
  setSelectedFeature,
  registriesObject,
  setRegistriesObject,
}: {
  map: MapSchema,
  selectedFeature: FeatureSpecial | undefined;
  setSelectedFeature: Dispatch<SetStateAction<FeatureSpecial | undefined>>;
  registriesObject: RegistriesObjectState;
  setRegistriesObject: Dispatch<SetStateAction<RegistriesObjectState>>;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);

  const [deleteRegistryByMap, { data, isLoading, isError }] = useDeleteRegistryByMapMutation();

  useEffect(() => {
    if (isError) {
      dispatch(actionsNotification.createAlert({ message: t('RECORD_HAS_NOT_BEEN_DELETED'), type: 'error' }));
    }
  }, [dispatch, isError, t]);

  useEffect(() => {
    if (isLoadingPrev && !isLoading && !isError && data) {
      dispatch(actionsNotification.createAlert({ message: t('RECORD_HAS_NOT_BEEN_DELETED'), type: 'success' }));
      setSelectedFeature(undefined);

      const temp = {...registriesObject};
      if (temp && temp[data.geojson]) {
        remove(temp[data.geojson], (o) => o.id === data.id);
        setRegistriesObject({...temp});
      }
    }
    setIsLoadingPrev(isLoading);
  }, [isLoading, isLoadingPrev, isError, data, setSelectedFeature, registriesObject, setRegistriesObject, dispatch, t]);

  const deleteRegistry = () => {
    if (map.isExample) {
      dispatch(actionsNotification.createAlert({ message: t('FEATURE_NOT_AVAILABLE_SAMPLE'), type: 'warning' }));
      return;
    }

    if (selectedFeature) {
      deleteRegistryByMap({
        mapId: map.id,
        geojsonId: selectedFeature.geojson,
        registryId: selectedFeature.id,
      });
    }
  }

  return (
    <Fragment>
      {
        (selectedFeature) &&
        <Button
          size="small"
          color="red"
          onClick={() => {
            deleteRegistry();
          }}
        >
          <TrashIcon className="w-4 h-4 mr-1" /> {t('DELETE')} {t('RECORD')}
        </Button>
      }
    </Fragment>
  );
}
