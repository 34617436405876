import cx from 'clsx';

export default function Input({
  value,
  id,
  label,
  type = 'text',
  disabled = false,
  placeholder = '',
  onChange,
}: {
  value: string;
  id: string;
  label: string;
  type?: 'text' | 'password';
  disabled?: boolean;
  placeholder?: string;
  onChange: any;
}) {

  return (
    <div className="flex flex-col gap-1">
      <label
        className="text-base text-gray-700"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        id={id}
        value={value}
        className={cx(
          'text-base relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700',
          'placeholder-gray-500 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500',
          disabled ? 'bg-slate-100' : '',
        )}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}
