import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyGetOrthophotoQuery, useCreateOrthophotoMutation, useUpdateOrthophotoMutation } from '../../../features/orthophoto';
import { useGetProjectQuery } from '../../../features/project';
import { actionsNotification } from '../../../features/notificationSlice';
import Breadcrumbs from '../../global/Breadcrumbs';
import Toggle from '../../ui/data-entry/Toggle';
import InputHook from '../../ui/data-entry/InputHook';
import Tabs from '../../ui/data-display/Tabs';
import BoardFooterBar from '../common/BoardFooterBar';
import BoardContent from '../common/BoardContent';
import BoardCard from '../common/BoardCard';
import BoardHeader from '../common/BoardHeader';

export default function Orthophoto() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { projectId, orthophotoId } = useParams();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [isCreatingPrev, setIsCreatingPrev] = useState<boolean>(false);
  const [isFetchingPrev, setIsFetchingPrev] = useState<boolean>(false);
  const [isUpdatingPrev, setIsUpdatingPrev] = useState<boolean>(false);
  const [skipGetOrthophoto, setSkipGetOrthophoto] = useState<boolean>(true);
  const [tms, setTms] = useState<boolean>(false);
  const [isProtected, setIsProtected] = useState<boolean>(false);

  const [createOrthophoto, { isLoading: isCreating, isError: creatingIsError }] = useCreateOrthophotoMutation();
  const [updateOrthophoto, { isLoading: isUpdating, isError: updatingIsError }] = useUpdateOrthophotoMutation();

  const { data: project } = useGetProjectQuery({ projectId });
  const [getOrthophoto, { data, isFetching, isLoading, isError: fetchingIsError }] = useLazyGetOrthophotoQuery();
  
  const isAnyLoading = useMemo<boolean>(() => (isFetching || isCreating || isUpdating || isLoading), [isFetching, isCreating, isUpdating, isLoading]);

  useEffect(() => {
    if (orthophotoId && projectId) {
      setSkipGetOrthophoto(false);
    } else {
      setSkipGetOrthophoto(true);
    }
  }, [orthophotoId, projectId]);

  useEffect(() => {
    if (skipGetOrthophoto === false) {
      getOrthophoto({ orthophotoId, projectId });
    }
  }, [getOrthophoto, orthophotoId, skipGetOrthophoto, projectId]);

  useEffect(() => {
    if (!isCreating && isCreatingPrev && !creatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('ORTHOPHOTO_ADDED_SUCCESSFULLY'), type: 'success' }));
      navigate(`/board/${projectId}/${t('SLUG_ORTHOPHOTOS')}`);
      return;
    }
    setIsCreatingPrev(isCreating);
  }, [isCreating, projectId, isCreatingPrev, creatingIsError, navigate, dispatch, t]);

  useEffect(() => {
    if (!isUpdating && isUpdatingPrev && !updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('ORTHOPHOTO_UPDATED_SUCCESSFULLY'), type: 'success' }));
      navigate(`/board/${projectId}/${t('SLUG_ORTHOPHOTOS')}`); 
      return;
    }
    setIsUpdatingPrev(isUpdating);
  }, [isUpdating, projectId, isUpdatingPrev, updatingIsError, navigate, dispatch, t]);

  useEffect(() => {
    if (!isFetching && isFetchingPrev && !fetchingIsError) {
      setValue('name', data.name);
      setValue('url', data.url);
      setTms(data.tms);
      setIsProtected(data.isProtected);
    }
    setIsFetchingPrev(isFetching);
  }, [isFetching, data, isFetchingPrev, fetchingIsError, orthophotoId, setValue]);

  useEffect(() => {
    if (fetchingIsError || creatingIsError || updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
    }
  }, [fetchingIsError, creatingIsError, updatingIsError, dispatch, t]);

  const onFinish = async (fieldValues: FieldValues) => {
    if (orthophotoId) {
      updateOrthophoto({
        id: orthophotoId,
        name: fieldValues.name,
        url: fieldValues.url,
        tms,
        bar: fieldValues.bar ?? '',
        barLeft: fieldValues.barLeft ?? '',
        barRight: fieldValues.barRight ?? '',
        isProtected,
        projectId,
      });
    } else {
      createOrthophoto({
        name: fieldValues.name,
        url: fieldValues.url,
        tms,
        bar: fieldValues.bar ?? '',
        barLeft: fieldValues.barLeft ?? '',
        barRight: fieldValues.barRight ?? '',
        isProtected,
        projectId,
      });
    }
  };

  const tabItems = [
    {
      label: 'General',
      key: 'general',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
            <div>
              <InputHook
                register={{
                  ...register('name', {
                    required: true,
                    maxLength: 256,
                    minLength: 2,
                  }),
                }}
                label={t('NAME')}
                disabled={isAnyLoading}
                errors={errors}
              />
            </div>
            <div>
              <InputHook
                register={{
                  ...register('url', {
                    required: true,
                    maxLength: 256,
                    minLength: 2,
                  }),
                }}
                label="URL"
                disabled={isAnyLoading}
                errors={errors}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-base text-gray-700">
                TMS
              </label>
              <Toggle enabled={tms} setEnabled={setTms} disabled={isAnyLoading} />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-base text-gray-700">
                {t('PROTECTED')}
              </label>
              <Toggle enabled={isProtected} setEnabled={setIsProtected} disabled={isAnyLoading} />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(project) ? project.name : ''}`, current: false },
          { name: t('ORTHOPHOTOS'), to: `/board/${projectId}/orthophotos`, current: false },
          { name: orthophotoId ? t('EDIT') : t('ADD'), current: true },
        ]}
      />
      <BoardHeader
        title={(orthophotoId) ? `${t('EDIT')} ${t('ORTHOPHOTO')}` : `${t('ADD')} ${t('ORTHOPHOTO')}`}
        description={(orthophotoId) ? t('BOARD_ORTHOPHOTO_EDIT_PAGE_DESCRIPTION') : t('BOARD_ORTHOPHOTO_CREATE_PAGE_DESCRIPTION')}
      />
      <form onSubmit={handleSubmit((data) => onFinish(data))}>
        <BoardContent>
          <BoardCard>
            <Tabs defaultActiveKey="general" items={tabItems} />
          </BoardCard>
        </BoardContent>
        <BoardFooterBar
          onClick={() => { }}
          disabled={isAnyLoading}
          isLoading={isAnyLoading}
        >
          {orthophotoId ? t('UPDATE') : t('CREATE')} {t('ORTHOPHOTOS')}
        </BoardFooterBar>
      </form>
    </>
  );
}
