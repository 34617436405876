import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FeatureSpecial, MapSchema } from '@/types/types';
import { useGeneratePresignedUrlToPutObjectMutation } from '../../../../features/aws';
import { useCreateRegistryDocumentByMapMutation } from '../../../../features/registry';
import { actionsNotification } from '../../../../features/notificationSlice';
import Button from '../../../ui/general/Button';
import Input from '../../../ui/data-entry/Input';
import Modal from '../../../ui/feedback/Modal';
import { InboxIcon } from '@heroicons/react/24/outline';

export default function AddRegistryFileModal({
  open,
  setOpen,
  selectedFeature,
  map,
  handleOk,
  onCancel,
} : {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedFeature: FeatureSpecial | undefined;
  map: MapSchema;
  handleOk: (val: any) => void;
  onCancel: () => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [file, setFile] = useState<File>();
  const [name, setName] = useState<string>('');
  const [isLoadingPresignedUrlPrev, setIsLoadingPresignedUrlPrev] = useState<boolean>(false);
  const [isLoadingFilePrev, setIsLoadingFilePrev] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isLoadingAws, setIsLoadingAws] = useState<boolean>(false);

  const [generatePresignedUrlToPutObject, { data: dataPresignedUrl, isLoading: isLoadingPresignedUrl, isError: isErrorPresignedUrl }] = useGeneratePresignedUrlToPutObjectMutation();
  const [createRegistryDocumentByMap, { data: dataFile, isLoading: isLoadingFile, isError: isErrorFile }] = useCreateRegistryDocumentByMapMutation();

  const isAnyLoading = useMemo<boolean>(() => (isLoadingPresignedUrl || isLoadingFile || isLoadingAws), [isLoadingPresignedUrl, isLoadingFile, isLoadingAws]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0])
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const close = useCallback(() => {
    if (!isAnyLoading) {
      setFile(undefined);
      setUploading(false);
      setName('');
      setIsLoadingAws(false);
      onCancel();
    } else {
      dispatch(actionsNotification.createAlert({ message: t('WINDOWS_CANNOT_BE_CLOSED_WHILE_LOADING'), type: 'warning' }));
    }
  }, [isAnyLoading, onCancel, dispatch, t]);

  useEffect(() => {
    if (!open) {
      close();
    }
  }, [open, close]);

  const forceClose = useCallback(() => {
    setFile(undefined);
    setUploading(false);
    setName('');
    setIsLoadingAws(false);
    onCancel();
  }, [onCancel]);

  const handleLoadFile = useCallback((event: any) => {
    if (event.target.status !== 200) {
      dispatch(actionsNotification.createAlert({ message: t('DOCUMENT_NOT_SAVED'), type: 'error' }));
      setIsLoadingAws(false);
      forceClose();
      return;
    }
    if (selectedFeature && dataPresignedUrl) {
      createRegistryDocumentByMap({
        name: name,
        mapId: map.id,
        geojsonId: selectedFeature.geojson,
        registryId: selectedFeature.id,
        path: dataPresignedUrl.myKey,
      });
    }
    setIsLoadingAws(false);
  }, [selectedFeature, dataPresignedUrl, dispatch, t, forceClose, createRegistryDocumentByMap, name, map.id]);

  const handleErrorFile = useCallback(() => {
    dispatch(actionsNotification.createAlert({ message: t('DOCUMENT_NOT_SAVED'), type: 'error' }));
    setIsLoadingAws(false);
    forceClose();
  }, [dispatch, forceClose, t]);

  const handleAbortFile = useCallback(() => {
    dispatch(actionsNotification.createAlert({ message: t('DOCUMENT_NOT_SAVED'), type: 'error' }));
    setIsLoadingAws(false);
    forceClose();
  }, [dispatch, forceClose, t]);

  const handleProgressFile = () => {

  }

  const uploadFileToS3 = useCallback((url: string, contentType: string) => {
    setIsLoadingAws(true);
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.setRequestHeader('Content-Type', contentType);
    xhr.addEventListener('progress', handleProgressFile);
    xhr.addEventListener('load', handleLoadFile);
    xhr.addEventListener('error', handleErrorFile);
    xhr.addEventListener('abort', handleAbortFile);
    xhr.send(file);
    setIsLoadingAws(true);
  }, [file, handleAbortFile, handleErrorFile, handleLoadFile]);

  const handleSubirClick = () => {
    if (file) {
      if (selectedFeature && selectedFeature.id && selectedFeature.geojson) {
        setUploading(true);
        generatePresignedUrlToPutObject({
          name: file.name,
          mapId: map.id,
          geojsonId: selectedFeature.geojson,
          registryId: selectedFeature.id,
          mimeType: file.type,
        });
      } else {
        dispatch(actionsNotification.createAlert({ message: t('RECORD_NOT_SELECTED'), type: 'warning' }));
        forceClose();
      }
    }
  }

  useEffect(() => {
    if (!isLoadingPresignedUrl && isLoadingPresignedUrlPrev) {
      if (isErrorPresignedUrl) {
        dispatch(actionsNotification.createAlert({ message: t('ERROR_UPLOADING_THE_DOCUMENT'), type: 'error' }));
        forceClose();
      } else {
        uploadFileToS3(dataPresignedUrl.presignedUrl, dataPresignedUrl.contentType);
      }
    }
    setIsLoadingPresignedUrlPrev(isLoadingPresignedUrl);
  }, [isLoadingPresignedUrl, isLoadingPresignedUrlPrev, dataPresignedUrl, uploadFileToS3, isErrorPresignedUrl, forceClose, dispatch, t]);

  useEffect(() => {
    if (!isLoadingFile && isLoadingFilePrev) {
      if (isErrorFile) {
        dispatch(actionsNotification.createAlert({ message: t('ERROR_UPLOADING_THE_DOCUMENT'), type: 'error' }));
        forceClose();
      } else {
        dispatch(actionsNotification.createAlert({ message: t('DOCUMENT_UPLOADED_SUCCESSFULLY'), type: 'success' }));
        handleOk(dataFile);
        forceClose();
      }
    }
    setIsLoadingFilePrev(isLoadingFile);
  }, [isLoadingFile, isLoadingFilePrev, isErrorFile, dataFile, forceClose, handleOk, dispatch, t]);

  useEffect(() => {
    if (open) {
      setFile(undefined);
      setUploading(false);
      setName('');
      setIsLoadingAws(false);
    }
  }, [open]);

  return (
    <Modal
      title={`${t('UPLOAD')} ${t('DOCUMENT')}`}
      isOpen={open}
      setIsOpen={setOpen}
      width={600}
    >
      <div className="">
        {
          (!uploading) &&
          <Fragment>
            <div className="mb-1">{t('NAME')}:</div>
            <div className="mb-4">
              <Input
                id="name"
                label={t('NAME')}
                value={name}
                onChange={(e: any) => { setName(e.target.value); }}
                placeholder={t('DOCUMENT_S_NAME') as string}
              />
            </div>
            {
              (!file) &&
              <div {...getRootProps({ className: cx(
                'flex flex-col items-center p-5 border-2 rounded-[6px] border-[#EEEEEE] border-dashed bg-[#FAFAFA] text-[#BDBDBD] outline-none transition-all',
                isDragActive ? 'border-[#1890FF]' : '',
              )})}>
                <input {...getInputProps()} />
                <p className="py-[12px]">
                  <InboxIcon className="w-10" style={{ color: '#40a9ff' }} />
                </p>
                {
                  (isDragActive) ?
                  <div>{t('CLICK_OR_DRAG_THE_DOCUMENT')}</div> :
                  <div>{t('CLICK_OR_DRAG_THE_DOCUMENT')}</div>
                }
              </div>
            }
            {
              (file) &&
              <div>{t('SELECTED_DOCUMENT')}: {file.name}</div>
            }
            <Button
              size="small"
              disabled={!name || !file}
              className="mt-4"
              onClick={() => { handleSubirClick(); }}
            >
              {t('UPLOAD')}
            </Button>
          </Fragment>
        }
        {
          (uploading) &&
          <div className="">
            <div>{(true) ? `${t('UPLOADING')}...` : t('DOCUMENT_UPLOADED_SUCCESSFULLY')}</div>
          </div>
        }
      </div>
    </Modal>
  );
}
