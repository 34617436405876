import cx from 'clsx';

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

export default function CheckboxGroup({
  options,
  disabled,
  onChange,
  value,
}: {
  options: Option[];
  disabled: boolean;
  onChange: (e: string[]) => void;
  value: string[];
}) {
  return (
    <div className="flex gap-5 items-center">
      {
        options.map((o: Option) => (
          <div
            className={
              cx(
                'flex items-center gap-2',
                disabled || o.disabled ? 'text-gray-300' : '',
              )
            }
          >
            <input
              className={
                cx('form-checkbox rounded', disabled || o.disabled ? 'text-gray-300 bg-gray-100 border-gray-200' : 'text-blue-600')
              }
              checked={value.includes(o.value)}
              disabled={disabled || o.disabled}
              type="checkbox"
              onChange={() => {
                if (!value.includes(o.value)) {
                  onChange([...value, o.value]);
                } else {
                  onChange([...value.filter((u => u !== o.value))]);
                }
              }}
            />
            {o.label}
          </div>
        ))
      }
    </div>
  );
}
