import cx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function Table({
  columns,
  dataSource,
  isLoading,
  className,
}: {
  columns: any[];
  dataSource: any[];
  isLoading: boolean;
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-2">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-2">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {
                    columns && columns.map(({ title }, index) => {
                      if (index === columns.length - 1) {
                        return (
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">{t('ACTIONS')}</span>
                          </th>
                        );
                      }
                      return (
                        <th
                          scope="col"
                          className={cx(
                            'py-3.5 text-left text-sm font-semibold text-gray-900',
                            index === 0 ? 'pl-4 pr-3 sm:pl-6' : 'px-3',
                          )}
                        >
                          {title}
                        </th>
                      );
                    })
                  }
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {
                  dataSource && dataSource.map((fields: any, index) => {
                    return (
                      <tr key={fields.email}>
                        {
                          columns.map(({ areActions, render }, idx2) => {
                            const isFirstRow = idx2 === 0;
                            return (
                              <td className={cx(
                                'whitespace-nowrap py-4 text-sm',
                                (!isFirstRow && !areActions) && 'px-3 text-gray-500',
                                (isFirstRow) && 'pl-4 pr-3 font-medium text-gray-900 sm:pl-6',
                                (areActions) && 'relative pl-3 pr-4 text-right font-medium sm:pr-6 flex gap-2 justify-end',
                              )}>
                                {render(fields, {}, index) || <i>{t('NO_DATA')}</i>}
                              </td>
                            );
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
