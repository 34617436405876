import { useState, useEffect, Fragment, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { unionBy, isEqual } from 'lodash';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useCreateNoteMutation, useLazyGetNotesByMapQuery } from '../../../features/note';
import CreateNoteModal from '../../global-modals/CreateNoteModal';
import { MapSchema, NoteSchema, UserSchema } from '@/types/types';
import { actionsNotification } from '../../../features/notificationSlice';
import Button from '../../ui/general/Button';
import Drawer from '../../ui/feedback/Drawer';

export default function MapNotes({
  visible,
  onClose,
  map,
}: {
  visible: boolean;
  onClose: () => void;
  map: MapSchema;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [createNoteModalVisible, setCreateNoteModalVisible] = useState<boolean>(false);
  const [isCreatingPrev, setIsCreatingPrev] = useState<boolean>(false);
  const [notesIsFetchingPrev, setNotesIsFetchingPrev] = useState<boolean>(false);
  const [mapNotesOpened, setMapNotesOpened] = useState<boolean>(false);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
  const [list, setList] = useState<NoteSchema[]>([]);
  const [listPrev, setListPrev] = useState<NoteSchema[] | null>(null);
  const [dataPrev, setDataPrev] = useState<NoteSchema[] | null>(null);

  const [getNotesByMap, { data, isFetching: notesIsFetching, isLoading: notesIsLoading, isError: fetchingIsError }] = useLazyGetNotesByMapQuery();
  const [createNote, { data: dataCreated, isLoading: isCreating, isError: creatingIsError }] = useCreateNoteMutation();

  const isAnyLoading = useMemo<boolean>(() => (isCreating || notesIsFetching || notesIsLoading), [isCreating, notesIsFetching, notesIsLoading]);

  useEffect(() => {
    if (!notesIsFetching && notesIsFetchingPrev && !fetchingIsError) {
      if (data && data.length < 10) {
        setShowLoadMore(false);
      }
    }
    setNotesIsFetchingPrev(notesIsFetching);
  }, [notesIsFetching, notesIsFetchingPrev, fetchingIsError, data]);

  useEffect(() => {
    if (!isCreating && isCreatingPrev && !creatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('NOTE_ADDED_SUCCESSFULLY'), type: 'success' }));
      const tempList = [dataCreated, ...list];
      setList(tempList);
      setCreateNoteModalVisible(false);
    }
    setIsCreatingPrev(isCreating);
  }, [isCreating, isCreatingPrev, creatingIsError, dataCreated, list, data, dispatch, t]);

  useEffect(() => {
    if (creatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('AN_ERROR_OCURRED_WHILE_REGISTERING_THE_NOTE'), type: 'error' }));
    }
  }, [creatingIsError, dispatch, t]);

  useEffect(() => {
    if (mapNotesOpened && map && map.id) {
      getNotesByMap({ mapId: map.id });
    }
  }, [mapNotesOpened, getNotesByMap, map]);

  useEffect(() => {
    const checkEquals = listPrev !== null && isEqual(list, listPrev);
    const checkEquals2 = dataPrev !== null && isEqual(data, dataPrev);
    if (data && data.length > 0 && (!checkEquals || !checkEquals2)) {
      let tempList = unionBy(list, data, 'id');
      tempList.sort(function(a, b) {
        return (a.createdAt > b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);
      });
      setListPrev([...list]);
      setList([...tempList]);
      setDataPrev([...data]);
    }
  }, [data, dataPrev, list, listPrev]);

  useEffect(() => {
    if (visible === true) {
      setMapNotesOpened(true);
    }
  }, [visible]);

  return (
    <Fragment>
      <Drawer
        width={640}
        placement="right"
        closable={false}
        isOpen={visible}
        onClose={onClose}
      >
        <div className="">
          <div className="text-lg font-medium text-gray-700">
            {t('NOTES')}
          </div>
          <Button
            className="mt-3"
            size="small"
            onClick={() => { setCreateNoteModalVisible(true); }}
          >
            {t('ADD')} {t('NOTE')}
          </Button>
          <div className="mt-8 flex flex-col gap-4">
            {
              (list && list.length > 0) &&
              list.map((o: NoteSchema, index) =>
                <div
                  key={index}
                  className="flex"
                >
                  <div className="w-9 h-9 text-white rounded-full bg-orange-500 flex items-center justify-center">
                    {(o && o.user && (o.user as UserSchema).displayName && (o.user as UserSchema).displayName[0]) && (o.user as UserSchema).displayName[0].toUpperCase()}
                  </div>
                  <div className="ml-2">
                    <div className="text-base font-semibold">{(o.user as UserSchema).displayName} <span className="ml-1 text-sm font-normal">{moment(o.createdAt).format('LLL')}</span></div>
                    <div className="mt-1">
                      {o.content}
                    </div>
                  </div>
                </div>
              )
            }
            {
              (list && list.length <= 0) &&
              <div>{t('THERE_ARE_NO_NOTES')}</div>
            }
          </div>
          <div className="mt-8">
            {
              (isAnyLoading) &&
              <ArrowPathIcon className="w-5 h-5 animate-spin" />
            }
            {
              (!isAnyLoading && (list && list.length > 0) && showLoadMore) &&
              <Button
                size="small"
                onClick={() => { getNotesByMap({ mapId: map.id, query: { skip: list.length } }); }}
              >
                {t('LOAD_MORE')}
              </Button>
            }
          </div>
        </div>
      </Drawer>
      <CreateNoteModal
        visible={createNoteModalVisible}
        handleOk={(content: any) => {
          if (map.isExample) {
            dispatch(actionsNotification.createAlert({ message: t('FEATURE_NOT_AVAILABLE_SAMPLE'), type: 'warning' }));
            return;
          }
          createNote({ content, map: map.id });
        }}
        handleCancel={() => { setCreateNoteModalVisible(false); }}
        isLoading={isAnyLoading}
      />
    </Fragment>
  );
}
