import { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { MapIcon } from '@heroicons/react/24/outline';
import { MapSchema } from '@/types/types';
import { onlyOwnerOrAdmin } from '../../../utils/util';
import { useGetMapsQuery } from '../../../features/map';
import { useGetProjectQuery } from '../../../features/project';
import { RootState } from '../../../store';
import Breadcrumbs from '../../global/Breadcrumbs';
import Table from '../../ui/data-display/Table';
import BoardContent from '../common/BoardContent';
import BoardEmpty from '../common/BoardEmpty';
import BoardHeader from '../common/BoardHeader';

export default function Maps() {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const { data, isFetching } = useGetMapsQuery({ projectId });
  const { data: project } = useGetProjectQuery({ projectId });

  const canCreateMap = useMemo(() => onlyOwnerOrAdmin(user, project), [user, project]);
  const canUpdateMap = useMemo(() => onlyOwnerOrAdmin(user, project) && project && !project.isExample, [user, project]);
  const hasData = useMemo(() => (data && data.length > 0), [data]);
  const isEmpty = useMemo(() => (data && data.length <= 0), [data]);
  const maps = useMemo(() => [...map(data, o => ({...o, key: o.id}))], [data]);
  const isAnyLoading = useMemo<boolean>(() => (isFetching), [isFetching]);

  const columns = [
    {
      title: t('NAME'),
      key: 'name',
      render: (record: MapSchema) => {
        return (record.name);
      }
    },
    {
      title: t('DESCRIPTION'),
      key: 'description',
      render: (record: MapSchema) => {
        return (record.description);
      }
    },
    {
      title: t('ACTIONS'),
      key: 'action',
      areActions: true,
      render: (record: MapSchema) => (
        <>
          <Link className="text-blue-600 hover:text-blue-900" to={`/mapa/${record.slug}`}>
            {t('VIEW')}
          </Link>
          {
            (canUpdateMap) &&
            <Link className="text-blue-600 hover:text-blue-900" to={`/board/${projectId}/${t('SLUG_MAPS')}/${t('SLUG_EDIT')}/${record.id}`}>
              {t('EDIT')}
            </Link>
          }
        </>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(project) ? project.name : ''}`, current: false },
          { name: t('MAPS'), current: true },
        ]}
      />
      {
        (hasData) &&
        <BoardHeader
          title={t('MAPS')}
          description={t('BOARD_MAPS_PAGE_DESCRIPTION')}
          link={(canCreateMap) ? `/board/${projectId}/${t('SLUG_MAPS')}/${t('SLUG_ADD')}` : ''}
          linkText={(canCreateMap) ? `${t('ADD')} ${t('MAP')}` : ''}
        />
      }
      <BoardContent>
        {
          (hasData) &&
          <Table
            columns={columns}
            dataSource={maps}
            isLoading={isAnyLoading}
          />
        }
        {
          (isEmpty) &&
          <BoardEmpty
            icon={<MapIcon className="mx-auto h-12 w-12 text-gray-400" />}
            title="Sin mapas"
            description="Comienza creando un nuevo mapa."
            buttonText="Nuevo mapa"
            buttonAction={() => { navigate(`/board/${projectId}/${t('SLUG_MAPS')}/${t('SLUG_ADD')}`); }}
            canCreate={canCreateMap}
          />
        }
      </BoardContent>
    </>
  );
}
