import { useState, useEffect, SetStateAction, Dispatch, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cx from 'clsx';
import { find, filter } from 'lodash';
import { FieldValues, useForm } from 'react-hook-form';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { ArrowPathIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useGetGeojsonsQuery } from '../../../features/geojson';
import { useGetOrthophotosQuery } from '../../../features/orthophoto'
import { useLazyGetMapQuery, useCreateMapMutation, useUpdateMapMutation } from '../../../features/map';
import { useGetProjectQuery } from '../../../features/project';
import GeojsonSelectModal from '../../global-modals/GeojsonSelectModal';
import OrthophotoSelectModal from '../../global-modals/OrthophotoSelectModal';
import UserSelectModal from '../../global-modals/UserSelectModal';
import { GeojsonSchema, OrthophotoSchema, UserSchema } from '@/types/types';
import { useGetUsersForMapQuery } from '../../../features/user';
import { actionsNotification } from '../../../features/notificationSlice';
import Breadcrumbs from '../../global/Breadcrumbs';
import Button from '../../ui/general/Button';
import InputHook from '../../ui/data-entry/InputHook';
import Input from '../../ui/data-entry/Input';
import Tabs from '../../ui/data-display/Tabs';
import Toggle from '../../ui/data-entry/Toggle';
import BoardFooterBar from '../common/BoardFooterBar';
import BoardContent from '../common/BoardContent';
import BoardCard from '../common/BoardCard';
import CaretUpIcon from '../../icons/CaretUpIcon';
import CaretDownIcon from '../../icons/CaretDownIcon';
import BoardHeader from '../common/BoardHeader';

export default function Map() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { projectId, mapId } = useParams();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [isCreatingPrev, setIsCreatingPrev] = useState<boolean>(false);
  const [isFetchingPrev, setIsFetchingPrev] = useState<boolean>(false);
  const [isUpdatingPrev, setIsUpdatingPrev] = useState<boolean>(false);
  const [orthophotos, setOrthophotos] = useState<string[]>([]);
  const [geojsons, setGeojsons] = useState<string[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const [skipGetMap, setSkipGetMap] = useState<boolean>(true);
  const [latitude, setLatitude] = useState<number>(19.432608);
  const [longitude, setLongitude] = useState<number>(-99.133209);
  const [activeGeojsons, setActiveGeojsons] = useState<string[]>([]);
  const [activeOrthophotos, setActiveOrthophotos] = useState<string[]>([]);
  const [selectGeojsonModalVisible, setSelectGeojsonModalVisible] = useState<boolean>(false);
  const [selectOrthophotoModalVisible, setSelectOrthophotoModalVisible] = useState<boolean>(false);
  const [selectUserModalVisible, setSelectUserModalVisible] = useState<boolean>(false);
  const [maxZoom, setMaxZoom] = useState<number>(22);
  const [minZoom, setMinZoom] = useState<number>(8);
  const [defaultZoom, setDefaultZoom] = useState<number>(15);
  const [triggerViewporReset, setTriggerViewporReset] = useState<number>(0);

  const { data: project } = useGetProjectQuery({ projectId });
  const [getMap, { data, isFetching, isLoading, isError: fetchingIsError, error: fetchingError }] = useLazyGetMapQuery();
  const { data: dataOrthophotos, isFetching: isFetchingOrthophotos } = useGetOrthophotosQuery({ projectId });
  const { data: dataGeojsons, isFetching: isFetchingGeojsons } = useGetGeojsonsQuery({ projectId });
  const { data: dataUsers, isFetching: isFetchingUsers } = useGetUsersForMapQuery({ projectId });

  const [createMap, { data: createdMapData, isLoading: isCreating, isError: creatingIsError, error: creatingError }] = useCreateMapMutation();
  const [updateMap, { data: updatedMapData, isLoading: isUpdating, isError: updatingIsError, error: updatingError }] = useUpdateMapMutation();

  const isAnyLoading = useMemo<boolean>(() => (isFetching || isCreating || isUpdating || isLoading), [isFetching, isCreating, isUpdating, isLoading]);

  useEffect(() => {
    if (mapId && projectId) {
      setSkipGetMap(false);
    } else {
      setSkipGetMap(true);
    }
  }, [mapId, projectId]);

  useEffect(() => {
    if (skipGetMap === false) {
      getMap({ mapId, projectId });
    }
  }, [skipGetMap, getMap, mapId, projectId]);

  useEffect(() => {
    if (!isCreating && isCreatingPrev && !creatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('MAP_ADDED_SUCCESSFULLY'), type: 'success' }));
      navigate(`/board/${projectId}/${t('SLUG_MAPS')}`);
    }
    setIsCreatingPrev(isCreating);
  }, [isCreating, projectId, isCreatingPrev, creatingIsError, navigate, createdMapData, dispatch, t]);

  useEffect(() => {
    if (!isUpdating && isUpdatingPrev && !updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('MAP_UPDATED_SUCCESSFULLY'), type: 'success' }));
      navigate(`/board/${projectId}/${t('SLUG_MAPS')}`);
    }
    setIsUpdatingPrev(isUpdating);
  }, [isUpdating, projectId, isUpdatingPrev, updatingIsError, navigate, updatedMapData, dispatch, t]);

  useEffect(() => {
    if (!isFetching && isFetchingPrev && !fetchingIsError) {
      setValue('name', data.name);
      setValue('description', data.description);
      setLongitude(data.longitude);
      setLatitude(data.latitude);
      setGeojsons(data.geojsons);
      setOrthophotos(data.orthophotos);
      setUsers(data.users);
      setDefaultZoom(data.defaultZoom);
      setMinZoom(data.minZoom);
      setMaxZoom(data.maxZoom);
      setActiveGeojsons((data.activeGeojsons) ? data.activeGeojsons : []);
      setActiveOrthophotos((data.activeOrthophotos) ? data.activeOrthophotos : []);
    }
    setIsFetchingPrev(isFetching);
  }, [isFetching, data, isFetchingPrev, fetchingIsError, mapId, setValue]);

  useEffect(() => {
    if (fetchingIsError) {
      if (fetchingError) {
        if ('status' in fetchingError) {
          const errMsg = 'error' in fetchingError ? fetchingError.error : JSON.stringify(fetchingError.data);
          dispatch(actionsNotification.createAlert({ message: errMsg, type: 'error' }));
        } else {
          dispatch(actionsNotification.createAlert({ message: fetchingError.message, type: 'error' }));
        }
      } else {
        dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      }
    }
  }, [fetchingIsError, fetchingError, dispatch, t]);

  useEffect(() => {
    if (creatingIsError) {
      if (creatingError) {
        if ('status' in creatingError) {
          const errMsg = 'error' in creatingError ? creatingError.error : JSON.stringify(creatingError.data);
          dispatch(actionsNotification.createAlert({ message: errMsg, type: 'error' }));
        } else {
          dispatch(actionsNotification.createAlert({ message: creatingError.message, type: 'error' }));
        }
      } else {
        dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      }
    }
  }, [creatingIsError, creatingError, dispatch, t]);

  useEffect(() => {
    if (updatingIsError) {
      if (updatingError) {
        if ('status' in updatingError) {
          const errMsg = 'error' in updatingError ? updatingError.error : JSON.stringify(updatingError.data);
          dispatch(actionsNotification.createAlert({ message: errMsg, type: 'error' }));
        } else {
          dispatch(actionsNotification.createAlert({ message: updatingError.message, type: 'error' }));
        }
      } else {
        dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      }
    }
  }, [updatingIsError, updatingError, dispatch, t]);

  const onFinish = (fieldValues: FieldValues) => {
    if (mapId) {
      updateMap({
        id: mapId,
        name: fieldValues.name,
        description: fieldValues.description,
        latitude,
        longitude,
        defaultZoom,
        maxZoom,
        minZoom,
        projectId,
        orthophotos: JSON.stringify(orthophotos),
        geojsons: JSON.stringify(geojsons),
        users: JSON.stringify(users),
        activeGeojsons: JSON.stringify(activeGeojsons),
        activeOrthophotos: JSON.stringify(activeOrthophotos),
      });
    } else {
      createMap({
        name: fieldValues.name,
        description: fieldValues.description,
        latitude,
        longitude,
        defaultZoom,
        maxZoom,
        minZoom,
        projectId,
        orthophotos: JSON.stringify(orthophotos),
        geojsons: JSON.stringify(geojsons),
        users: JSON.stringify(users),
        activeGeojsons: JSON.stringify(activeGeojsons),
        activeOrthophotos: JSON.stringify(activeOrthophotos),
      });
    }
  };

  const tabItems = [
    {
      label: 'General',
      key: 'general',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
            <div>
              <InputHook
                register={{
                  ...register('name', {
                    required: true,
                    maxLength: 256,
                    minLength: 2,
                  }),
                }}
                placeholder={t('NAME') as string}
                label={t('NAME')}
                disabled={isAnyLoading}
                errors={errors}
              />
            </div>
            <div>
              <InputHook
                register={{
                  ...register('description', {
                    required: false,
                    maxLength: 256,
                    minLength: 2,
                  }),
                }}
                placeholder={t('DESCRIPTION') as string}
                label={t('DESCRIPTION')}
                disabled={isAnyLoading}
                errors={errors}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      label: 'Zoom',
      key: 'zoom',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
            <div>
              <Input
                id="defaultZoom"
                label={t('DEFAULT_ZOOM')}
                type="text"
                placeholder={t('DEFAULT_ZOOM') as string}
                disabled={isAnyLoading}
                value={`${defaultZoom}`}
                onChange={(e: any) => {
                  if (typeof e !== 'number') {
                    return;
                  }
                  setDefaultZoom(e);
                }}
              />
            </div>
            <div>
              <Input
                id="minZoom"
                label={t('MINIMUM_ZOOM')}
                type="text"
                placeholder={t('MINIMUM_ZOOM') as string}
                disabled={isAnyLoading}
                value={`${minZoom}`}
                onChange={(e: any) => {
                  const val = e.target.value;
                  if (typeof val !== 'number') {
                    return;
                  }
                  if (val > defaultZoom) {
                    setDefaultZoom(val);
                  }
                  setMinZoom(val);
                }}
              />
            </div>
            <div>
              <Input
                id="maxZoom"
                label={t('MAXIMUM_ZOOM')}
                type="text"
                placeholder={t('MAXIMUM_ZOOM') as string}
                disabled={isAnyLoading}
                value={`${maxZoom}`}
                onChange={(e: any) => {
                  if (typeof e !== 'number') {
                    return;
                  }
                  if (e < defaultZoom) {
                    setDefaultZoom(e);
                  }
                  setMaxZoom(e);
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      label: t('LOCATION'),
      key: 'ubicacion',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
            <div>
              <Input
                label={t('LATITUDE')}
                id="latitude"
                type="text"
                placeholder={t('LATITUDE') as string}
                disabled={isAnyLoading}
                value={`${latitude}`}
                onChange={(e: any) => {
                  setLatitude(e.target.value);
                }}
              />
            </div>
            <div>
              <Input
                label={t('LONGITUDE')}
                id="longitude"
                type="text"
                placeholder={t('LONGITUDE') as string}
                disabled={isAnyLoading}
                value={`${longitude}`}
                onChange={(e: any) => {
                  setLongitude(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="h-[500px] w-full mt-[30px]">
            <MapContainer
              className="h-[500px] w-full"
              center={[latitude, longitude]}
              zoom={13}
              style={{ height: '500px' }}
              key={triggerViewporReset}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MapEventsComponent setLatitude={setLatitude} setLongitude={setLongitude} />
              <Marker position={[latitude, longitude]} />
            </MapContainer>
          </div>
        </div>
      ),
    },
    {
      label: t('ORTHOPHOTOS'),
      key: 'orthophotos',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
            <div className="flex flex-col gap-1">
              <label className="text-base text-gray-700">
                {t('ORTHOPHOTOS')}
              </label>
              {
                (!isFetchingOrthophotos) &&
                <Button
                  size="small"
                  onClick={() => { setSelectOrthophotoModalVisible(true); }}
                  className="w-fit"
                >
                  {t('ADD')} {t('ORTHOPHOTO')}
                </Button>
              }
              <div className="flex flex-col gap-2 my-3">
                {
                  orthophotos.map(((item, index) => (
                    <div className="flex items-center" key={item}>
                      <div className="flex items-center">
                        <Toggle
                          className="mr-2"
                          enabled={activeOrthophotos.includes(item)}
                          setEnabled={() => {
                            if (activeOrthophotos.includes(item)) {
                              setActiveOrthophotos(prev => [...filter(prev, o => o !== item)]);
                            } else {
                              setActiveOrthophotos(prev => [...prev, item]);
                            }
                          }}
                        />
                        {find(dataOrthophotos, o => o.id === item).name}
                      </div>
                      <div className="flex gap-2 ml-auto">
                        <Button
                          className="p-0 !bg-transparent !text-blue-700 disabled:!bg-transparent disabled:!text-blue-400"
                          size="small"
                          onClick={() => {
                            const tempOrthophotos = [...orthophotos];
                            [tempOrthophotos[index], tempOrthophotos[index - 1]] = [tempOrthophotos[index - 1], tempOrthophotos[index]];
                            setOrthophotos([...tempOrthophotos]);
                          }}
                          disabled={(index === 0)}
                        >
                          <CaretUpIcon className="w-6" />
                        </Button>
                        <Button
                          className="p-0 !bg-transparent !text-blue-700 disabled:!bg-transparent disabled:!text-blue-400"
                          size="small"
                          onClick={() => {
                            const tempOrthophotos = [...orthophotos];
                            [tempOrthophotos[index], tempOrthophotos[index + 1]] = [tempOrthophotos[index + 1], tempOrthophotos[index]];
                            setOrthophotos([...tempOrthophotos]);
                          }}
                          disabled={index === orthophotos.length - 1}
                        >
                          <CaretDownIcon className="w-6" />
                        </Button>
                        <Button
                          className="p-0 !bg-transparent !text-blue-700"
                          size="small"
                          onClick={() => {
                            setOrthophotos(prev=> [...filter(prev, o => o !== item)]);
                            setActiveOrthophotos(prev=> [...filter(prev, o => o !== item)]);
                          }}
                        >
                          <TrashIcon className="w-4" />
                        </Button>
                      </div>
                    </div>
                  )))
                }
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: 'GeoJSONs',
      key: 'geojsons',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
            <div className="flex flex-col gap-1">
              <label className="text-base text-gray-700">
                {t('GEOJSONS')}
              </label>
              {
                (!isFetchingGeojsons) &&
                <Button
                  size="small"
                  onClick={() => { setSelectGeojsonModalVisible(true); }}
                  className="w-fit"
                >
                  {t('ADD')} {t('GEOJSON')}
                </Button>
              }
              <div className="flex flex-col gap-2 my-3">
                {
                  geojsons.map(((item, index) => (
                    <div className="flex items-center" key={item}>
                      <div className="flex items-center">
                        <Toggle
                          className="mr-2"
                          enabled={activeGeojsons.includes(item)}
                          setEnabled={() => {
                            if (activeGeojsons.includes(item)) {
                              setActiveGeojsons(prev => [...filter(prev, o => o !== item)]);
                            } else {
                              setActiveGeojsons(prev => [...prev, item]);
                            }
                          }}
                        />
                        {find(dataGeojsons, o => o.id === item).name}
                      </div>
                      <div className="flex gap-2 ml-auto">
                        <Button
                          className="p-0 !bg-transparent !text-blue-700 disabled:!bg-transparent disabled:!text-blue-400"
                          size="small"
                          onClick={() => {
                            const tempGeojsons = [...geojsons];
                            [tempGeojsons[index], tempGeojsons[index - 1]] = [tempGeojsons[index - 1], tempGeojsons[index]];
                            setGeojsons([...tempGeojsons]);
                          }}
                          disabled={(index === 0)}
                        >
                          <CaretUpIcon className="w-6" />
                        </Button>
                        <Button
                          className="p-0 !bg-transparent !text-blue-700 disabled:!bg-transparent disabled:!text-blue-400"
                          size="small"
                          onClick={() => {
                            const tempGeojsons = [...geojsons];
                            [tempGeojsons[index], tempGeojsons[index + 1]] = [tempGeojsons[index + 1], tempGeojsons[index]];
                            setGeojsons([...tempGeojsons]);
                          }}
                          disabled={index === geojsons.length - 1}
                        >
                          <CaretDownIcon className="w-6" />
                        </Button>
                        <Button
                          className="p-0 !bg-transparent !text-blue-700"
                          size="small"
                          onClick={() => {
                            setGeojsons(prev=> [...filter(prev, o => o !== item)]);
                            setActiveGeojsons(prev=> [...filter(prev, o => o !== item)]);
                          }}
                        >
                          <TrashIcon className="w-4 " />
                        </Button>
                      </div>
                    </div>
                  )))
                }
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: t('USERS'),
      key: 'users',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
            <div className="flex flex-col gap-1">
              <label className="text-base text-gray-700">
                {t('USERS')}
              </label>
              {
                (!isFetchingUsers) &&
                <Button
                  size="small"
                  onClick={() => { setSelectUserModalVisible(true); }}
                  className="w-fit"
                >
                  {t('ADD')} {t('USER')}
                </Button>
              }
              <div className="flex flex-col gap-2 my-3">
                {
                  users.map((item) => {
                    const itemFound = find(dataUsers, (o: UserSchema) => o?.id === item);
                    let name = '';
                    if (itemFound) {
                      name = `${itemFound.name} ${itemFound.surname} ${itemFound.motherSurname} - ${itemFound.displayName}`;
                    }
                    return (
                      <div className="flex items-center" key={item}>
                        <div>
                          {name}
                        </div>
                        <div className="ml-auto">
                          <Button
                            className="p-0 !bg-transparent !text-blue-700 disabled:!text-blue-400"
                            size="small"
                            onClick={() => {
                              setUsers(prev=> [...filter(prev, o => o !== item)]);
                            }}
                          >
                            <TrashIcon className="w-4 " />
                          </Button>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(project) ? project.name : ''}`, current: false },
          { name: t('MAPS'), to: `/board/${projectId}/${t('SLUG_MAPS')}`, current: false },
          { name: mapId ? t('EDIT') : t('ADD'), current: true },
        ]}
      />
      <BoardHeader
        title={(mapId) ? `${t('EDIT')} ${t('MAP')}` : `${t('ADD')} ${t('MAP')}`}
        description={(mapId) ? t('BOARD_MAP_EDIT_PAGE_DESCRIPTION') : t('BOARD_MAP_CREATE_PAGE_DESCRIPTION')}
      />
      <form onSubmit={handleSubmit((data) => onFinish(data))}>
        <div
          className={cx(
            'top-[-24px] absolute justify-center pt-[40vh] hidden left-0 w-full h-[calc(100%-124px)]',
            (isAnyLoading) ? 'flex z-[2]' : '',
          )}
        >
          <ArrowPathIcon className="w-7 h-7 animate-spin" />
        </div>
        <BoardContent>
          <BoardCard>
            <Tabs
              defaultActiveKey="general"
              items={tabItems}
              tabChanged={() => {
                setTriggerViewporReset(prev => prev + 1);
              }}
            />
          </BoardCard>
        </BoardContent>
        <BoardFooterBar
          onClick={() => { }}
          disabled={isAnyLoading}
          isLoading={isCreating || isUpdating}
        >
          {mapId ? t('UPDATE') : t('CREATE')} {t('MAP')}
        </BoardFooterBar>
      </form>
      <GeojsonSelectModal
        visible={selectGeojsonModalVisible}
        setVisible={setSelectGeojsonModalVisible}
        selectGeojsonEvent={(record: GeojsonSchema) => {
          if (!geojsons.includes(record.id)) {
            setGeojsons(prev => [...prev, record.id]);
          }
          setSelectGeojsonModalVisible(false);
        } }
        geojsons={filter(dataGeojsons, o => !geojsons.includes(o.id))}
      />
      <OrthophotoSelectModal
        visible={selectOrthophotoModalVisible}
        setVisible={setSelectOrthophotoModalVisible}
        selectOrthophotoEvent={(record: OrthophotoSchema) => {
          if (!orthophotos.includes(record.id)) {
            setOrthophotos(prev => [...prev, record.id]);
          }
          setSelectOrthophotoModalVisible(false);
        }}
        orthophotos={filter(dataOrthophotos, o => !orthophotos.includes(o.id))}
      />
      <UserSelectModal
        visible={selectUserModalVisible}
        setVisible={setSelectUserModalVisible}
        selectUserEvent={(record: UserSchema) => {
          if (!users.includes(record.id)) {
            setUsers(prev => [...prev, record.id]);
          }
          setSelectUserModalVisible(false);
        }}
        users={filter(dataUsers, o => !users.includes(o.id))}
      />
    </>
  );
}

function MapEventsComponent({
  setLatitude,
  setLongitude,
}: {
  setLatitude: Dispatch<SetStateAction<number>>;
  setLongitude: Dispatch<SetStateAction<number>>;
}) {
  const map = useMapEvents({
    click: (e) => {
      setLatitude(e.latlng.lat);
      setLongitude(e.latlng.lng);
      map.invalidateSize();
    },
    load: (e) => {
      map.invalidateSize();
    },
    viewreset: (e) => {
      map.invalidateSize();
    },
  });

  return null;
}
