import { createSlice } from '@reduxjs/toolkit';

export const translateSlice = createSlice({
  name: 'translate',
  initialState: {
    language: 'es',
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const actionsTranslate = translateSlice.actions;

export default translateSlice;

