import { useState, Fragment, SetStateAction, Dispatch } from 'react';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, EyeIcon, EyeSlashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { pull, find, remove } from 'lodash';
import ModalAddCapa from './layer/ModalAddLayer';
import { GeojsonSchema, GeojsonsStateType, OrthophotoSchema, SelectedClassificationsType } from '@/types/types';
import Button from '../../ui/general/Button';
import Divider from '../../ui/layout/Divider';

export default function MapCapas({
  active,
  setActive,
  orthophotos,
  loadedGeojsons,
  setLoadedGeojsons,
  activeOrthophotos,
  setActiveOrthophotos,
  activeGeojsons,
  setActiveGeojsons,
  oIndexes,
  setOIndexes,
  gIndexes,
  setGIndexes,
  setSelectedCapa,
  selectedCapa,
  setSelectedGeojson,
  geojsons,
  selectedClassifications,
  map,
}: {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  orthophotos: OrthophotoSchema[];
  loadedGeojsons: GeojsonSchema[];
  setLoadedGeojsons: Dispatch<SetStateAction<GeojsonSchema[]>>;
  activeOrthophotos: string[];
  setActiveOrthophotos: Dispatch<SetStateAction<string[]>>;
  activeGeojsons: string[];
  setActiveGeojsons: Dispatch<SetStateAction<string[]>>;
  oIndexes: string[];
  setOIndexes: Dispatch<SetStateAction<string[]>>;
  gIndexes: string[];
  setGIndexes: Dispatch<SetStateAction<string[]>>;
  selectedCapa: string,
  setSelectedCapa: Dispatch<SetStateAction<string>>;
  setSelectedGeojson: Dispatch<SetStateAction<GeojsonSchema | undefined>>,
  geojsons: GeojsonsStateType,
  selectedClassifications: SelectedClassificationsType,
  map: any,
}) {
  const { t } = useTranslation();

  const [modalAddCapaVisible, setModalAddCapaVisible] = useState<boolean>(false);

  const orthophotoSelectHandle = (id: string) => {
    let temp = activeOrthophotos;
    if (temp.includes(id)) temp = pull(temp, id); else temp.push(id);
    setActiveOrthophotos([...temp]);
  };

  const geojsonSelectHandle = (id: string) => {
    let temp = activeGeojsons;
    if (temp.includes(id)) {
      if (id === selectedCapa) {
        setSelectedCapa('');
        setSelectedGeojson(undefined);
      }
      temp = pull(temp, id);
    } else {
      setSelectedCapa(id);
      temp.push(id);
      setSelectedGeojson({...geojsons[id]});
    };
    setActiveGeojsons([...temp]);
  };

  const geojsonSelectHandle2 = (id: string) => {
    let temp = activeGeojsons;
    if (!temp.includes(id)) {
      setSelectedCapa(id);
      temp.push(id);
      setActiveGeojsons([...temp]);
      setSelectedGeojson({...geojsons[id]});
    } else {
      setSelectedCapa(id);
      setSelectedGeojson({...geojsons[id]});
    }
  }

  const orthophotoSwap = (y: number, x: number) => {
    const tempOIndexes = [...oIndexes];
    const tempIndex = tempOIndexes[y];
    tempOIndexes[y] = tempOIndexes[x];
    tempOIndexes[x] = tempIndex;
    setOIndexes(tempOIndexes);
  }

  const geojsonSwap = (y: number, x: number) => {
    const tempGIndexes = [...gIndexes];
    const tempIndex = tempGIndexes[y];
    tempGIndexes[y] = tempGIndexes[x];
    tempGIndexes[x] = tempIndex;
    setGIndexes(tempGIndexes);
    const newActiveGeojsons = [];
    for (let k = 0; k < tempGIndexes.length; k += 1) {
      if (activeGeojsons.includes(tempGIndexes[k])) {
        setActiveGeojsons(prev => remove(prev, o => o === tempGIndexes[k]));
        newActiveGeojsons.push(tempGIndexes[k]);
      }
    }
    setActiveGeojsons([...newActiveGeojsons]);
  }

  return (
    <div className={cx(
      'absolute bottom-[50px] right-0 max-w-[350px] min-h-[140px] w-full bg-white z-[400] translate-x-[100%] transition-all',
      active ? '!translate-x-0' : '',
    )}>
      <div
        className="absolute top-[40px] left-[-20px] w-[20px] h-[50px] bg-[#001529] text-white flex items-center justify-center cursor-pointer"
        onClick={() => setActive(!active)}
      >
        <ChevronRightIcon className={cx('w-5 transition-all', (!active) ? 'rotate-180' : '')} />
      </div>
      <div className="bg-[#001529] text-white text-[18px] flex items-center py-0 px-5 h-10">
        {t('LAYERS')}
        <Button
          size="xs"
          className="ml-2"
          onClick={() => { setModalAddCapaVisible(true); }}
        >
          <PlusIcon className="w-4 h-4" />
        </Button>
      </div>
      <div className="py-4 px-5">
        {
          (gIndexes && gIndexes.length > 0) &&
          gIndexes.map((i, index) => {
            const o = find(loadedGeojsons, x => x.id === i);
            if (!o) {
              return <Fragment />;
            }
            const isActive = activeGeojsons.includes(o.id);
            return (
              <div
                key={o.id}
                className={cx('text-[16px] p-1 flex items-center')}
              >
                <div className="flex items-center cursor-pointer">
                  <div className="flex items-center" onClick={() => { geojsonSelectHandle(o.id); }}>
                    {(isActive) && <EyeIcon className={cx('w-4', isActive ? 'text-[#1890ff]' : '')} />}
                    {(!isActive) && <EyeSlashIcon className={cx('w-4', isActive ? 'text-[#1890ff]' : '')} />}
                  </div>
                  &nbsp;
                  <span
                    onClick={() => { geojsonSelectHandle2(o.id); }}
                    className={(selectedCapa === o.id) ? 'underline' : 'no-underline'}
                  >
                    {o.name}&nbsp;{(selectedClassifications[o.id]) ? <i>({selectedClassifications[o.id]})</i> : ''}
                  </span>
                </div>
                <div className="flex ml-auto">
                  <ChevronUpIcon className="w-4 cursor-pointer" onClick={() => (index > 0) && geojsonSwap(index, index - 1)} />
                  <ChevronDownIcon className="w-4 cursor-pointer" onClick={() => (loadedGeojsons.length - 1 > index) && geojsonSwap(index, index + 1)} />
                </div>
              </div>
            );
          })
        }
        {
          (orthophotos && orthophotos.length > 0 && loadedGeojsons && loadedGeojsons.length > 0) &&
          <Divider />
        }
        {
          (oIndexes && oIndexes.length > 0) &&
          oIndexes.map((i, index) => {
            const o = find(orthophotos, x => x.id === i);
            if (!o) {
              return <Fragment />;
            }
            const isActive = activeOrthophotos.includes(o.id);
            return (
              <div key={o.id} className={cx('text-[16px] p-1 flex items-center')}>
                <div className="flex items-center cursor-pointer" onClick={() => { orthophotoSelectHandle(o.id)}}>
                  <div className="flex items-center">
                    {(isActive) && <EyeIcon className={cx('w-4', isActive ? 'text-[#1890ff]' : '')} />}
                    {(!isActive) && <EyeSlashIcon className={cx('w-4', isActive ? 'text-[#1890ff]' : '')} />}
                  </div>
                  &nbsp;
                  <span>
                    {o.name}
                  </span>
                </div>
                <div className="flex ml-auto">
                  <ChevronUpIcon className="w-4 cursor-pointer" onClick={() => (index > 0) && orthophotoSwap(index, index - 1)} />
                  <ChevronDownIcon className="w-4 cursor-pointer" onClick={() => (orthophotos.length - 1 > index) && orthophotoSwap(index, index + 1)} />
                </div>
              </div>
            );
          })
        }
      </div>
      <ModalAddCapa
        map={map}
        visible={modalAddCapaVisible}
        setVisible={setModalAddCapaVisible}
        onCreated={(data) => {
          setModalAddCapaVisible(false);
          setLoadedGeojsons(prev => [...prev, data]);
          setGIndexes(prev => [...prev, data.id]);
        }}
      />
    </div>
  );
}
