import qs from 'qs';
import { appApi } from './api';
import { getToken } from '../store';

export const company = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCompany: builder.query({
      query: (params) => ({
        url: `/api/v1/companies?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (result, error, arg) => [{ type: 'Company', id: (result && result.id) ? result.id : '' }],
    }),
    createCompany: builder.mutation({
      query: (params) => ({
        url: '/api/v1/companies',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Company', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useCreateCompanyMutation,
} = company;
