import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { GeojsonSchema, PlotItem, SelectedClassificationsType } from '@/types/types';
import Modal from '../../../ui/feedback/Modal';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export default function MapColumnPlot({
  visible,
  setVisible,
  selectedGeojson,
  selectedClassifications,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedGeojson: GeojsonSchema | undefined;
  selectedClassifications: SelectedClassificationsType;
}) {
  const { t } = useTranslation();

  const [labelSelected, setLabelSelected] = useState<string>('');
  const [data, setData] = useState<PlotItem[]>([]);

  useEffect(() => {
    if (selectedGeojson && selectedClassifications && selectedClassifications[selectedGeojson.id]) {
      setLabelSelected(selectedClassifications[selectedGeojson.id]);
    } else {
      setLabelSelected('');
    }
  }, [selectedClassifications, selectedGeojson]);

  useEffect(() => {
    if (labelSelected && selectedGeojson && selectedGeojson.classificationsKeys && selectedGeojson.classificationsKeys[labelSelected]) {
      const formattedData = selectedGeojson.classificationsKeys[labelSelected].map((o: PlotItem) => ({ ...o, value: `${labelSelected} ${o.value}` }));
      setData(formattedData);
    }
  }, [labelSelected, selectedGeojson]);

  return (
    <Modal
      title={t('CHART')}
      isOpen={visible}
      setIsOpen={setVisible}
      width={1200}
    >
      <div className="p-8">
        <h3 className="mb-5">
          {labelSelected}
        </h3>
        <div className="w-full h-[300px]">
        <ResponsiveContainer width="99%">
          <BarChart
            width={600}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="value" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="color" />
          </BarChart>
        </ResponsiveContainer>
        </div>
      </div>
    </Modal>
  );
}
