export default function Logo({
  size: sizeProp = 'large',
  type: typeProp = 'dark',
  className,
}: {
  size?: 'small' | 'large';
  type?: 'dark' | 'light';
  className: string;
}) {
  let type = 'dark';
  let size = '';

  if (sizeProp === 'small') {
    size = 's-';
  }

  if (typeProp === 'light') {
    type = 'light';
  }

  return (
    <img className={className} src={`/logo-${size}${type}.png`} alt="Logo" />
  );
}
