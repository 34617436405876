import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCompleteRegistrationMutation } from '../../features/auth';
import { setCredentials } from '../../features/authSlice';
import InputHook from '../ui/data-entry/InputHook';
import Button from '../ui/general/Button';
import { actionsNotification } from '../../features/notificationSlice';

export default function CompleteRegistration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [completeRegistration, { isLoading }] = useCompleteRegistrationMutation();

  const onFinish = async (fieldValues: FieldValues) => {
    try {
      const user = await completeRegistration({ ...fieldValues }).unwrap();
      dispatch(setCredentials(user));
      dispatch(actionsNotification.createAlert({ message: t('REGISTRATION_COMPLETED'), type: 'success' }));
      navigate('/');
    } catch (err) {
      dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
    }
  };

  return (
    <div className="min-h-screen py-10 px-5 bg-slate-100 flex items-center justify-center text-slate-600 ">
      <div className="max-w-[416px] sm:max-w-[456px] w-full">
        <h2 className="text-3xl font-bold text-slate-600 tracking-tight mb-8 text-center">
          {t('COMPLETE_REGISTRATION')}
        </h2>
        <form
          className="py-9 px-5 sm:px-10 bg-white w-full rounded-lg flex flex-col gap-4 shadow"
          onSubmit={handleSubmit((data) => onFinish(data))}
        >
          <InputHook
            register={{
              ...register('name', {
                required: true,
                maxLength: 100,
              }),
            }}
            label={t('NAME')}
            disabled={isLoading}
            errors={errors}
          />
          <InputHook
            register={{
              ...register('surname', {
                required: true,
                maxLength: 100,
              }),
            }}
            label={t('LAST_NAME')}
            disabled={isLoading}
            errors={errors}
          />
          <InputHook
            register={{
              ...register('phone', {
                required: true,
                maxLength: 100,
              }),
            }}
            label={t('PHONE_NUMBER')}
            disabled={isLoading}
            errors={errors}
          />
          <InputHook
            register={{
              ...register('companyName', {
                required: true,
                maxLength: 100,
              }),
            }}
            label={t('COMPANY')}
            disabled={isLoading}
            errors={errors}
          />
          <Button
            type="submit"
            className="mt-5"
            onClick={() => {}}
            isLoading={isLoading}
            preventDefault={false}
          >
            {(isLoading) ? `${t('COMPLETING_REGISTRATION')}...` : t('TO_COMPLETE_REGISTRATION') }
          </Button>
        </form>
        <div className="text-base text-right mt-4">
          <Link to="/log-out" className="text-blue-700 hover:text-blue-500">
            {t('LOG_OUT')}
          </Link>
        </div>
      </div>
    </div>
  );
}
