import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureSpecial, GeojsonSchema, MapSchema, RegistriesObjectState, SelectedClassificationsType, SelectedFiltrosType } from '@/types/types';
import MapClassifications from './selected-layer/MapClassifications';
import MapRegistries from './selected-layer/MapRegistries';
import Tabs from '../../ui/data-display/Tabs';
import Drawer from '../../ui/feedback/Drawer';

export default function MapSelectedLayer({
  visible,
  setVisible,
  selectedGeojson,
  selectedClassifications,
  setSelectedClassifications,
  selectedFiltros,
  setSelectedFiltros,
  opacidad,
  setOpacidad,
  registriesObject,
  setRegistriesObject,
  registriesObjectVisible,
  selectedFeature,
  setSelectedFeature,
  map,
  setFeatureDrawerVisible,
  onClose,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedGeojson: GeojsonSchema | undefined;
  selectedClassifications: SelectedClassificationsType;
  setSelectedClassifications: Dispatch<SetStateAction<SelectedClassificationsType>>;
  selectedFiltros: SelectedFiltrosType;
  setSelectedFiltros: Dispatch<SetStateAction<SelectedFiltrosType>>;
  opacidad: boolean;
  setOpacidad: Dispatch<SetStateAction<boolean>>;
  registriesObject: RegistriesObjectState;
  setRegistriesObject: Dispatch<SetStateAction<RegistriesObjectState>>;
  registriesObjectVisible: RegistriesObjectState;
  selectedFeature: FeatureSpecial | undefined;
  setSelectedFeature: Dispatch<SetStateAction<FeatureSpecial | undefined>>;
  map: MapSchema;
  setFeatureDrawerVisible: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const items = [
    {
      label: t('CLASSIFICATIONS'),
      key: 'classifications',
      children: (
        <MapClassifications
          selectedGeojson={selectedGeojson}
          selectedClassifications={selectedClassifications}
          setSelectedClassifications={setSelectedClassifications}
          selectedFiltros={selectedFiltros}
          setSelectedFiltros={setSelectedFiltros}
          opacidad={opacidad}
          setOpacidad={setOpacidad}
          setSelectedFeature={setSelectedFeature}
        />
      ),
    },
    {
      label: t('RECORDS'),
      key: 'registries',
      children: (
        <MapRegistries
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
          selectedGeojson={selectedGeojson}
          registriesObject={registriesObject}
          setRegistriesObject={setRegistriesObject}
          registriesObjectVisible={registriesObjectVisible}
          setFeatureDrawerVisible={setFeatureDrawerVisible}
          map={map}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Drawer
        width={350}
        placement="left"
        closable={false}
        isOpen={visible}
        onClose={onClose}
      >
        <Tabs
          defaultActiveKey="classifications"
          items={items}
        />
      </Drawer>
    </Fragment>
  );
}
