export default function CaretDownIcon({
  className = '',
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="m7 10l5 5l5-5H7Z"
      />
    </svg>
  );
}
