import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FieldValues, useForm } from 'react-hook-form';
// import slugify from 'slugify';
import { includes, replace } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
//  generateRandomPassword,
  permissionsParser,
} from '../../../utils/util';
import { useGetUserQuery, useCreateUserMutation, useUpdateUserMutation } from '../../../features/user';
import { useGetProjectQuery } from '../../../features/project';
import SelectFechaModal from '../../global-modals/SelectDateModal';
import { actionsNotification } from '../../../features/notificationSlice';
import Breadcrumbs from '../../global/Breadcrumbs';
import InputHook from '../../ui/data-entry/InputHook';
import RadioGroup from '../../ui/data-entry/RadioGroup';
import CheckboxGroup from 'src/components/ui/data-entry/CheckboxGroup';
import BoardFooterBar from '../common/BoardFooterBar';
import BoardContent from '../common/BoardContent';
import BoardCard from '../common/BoardCard';
import BoardCardHeader from '../common/BoardCardHeader';
import BoardHeader from '../common/BoardHeader';

type OptionType = {
  label: string;
  value: string;
  disabled?: boolean;
}

const choicesGender = [
  { label: 'Hombre', value: 'hombre' },
  { label: 'Mujer', value: 'mujer' },
];

export default function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { projectId, userId } = useParams();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const optionsRoles = [
    { label: t('USER'), value: 'user' },
    { label: t('ADMIN'), value: 'admin' },
  ];
  
  const optionsOrthophotosDisabled = [
    { label: t('VIEW'), value: 'view' },
    { label: `${t('ADD')} / ${t('EDIT')}`, value: 'upsert', disabled: true },
  ];

  const optionsOrthophotosEnabled = useMemo(() => [
    { label: t('VIEW'), value: 'view' },
    { label: `${t('ADD')} / ${t('EDIT')}`, value: 'upsert' },
  ], [t]);
  
  const optionsOrthophotosChecked = useMemo(() => ['view', 'upsert'], []);
  
  const optionsGeojsonsDisabled = [
    { label: t('VIEW'), value: 'view' },
    { label: `${t('ADD')} / ${t('EDIT')}`, value: 'upsert', disabled: true },
  ];
  
  const optionsGeojsonsEnabled = useMemo(() => [
    { label: t('VIEW'), value: 'view' },
    { label: `${t('ADD')} / ${t('EDIT')}`, value: 'upsert' },
  ], [t]);;
  
  const optionsGeojsonsChecked = useMemo(() => ['view', 'upsert'], []);

  const [isCreatingPrev, setIsCreatingPrev] = useState<boolean>(false);
  const [isFetchingPrev, setIsFetchingPrev] = useState<boolean>(false);
  const [isUpdatingPrev, setIsUpdatingPrev] = useState<boolean>(false);
  const [role, setRole] = useState<string>('');
  const [rolePrev, setRolePrev] = useState<string>('');
  const [prevOrthophotos, setPrevOrthophotos] = useState<string[]>([]);
  const [orthophotoValues, setOrthophotoValues] = useState<string[]>([]);
  const [optionsOrthophotos, setOptionsOrthophotos] = useState<OptionType[]>([...optionsOrthophotosDisabled]);
  const [prevGeojsons, setPrevGeojsons] = useState<string[]>([]);
  const [geojsonValues, setGeojsonValues] = useState<string[]>([]);
  const [optionsGeojsons, setOptionsGeojsons] = useState<OptionType[]>([...optionsGeojsonsDisabled]);
  const [selectFechaVisible, setSelectFechaVisible] = useState<boolean>(false);
  const [birthDate, setFechaBirth] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [skipGetUser, setSkipGetUser] = useState<boolean>(true);

  const { data, isFetching, isError: fetchingIsError, refetch } = useGetUserQuery({ id: userId, projectId }, { skip: skipGetUser });
  const [createUser, { isLoading: isCreating, isError: creatingIsError }] = useCreateUserMutation();
  const [updateUser, { isLoading: isUpdating, isError: updatingIsError }] = useUpdateUserMutation();
  const { data: project } = useGetProjectQuery({ projectId });

  const isAnyLoading = useMemo<boolean>(() => (isFetching || isCreating || isUpdating), [isFetching, isCreating, isUpdating]);

  useEffect(() => {
    if (userId && projectId) {
      setSkipGetUser(false);
    } else {
      setSkipGetUser(true);
    }
  }, [userId, projectId]);

  useEffect(() => {
    if (skipGetUser === false) {
      refetch();
    }
  }, [skipGetUser, refetch]);

  useEffect(() => {
    if (!isCreating && isCreatingPrev && !creatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('USER_ADDED_SUCCESSFULLY'), type: 'success' }));
      navigate(`/board/${projectId}/${t('SLUG_USERS')}`);
      return;
    }
    setIsCreatingPrev(isCreating);
  }, [isCreating, projectId, isCreatingPrev, creatingIsError, navigate, dispatch, t]);

  useEffect(() => {
    if (!isUpdating && isUpdatingPrev && !updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('USER_UPDATED_SUCCESSFULLY'), type: 'success' }));
      navigate(`/board/${projectId}/${t('SLUG_USER')}`); 
      return;
    }
    setIsUpdatingPrev(isUpdating);
  }, [isUpdating, projectId, isUpdatingPrev, updatingIsError, navigate, dispatch, t]);

  useEffect(() => {
    if (!isFetching && isFetchingPrev && !fetchingIsError) {
      /* form.setFieldsValue({
        ...data,
        username: (data && data.displayName) ? data.displayName : '',
        birthDate: (userId && data.birthDate) ?  data.birthDate : '',
      }); */
      if (userId && data.birthDate) {
        setFechaBirth(data.birthDate);
      }
      setPermissions(data.permissions);
      setRole(data.role);
      setGender(data.gender);
    }
    setIsFetchingPrev(isFetching);
  }, [isFetching, data, isFetchingPrev, fetchingIsError, userId, permissions]);

  useEffect(() => {
    if (role === 'user' && permissions && Array.isArray(permissions)) {
      if (includes(permissions, 'orthophotos_view')) {
        setOptionsOrthophotos([...optionsOrthophotosEnabled]);
      } else {
      setOrthophotoValues([]);
      }
      if (includes(permissions, 'geojsons_view')) {
        setOptionsGeojsons([...optionsGeojsonsEnabled]);
      } else {
        setGeojsonValues([]);
      }
    }
  }, [optionsGeojsonsEnabled, optionsOrthophotosEnabled, permissions, role]);

  useEffect(() => {
    if (role === 'admin' && rolePrev !== role) {
      setPrevOrthophotos([...orthophotoValues]);
      setPrevGeojsons([...geojsonValues]);
      setOrthophotoValues([...optionsOrthophotosChecked]);
      setGeojsonValues([...optionsGeojsonsChecked]);
    } else if (rolePrev === 'admin' && rolePrev !== role && prevOrthophotos && prevGeojsons) {
      setOrthophotoValues([...prevOrthophotos]);
      setGeojsonValues([...prevGeojsons]);
    }
    setRolePrev(role);
  }, [role, rolePrev, prevOrthophotos, prevGeojsons, orthophotoValues, geojsonValues, optionsOrthophotosChecked, optionsGeojsonsChecked]);

  useEffect(() => {
    if (fetchingIsError || creatingIsError || updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
    }
  }, [fetchingIsError, creatingIsError, updatingIsError, dispatch, t]);

  useEffect(() => {
    if (role === 'user' && permissions && Array.isArray(permissions) && permissions.length > 0) {
      const tempOrthophoto: string[] = [];
      const tempGeojson: string[] = [];
      permissions.forEach(o => {
        if (includes(o, 'orthophotos_')) {
          tempOrthophoto.push(replace(o, 'orthophotos_', ''));
        }
        if (includes(o, 'geojsons_')) {
          tempGeojson.push(replace(o, 'geojsons_', ''));
        }
      });
      setOrthophotoValues([...tempOrthophoto]);
      setGeojsonValues([...tempGeojson]);
    }
  }, [permissions, role]);

  const onFinish = (fieldValues: FieldValues) => {
    const permissions = [];
    if (fieldValues.permissions_orthophoto && fieldValues.permissions_orthophoto.length > 0) {
      permissions.push(...permissionsParser('orthophotos', fieldValues.permissions_orthophoto));
    }
    if (fieldValues.permissions_geojson && fieldValues.permissions_geojson.length > 0) {
      permissions.push(...permissionsParser('geojsons', fieldValues.permissions_geojson));
    }
    if (userId) {
      updateUser({
        ...fieldValues,
        permissions: (permissions) ? JSON.stringify(permissions) : '[]',
        birthDate: (fieldValues.birthDate) ? fieldValues.birthDate : '',
        id: userId,
        projectId,
      });
    } else {
      createUser({
        ...fieldValues,
        permissions: (permissions) ? JSON.stringify(permissions) : '[]',
        birthDate: (fieldValues.birthDate) ? fieldValues.birthDate : '',
        projectId,
      });
    }
  };

  /* const generateUsername = () => {
    if (isAnyLoading) {
      return;
    }
    const name = form.getFieldValue('name');
    const surname = form.getFieldValue('surname');
    if (!name) {
      dispatch(actionsNotification.createAlert({ message: "Ingresa el name", type: 'warning' }));
      return;
    }
    if (!surname) {
      dispatch(actionsNotification.createAlert({ message: "Ingresa el apellido", type: 'warning' }));
      return;
    }
    form.setFieldsValue({
      username: slugify(`${name}_${surname}`, { lower: true, replacement: '_' }),
    });
  } */

  /* const generatePassword = () => {
    if (isAnyLoading) {
      return;
    }
    form.setFieldsValue({
      password: generateRandomPassword(),
    });
  } */

  const orthophotosChanged = (e: string[]) => {
    if (includes(e, 'view')) {
      setOrthophotoValues([...e]);
      setOptionsOrthophotos([...optionsOrthophotosEnabled]);
    }
    if (!includes(e, 'view')) {
      setOrthophotoValues([]);
      setOptionsOrthophotos([...optionsOrthophotosDisabled]);
    }
  }

  const geojsonsChanged = (e: string[]) => {
    if (includes(e, 'view')) {
      setGeojsonValues([...e]);
      setOptionsGeojsons([...optionsGeojsonsEnabled]);
    }
    if (!includes(e, 'view')) {
      setGeojsonValues([]);
      setOptionsGeojsons([...optionsGeojsonsDisabled]);
    }
  }

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(project) ? project.name : ''}`, current: false },
          { name: t('USERS'), to: `/board/${projectId}/${t('SLUG_USERS')}`, current: false },
          { name: userId ? t('EDIT') : t('ADD'), current: true },
        ]}
      />
      <BoardHeader
        title={(userId) ? `${t('EDIT')} ${t('USER')}` : `${t('ADD')} ${t('USER')}`}
        description={(userId) ? t('BOARD_USER_EDIT_PAGE_DESCRIPTION') : t('BOARD_USER_CREATE_PAGE_DESCRIPTION')}
      />
      <form onSubmit={handleSubmit((data) => onFinish(data))}>
        <BoardContent>
          <BoardCard>
            <BoardCardHeader>
              <h3 className="board-h3">{t('BASIC_INFORMATION')}</h3>
            </BoardCardHeader>
            <div className="board-padding">
              <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
                <div>
                  <InputHook
                    register={{
                      ...register('email', {
                        required: true,
                        maxLength: 256,
                        minLength: 2,
                      }),
                    }}
                    label={t('EMAIL')}
                    disabled={isAnyLoading}
                    errors={errors}
                  />
                </div>
                <div>
                  <InputHook
                    register={{
                      ...register('name', {
                        required: true,
                        maxLength: 256,
                        minLength: 2,
                      }),
                    }}
                    label={t('NAME')}
                    disabled={isAnyLoading}
                    errors={errors}
                  />
                </div>
                <div>
                  <InputHook
                    register={{
                      ...register('surname', {
                        required: true,
                        maxLength: 256,
                        minLength: 2,
                      }),
                    }}
                    label={t('LAST_NAME')}
                    disabled={isAnyLoading}
                    errors={errors}
                  />
                </div>
                <div>
                  <InputHook
                    register={{
                      ...register('birthDate', {
                        required: true,
                        maxLength: 256,
                        minLength: 2,
                      }),
                    }}
                    label={t('DATE_OF_BIRTH')}
                    disabled={isAnyLoading}
                    errors={errors}
                    // onClick={() => { setSelectFechaVisible(true); }}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label className="text-base text-gray-700">
                    {t('GENDER')}
                  </label>
                  <RadioGroup
                    options={choicesGender}
                    disabled={isAnyLoading}
                    value={gender}
                    onChange={(e) => {
                      setGender(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </BoardCard>
          <div className="grid xl:grid-cols-1 2xl:grid-cols-2 gap-3">
            <div>
              <BoardCard className="mt-6">
                <BoardCardHeader>
                  <h3 className="board-h3">{t('CREDENTIALS')}</h3>
                </BoardCardHeader>
                <div className="board-padding">
                  <div className="grid xl:grid-cols-1 2xl:grid-cols-2 gap-3">
                    <div>
                      <InputHook
                        register={{
                          ...register('username', {
                            required: true,
                            maxLength: 256,
                            minLength: 2,
                          }),
                        }}
                        label={t('USERNAME')}
                        disabled={isAnyLoading}
                        errors={errors}
                      />
                    </div>
                    {
                      (!userId) &&
                      <div>
                        <InputHook
                          register={{
                            ...register('password', {
                              required: true,
                              maxLength: 256,
                              minLength: 2,
                            }),
                          }}
                          label={t('PASSWORD')}
                          disabled={isAnyLoading}
                          errors={errors}
                        />
                      </div>
                    }
                  </div>
                </div>
              </BoardCard>
            </div>
            <div>
              <BoardCard className="mt-6">
                <BoardCardHeader>
                  <h3 className="board-h3">{t('PERMISSIONS')}</h3>
                </BoardCardHeader>
                <div className="board-padding">
                  <div className="flex flex-col gap-1">
                    <label className="text-base text-gray-700">
                      Roles
                    </label>
                    <RadioGroup
                      value={role}
                      options={optionsRoles}
                      onChange={(e) => { setRole(e); }}
                      disabled={isAnyLoading}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-base text-gray-700">
                      {t('ORTHOPHOTOS')}
                    </label>
                    <CheckboxGroup
                      value={orthophotoValues}
                      options={optionsOrthophotos}
                      disabled={role === 'admin' || isAnyLoading}
                      onChange={(e) => { orthophotosChanged(e.map(o => `${o}`)); }}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-base text-gray-700">
                      GeoJSONs
                    </label>
                    <CheckboxGroup
                      value={geojsonValues}
                      options={optionsGeojsons}
                      disabled={role === 'admin' || isAnyLoading}
                      onChange={(e) => { geojsonsChanged(e.map(o => `${o}`)); }}
                    />
                  </div>
                </div>
              </BoardCard>
            </div>
          </div>
        </BoardContent>
        <BoardFooterBar
          onClick={() => {}}
          disabled={isAnyLoading}
          isLoading={isAnyLoading}
        >
          {userId ? t('UPDATE') : t('CREATE')} {t('USER')}
        </BoardFooterBar>
      </form>
      <SelectFechaModal
        visible={selectFechaVisible}
        setVisible={setSelectFechaVisible}
        handleOk={(val) => {
          setSelectFechaVisible(false);
          setFechaBirth(val);
        }}
        data={birthDate}
      />
    </>
  );
}
