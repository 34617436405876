import { ReactNode } from 'react';

export default function BoardCardHeader({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="flex justify-between py-4 border-b border-[#F0F0F0] mb-3">
      {children}
    </div>
  );
}
