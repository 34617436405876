import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GeojsonProperty, GeojsonPropertyTypes } from '../../../types/types';
import { alphanumeriDashes } from '../../../utils/regex';
import { find, findIndex } from 'lodash';
import { actionsNotification } from '../../../features/notificationSlice';
import Button from '../../ui/general/Button';
import Select from '../../ui/data-entry/Select';
import SelectTags from '../../ui/data-entry/SelectTags';
import Input from '../../ui/data-entry/Input';
import Modal from '../../ui/feedback/Modal';

export default function UpsertPropertyModal({
  visible,
  setVisible,
  properties,
  setProperties,
  action,
  propertyObject,
} : {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  properties: GeojsonProperty[];
  setProperties: Dispatch<SetStateAction<GeojsonProperty[]>>;
  action: 'ADD' | 'EDIT';
  propertyObject: GeojsonProperty | undefined;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [propertiesState, setPropertiesState] = useState<GeojsonProperty[]>([]);
  const [property, setProperty] = useState<string>('');
  const [type, setType] = useState<GeojsonPropertyTypes>(GeojsonPropertyTypes.STRING);
  const [options, setOptions] = useState<string[]>([]);

  const GeojsonPropertyTypesOptions = [
    { id: GeojsonPropertyTypes.STRING, name: t('STRING') },
    { id: GeojsonPropertyTypes.NUMBER, name: t('NUMBER') },
    { id: GeojsonPropertyTypes.OPTION, name: t('OPTION') },
  ];

  useEffect(() => {
    setPropertiesState([...properties]);
  }, [properties]);

  useEffect(() => {
    if (visible) {
      if (action === 'ADD') {
        setProperty('');
        setType(GeojsonPropertyTypes.STRING);
        setOptions([]);
      } else if (action === 'EDIT' && propertyObject) {
        setProperty(propertyObject.property);
        setType((propertyObject.type) ? propertyObject.type : GeojsonPropertyTypes.STRING);
        if (propertyObject.options && propertyObject.options.length > 0) {
          setOptions([...propertyObject.options]);
        } else {
          setOptions([]);
        }
      }
    }
  }, [visible, action, propertyObject]);

  const handleOk = () => {
    if (!property || property.trim().length === 0) {
      dispatch(actionsNotification.createAlert({ message: t('PROPERTY_CANNOT_BE_EMPTY'), type: 'warning' }));
      setProperty('');
      return;
    }

    const trimmedProperty = property.trim().replace(/\s+/g, ' ');

    if (!alphanumeriDashes.test(trimmedProperty)) {
      dispatch(actionsNotification.createAlert({ message: t('PROPERTY_CONTAINS_INVALID_CHAR'), type: 'warning' }));
      setProperty('');
      return;
    }

    if (action === 'ADD' && find(properties, o => o.property.toLowerCase() === trimmedProperty.toLowerCase())) {
      dispatch(actionsNotification.createAlert({ message: t('PROPERTY_ALREADY_EXISTS'), type: 'warning' }));
      return;
    }

    if (action === 'EDIT' && propertyObject?.property.toLowerCase() !== trimmedProperty.toLowerCase() && find(propertiesState, o => o.property.toLowerCase() === trimmedProperty.toLowerCase())) {
      dispatch(actionsNotification.createAlert({ message: t('PROPERTY_ALREADY_EXISTS'), type: 'warning' }));
      return;
    }

    if (type === GeojsonPropertyTypes.OPTION && options.length <= 0) {
      dispatch(actionsNotification.createAlert({ message: t('ADD_AT_LEAST_ONE_OPTION'), type: 'warning' }));
      return;
    }

    const temp = [...propertiesState];

    if (action === 'ADD') {
      if (type === GeojsonPropertyTypes.OPTION) {
        temp.unshift({ property: trimmedProperty, type, options });
      } else {
        temp.unshift({ property: trimmedProperty, type });
      }
    } else if (action === 'EDIT') {
      if (propertyObject) {
        const index = findIndex(temp, o => o.property === propertyObject.property);
        const newObj: GeojsonProperty = {
          property: trimmedProperty,
          type,
        };
        if (type === GeojsonPropertyTypes.OPTION) {
          newObj.options = options;
        } else {
          newObj.options = [];
        }
        temp[index] = {...newObj};
      }
    }
    setProperties([...temp]);
    setVisible(false);
  }

  return (
    <Modal
      title={`${t('ADD')} ${t('PROPERTY')}`}
      isOpen={visible}
      setIsOpen={setVisible}
      width={700}
    >
      <div>
        <Input
          id="property"
          label={t('PROPERTY')}
          placeholder={t('PROPERTY') as string}
          value={property}
          onChange={(e: any) => { setProperty(e.target.value); } }
        />
        <div className="flex flex-col gap-1 mt-3">
          <label className="text-base text-gray-700">
            {t('TYPE')}
          </label>
          <Select
            selected={{
              id: type,
              name: GeojsonPropertyTypesOptions.find((e) => e.id === type)?.name,
            }}
            setSelected={(e: any) => { setType(e.id); }}
            options={GeojsonPropertyTypesOptions}
          />
        </div>
        {
          (type === GeojsonPropertyTypes.OPTION) &&
          <SelectTags
            id="options"
            label={t('OPTIONS')}
            placeholder={`${t('ADD')} ${t('OPTION')}`}
            values={options}
            setValues={setOptions}
            className="mt-3"
          />
        }
        <Button
          className="mt-5"
          size="small"
          onClick={() => {
            handleOk();
          }}
        >
          {(action === 'ADD') && <>{t('ADD')}</>}
          {(action === 'EDIT') && <>{t('EDIT')}</>}
        </Button>
      </div>
    </Modal>
  );
}
