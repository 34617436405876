import { TranslationsType } from './TranslationTypes';

const Translations: TranslationsType = {
  TO_LOGIN: 'Login',
  ARE_YOU_NEW_TO_GEOXPRO: 'Are you new to Geoxpro?',
  REGISTER_HERE: 'Register here',
  EMAIL_OR_USERNAME: 'Email or Username',
  USERNAME: 'Username',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  LOGGING_IN: 'Logging In',
  FIELD_REQUIRED: 'This field is required.',
  SHOULD_HAVE_MORE_CHARS: 'Should have more characters.',
  SHOULD_HAVE_FEWER_CHARS: 'Should have fewer characters.',
  CONTAINS_INVALID_CHARS: 'Contains invalid characters.',
  TO_REGISTER: 'Register',
  REGISTERING: 'Registering',
  REGISTER: 'Register',
  ALREADY_HAVE_AN_ACCOUNT: 'Do you already have an account on Geoxpro?',
  LOG_IN_HERE: 'Log in here',
  REGISTER_POLICIES: 'At the moment of "Register", you agree to our <1>Terms and Conditions</1>, <2>Privacy Policy</2>, and <3>Cookies Policy</3>. We may send you notifications by SMS and email.',
  LOGGING_OUT: 'Logging Out',
  UNEXPECTED_ERROR: 'Unexpected error',
  GEOJSON_ADDED_SUCCESSFULLY: 'GeoJSON added successfully',
  NAME: 'Name',
  PRELOAD_DATA: 'Preload Data',
  CLICK_OR_DRAG_DOC: 'Click or drag the document to this area to upload it',
  DOCUMENT: 'Document',
  UPLOAD: 'Upload',
  SELECT_DOCUMENT: 'Select a document',
  PREVIEW: 'Preview',
  PROJECT: 'Project',
  ADD: 'Add',
  CREATING: 'Creating',
  CREATE: 'Create',
  CLOSE: 'Close',
  DOCUMENT_UPLOADED_SUCCESSFULLY: 'Document uploaded successfully',
  ERROR_UPLOADING_DOCUMENT: 'There was an error uploading the document',
  UPLOADING: 'Uploading',
  LOG_OUT: 'Log Out',
  COMPLETE_REGISTRATION: 'Complete Registration',
  LAYER_S_NAME: 'Layer\'s name',
  DOCUMENT_S_NAME: 'Document\'s name',
  PROJECT_S_NAME: 'Project\'s name',
  LAST_NAME: 'Last Name',
  PHONE_NUMBER: 'Phone Number',
  COMPANY: 'Company',
  COMPLETING_REGISTRATION: 'Completing Registration',
  TO_COMPLETE_REGISTRATION: 'Complete Registration',
  EDIT: 'Edit',
  DELETE: 'Delete',
  SUCCESSFUL_REGISTRATION: 'Successful Registration',
  LAYER_ADDED_SUCCESSFULLY: 'Layer Added Successfully',
  REGISTRATION_COMPLETED: 'Registration Completed',
  LOGGED_IN: 'Logged In',
  INVALID_CREDENTIALS: 'Invalid Credentials',
  ERROR_LOADING_GEOJSON: 'Error loading the GeoJSON',
  UPDATED_SUCCESSFULLY: 'updated successfully',
  CLASSIFICATION_TAGS_MUST_BE_UNIQUE: 'Classification tags must be unique',
  PROPERTY: 'Property',
  UPDATE: 'Update',
  PROPERTIES: 'Properties',
  PROPERTIES_UPDATED_SUCCESSFULLY: 'Properties updated successfully',
  SAVE: 'Save',
  CONDITION: 'Condition',
  THERE_ARE_NO_CONDITIONS: 'There are no conditions',
  LABEL: 'Label',
  INFORMATION: 'Information',
  BASIC_INFORMATION: 'Basic Information',
  GENERAL_INFORMATION: 'General Information',
  LIST: 'List',
  PROPERTY_ALREADY_EXISTS: 'Property already exists in the list of properties',
  STRING: 'String',
  NUMBER: 'Number',
  OPTION: 'Option',
  PROPERTY_CANNOT_BE_EMPTY: 'Property field cannot be empty',
  PROPERTY_CONTAINS_INVALID_CHAR: 'Property field contains an invalid character',
  ADD_AT_LEAST_ONE_OPTION: 'Add at least one option in the options field',
  MAP: 'Map',
  TYPE: 'Type',
  GRADIENT: 'Gradient',
  FIELD: 'Field',
  THERE_ARE_NO_CLASSIFICATIONS: 'There are no classifications',
  CLASSIFICATION: 'Classification',
  TO_PROCESS: 'Process',
  UNPROCESSED: 'Unprocessed',
  LOADED: 'Loaded',
  LAYER: 'Layer',
  FEATURE_NOT_AVAILABLE_SAMPLE: 'Feature not available in sample maps',
  MAP_ADDED_SUCCESSFULLY: 'Map Added Successfully',
  MAP_UPDATED_SUCCESSFULLY: 'Map Updated Successfully',
  ORTHOPHOTO_ADDED_SUCCESSFULLY: 'Orthophoto Added Successfully',
  ORTHOPHOTO_UPDATED_SUCCESSFULLY: 'Orthophoto Updated Successfully',
  PROJECT_ADDED_SUCCESSFULLY: 'Project Added Successfully',
  PROJECT_UPDATED_SUCCESSFULLY: 'Project Updated Successfully',
  USER_ADDED_SUCCESSFULLY: 'User Added Successfully',
  USER_UPDATED_SUCCESSFULLY: 'User Updated Successfully',
  RECORD_ADDED_SUCCESSFULLY: 'Record Added Successfully',
  RECORD_UPDATED_SUCCESSFULLY: 'Record Updated Successfully',
  NOTE_ADDED_SUCCESSFULLY: 'Note Added Successfully',
  ERROR_UPLOADING_THE_DOCUMENT: 'Error uploading the document',
  RECORD_NOT_SELECTED: 'Record not selected',
  RECORD_HAS_NOT_BEEN_DELETED: 'The record has not been deleted',
  DOCUMENT_NOT_SAVED: 'The document has not been saved',
  DOCUMENT_HAS_NOT_BEEN_UPLOADED: 'The document has not been uploaded',
  WINDOWS_CANNOT_BE_CLOSED_WHILE_LOADING: 'The window cannot be closed while it\'s loading',
  AN_ERROR_OCURRED_WHILE_REGISTERING_THE_NOTE: 'An error occurred while registering the note',
  SELECT_A_LAYER: 'Select a layer',
  THE_RECORD_HAS_NOT_BEEN_ADDED: 'The record has not been added',
  ADDRESS_NOT_FOUND: 'Address not found',
  INPUT_AN_ADDRESS: 'Input an Address',
  ORTHOPHOTO: 'Orthophoto',
  ORTHOPHOTOS: 'Orthophotos',
  CHART: 'Chart',
  CLICK_OR_DRAG_THE_DOCUMENT: 'Click or drag the document to this area to upload it',
  SELECTED_DOCUMENT: 'Selected document',
  COORDINATE: 'Coordinate',
  COORDINATES: 'Coordinates',
  POINT: 'Point',
  POLYGON: 'Polygon',
  POLYLINE: 'Polyline',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  DRAW: 'Draw',
  DESCRIPTION: 'Description',
  NOT_AVAILABLE: 'Not available',
  SETTINGS: 'Settings',
  DEFAULT_ZOOM: 'Default Zoom',
  MAXIMUM_ZOOM: 'Maximum Zoom',
  MINIMUM_ZOOM: 'Minimum Zoom',
  LOCATION: 'Location',
  USE_SETTINGS: 'Use settings',
  BASIC_SETTINGS: 'Basic Settings',
  VALUE: 'Value',
  ACTIONS: 'Actions',
  USERS: 'Users',
  MAPS: 'Maps',
  VIEW: 'View',
  USER: 'User',
  CREDENTIALS: 'Credentials',
  GENDER: 'Gender',
  PERMISSIONS: 'Permissions',
  DATE_OF_BIRTH: 'Date of Birth',
  ADD_TO_THIS_PROJECT: 'Add to this project',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  MY_ACCOUNT: 'My Account',
  ADMIN: 'Admin',
  PROJECTS: 'Projects',
  CONTENT: 'Content',
  NOTE: 'Note',
  ACTION: 'Action',
  SELECT: 'Select',
  OPEN_USER_MENU: 'Open user menu',
  OPEN_TRANSLATE_MENU: 'Open translate menu',
  OPEN_SIDEBAR: 'Open sidebar',
  CLOSE_SIDEBAR: 'Close sidebar',
  DATE: 'Date',
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  RECORD: 'Record',
  OPAQUE: 'Opaque',
  RECENT_ACTIVITY: 'Recent Activity',
  NO_RECENT_ACTIVITY: 'No recent activity',
  LOAD_MORE: 'Load more',
  LAYERS: 'Layers',
  DOCUMENTS: 'Documents',
  GEOMETRY: 'Geometry',
  OPTIONS: 'Options',
  ENTER_THE_ADDRESS_TO_SEARCH: 'Enter the address to search',
  NOTES: 'Notes',
  THERE_ARE_NO_NOTES: 'There are no notes',
  RECORDS: 'Records',
  CLASSIFICATIONS: 'Classifications',
  EXIT: 'Exit',
  THE_PAGE_HAS_AN_ERROR: 'The page has an error',
  SELECT_A_TAB: 'Select a tab',
  CLOSE_PANEL: 'Close panel',
  SLUG_ORTHOPHOTOS: 'orthophotos',
  SLUG_SETTINGS: 'settings',
  SLUG_USERS: 'users',
  SLUG_MAPS: 'maps',
  SLUG_MAP: 'map',
  SLUG_REGISTER: 'register',
  LANGUAGE: 'Language',
  SLUG_EDIT: 'edit',
  SLUG_ADD: 'add',
  PROTECTED: 'Protected',
  REGISTER_SLUG: 'register',
  SLUG_GEOJSONS: 'geojsons',
  BOARD_MAPS_PAGE_DESCRIPTION: 'A list of all the maps in this project including their name and description.',
  BOARD_ORTHOPHOTOS_PAGE_DESCRIPTION: 'A list of all the orthophotos in this project including their name.',
  BOARD_GEOJSONS_PAGE_DESCRIPTION: 'A list of all the GeoJSONs in this project including their name and processing status.',
  BOARD_USERS_PAGE_DESCRIPTION: 'A list of all the users in this project including their email, username, name and lastname.',
  BOARD_SETTINGS_PAGE_DESCRIPTION: 'Configure the project.',
  NO_DATA: 'No Data',
  GEOJSON: 'GeoJSON',
  GEOJSONS: 'GeoJSONs',
  BOARD_MAP_CREATE_PAGE_DESCRIPTION: 'Create and configure the intelligent map.',
  BOARD_MAP_EDIT_PAGE_DESCRIPTION: 'Edit and configure the intelligent map.',
  BOARD_ORTHOPHOTO_CREATE_PAGE_DESCRIPTION: 'Create and configure the orthophoto.',
  BOARD_ORTHOPHOTO_EDIT_PAGE_DESCRIPTION: 'Create and configure the orthophoto.',
  BOARD_GEOJSON_CREATE_PAGE_DESCRIPTION: 'Create and configure the GeoJSON',
  BOARD_GEOJSON_PROCESS_PAGE_DESCRIPTION: 'Edit, configure and process the GeoJSON',
  BOARD_USER_CREATE_PAGE_DESCRIPTION: 'Enter user data.',
  BOARD_USER_EDIT_PAGE_DESCRIPTION: 'Edit user data.',
};

export default Translations;
