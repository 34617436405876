import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [] as any,
  },
  reducers: {
    createAlert: (state, action) => {
      state.notifications.push({
        message: action.payload.message,
        type: action.payload.type
      });
    }
  },
});

export const actionsNotification = notificationSlice.actions;

export default notificationSlice;
