import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { actionsTranslate } from '../../features/translateSlice';

export function useTranslate() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { language } = useSelector((state: RootState) => state.translate);

  const currentLanguage = useMemo(() => i18n.resolvedLanguage, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (language !== currentLanguage) {
      i18n.changeLanguage(language);
    }
  }, [language, currentLanguage, i18n]);

  const setLanguage = (language: string) => {
    i18n.changeLanguage(language);
    dispatch(actionsTranslate.setLanguage(language));
  }

  return {
    setLanguage,
    currentLanguage,
  };
};
