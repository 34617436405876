import { ReactNode } from 'react';
import cx from 'clsx';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

export default function Collapse({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="w-full">
      <div className="mx-auto w-full rounded-2xl bg-white flex flex-col gap-2">
        {children}
      </div>
    </div>
  )
}

export function CollapsePanel({
  header,
  extra,
  children,
}: {
  header: ReactNode;
  extra: ReactNode;
  children: ReactNode;
}) {
  return (
    <Disclosure as="div">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={cx('flex w-full justify-between items-center bg-blue-100 px-2 py-4 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75', open ? 'rounded-t-lg' : 'rounded-lg')}
          >
            <div className="flex gap-1 text-base items-center">
              <ChevronUpIcon
                className={cx(
                  'h-5 w-5 text-blue-500 transition-all',
                  open ? 'rotate-180 transform' : '',
                )}
              />
              <span>{header}</span>
            </div>
            <div>
              {extra}
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="pt-4 py-5 px-4 text-sm text-gray-500 border border-t-0 rounded-b-lg">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
