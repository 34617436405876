import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { leapYear } from '../../utils/util';
import Select from '../ui/data-entry/Select';
import Modal from '../ui/feedback/Modal';
import Button from '../ui/general/Button';

export default function SelectFechaModal({
  visible,
  setVisible,
  handleOk,
  data,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  handleOk: (val: string) => void;
  data: string;
}) {
  const { t } = useTranslation();

  const meses = [
    { id: '01', name: t('JANUARY') },
    { id: '02', name: t('FEBRUARY') },
    { id: '03', name: t('MARCH') },
    { id: '04', name: t('APRIL') },
    { id: '05', name: t('MAY') },
    { id: '06', name: t('JUNE') },
    { id: '07', name: t('JULY') },
    { id: '08', name: t('AUGUST') },
    { id: '09', name: t('SEPTEMBER') },
    { id: '10', name: t('OCTOBER') },
    { id: '11', name: t('NOVEMBER') },
    { id: '12', name: t('DECEMBER') },
  ];

  const [anho, setAnho] = useState<string>(`${new Date().getFullYear()}`);
  const [mes, setMes] = useState<string>('01');
  const [dia, setDia] = useState<string>('01');
  const [diasLength, setDiasLength] = useState<number>(31);
  const [anhos, setAnhos] = useState<string[]>([]);

  useEffect(() => {
    if (visible) {
      const currentYear = new Date().getFullYear();
      const tempAnhos: string[] = [];
      for (let i = currentYear; i >= currentYear - 110; i--) {
        tempAnhos.push(`${i}`);
      }
      setAnhos([...tempAnhos]);
      if (data) {
        const dateSplitted = data.split('-');
        setDia(dateSplitted[2]);
        setMes(dateSplitted[1]);
        setAnho(dateSplitted[0]);
      } else {
        setDia('01');
        setMes('01');
        setAnho('2000');
      }
    }
  }, [visible, data]);

  useEffect(() => {
    if (mes === '01' || mes === '03' || mes === '05' || mes === '07' || mes === '08' || mes === '10' || mes === '12') {
      setDiasLength(31);
    } else if (mes === '04' || mes === '06' || mes === '09' || mes === '11') {
      setDiasLength(30);
      if (dia === '31') {
        setDia('30');
      }
    } else {
      if (leapYear(parseInt(anho))) {
        setDiasLength(29);
        if (dia === '30' || dia === '31') {
          setDia('29');
        }
      } else {
        setDiasLength(28);
        if (dia === '29' || dia === '30' || dia === '31') {
          setDia('28');
        }
      }
    }
  }, [anho, mes, dia]);

  return(
    <Modal
      isOpen={visible}
      setIsOpen={setVisible}
      title={`${t('SELECT')} ${t('DATE')}`}
      width={700}
    >
      <div className="flex gap-1">
        <Select
          className="w-[90px]"
          selected={{
            id: dia,
            name: dia,
          }}
          setSelected={(e: any) => setDia(e.id)}
          options={
            Array.from({ length: diasLength }, (_, i) => i + 1).map((o, index) => {
              const val = String(index + 1).padStart(2, '0');
              return ({ id: val, name: val });
            })
          }
        />
        <Select
          className="w-[150px]"
          selected={{
            id: mes,
            name: meses.find((e) => e.id === mes)?.name,
          }}
          setSelected={(e: any) => setMes(e.id)}
          options={meses}
        />
        <Select
          className="w-[100px]"
          selected={{
            id: anho,
            name: anho,
          }}
          setSelected={(e: any) => setAnho(e.id)}
          options={anhos.map((o) => ({ id: o, name: o }))}
        />
      </div>
      <Button
        className="mt-5"
        onClick={() => { handleOk(`${anho}-${mes}-${dia}`); }}
      >
        {t('SELECT')}
      </Button>
    </Modal>
  );
}
