import { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actionsNotification } from '../../../features/notificationSlice';
import { useGetUsersQuery, useUpdateUserEnableMutation, useAttachUserMutation } from '../../../features/user';
import { useGetProjectQuery } from '../../../features/project';
import { RootState } from '../../../store';
import { UserSchema } from '@/types/types';
import { useDispatch } from 'react-redux';
import Breadcrumbs from '../../global/Breadcrumbs';
import Button from '../../ui/general/Button';
import Badge from '../../ui/data-display/Badge';
import Table from '../../ui/data-display/Table';
import AccountTieIcon from 'src/components/icons/mdi/AccountTieIcon';
import BoardContent from '../common/BoardContent';
import BoardHeader from '../common/BoardHeader';

export default function Users() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { user } = useSelector((state: RootState) => state.auth);

  const { data: users, isFetching, isLoading: userIsLoading, isError } = useGetUsersQuery({ projectId });
  const { data: project } = useGetProjectQuery({ projectId });
  const [attachUser, { isLoading: attachUserIsLoading }] = useAttachUserMutation();
  const [updateUserEnable, { isLoading: updateUserEnableIsLoading }] = useUpdateUserEnableMutation();
  
  const isAnyLoading = useMemo<boolean>(() => (isFetching || userIsLoading || updateUserEnableIsLoading || attachUserIsLoading), [isFetching, userIsLoading, updateUserEnableIsLoading, attachUserIsLoading]);

  const emailColumnItem = {
    title: t('EMAIL'),
    key: 'email',
    render: (record: UserSchema) => {
      if (record.isOwner) {
        return (
          <div className="flex items-center">
            <AccountTieIcon className="text-[#096dd9] w-5" />
            &nbsp;
            {record.email}
            &nbsp;
            {
              (user.id === record.id) &&
              <Badge color="blue">{t('MY_ACCOUNT')}</Badge>
            }
          </div>
        );
      } else {
        return (
          <>
            {record.email}
            &nbsp;
            {
              (user.id === record.id) &&
              <Badge color="blue">{t('MY_ACCOUNT')}</Badge>
            }
            {
              (!record.enabled) &&
              <Badge color="gray">{t('DISABLED')}</Badge>
            }
          </>
        );
      }
    },
  };

  useEffect(() => {
    if (isError) {
      dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
    }
  }, [isError, dispatch, t]);

  const columns = [
    {
      ...emailColumnItem,
    },
    {
      title: t('USER'),
      key: 'displayName',
      render: (record: UserSchema) => (record.displayName),
    },
    {
      title: t('NAME'),
      key: 'name',
      render: (record: UserSchema) => (record.name),
    },
    {
      title: t('LAST_NAME'),
      key: 'surname',
      render: (record: UserSchema) => (record.surname),
    },
    {
      title: t('ACTIONS'),
      key: 'action',
      render: (record: UserSchema) => (
        <div className="flex gap-4 items-center">
          {
            (!record.isOwner && user.id !== record.id && record.hasPermissions) &&
            <>
              <Link
                to={`/board/${projectId}/${t('SLUG_USERS')}/${t('SLUG_EDIT')}/${record.id}`}
              >
                {t('EDIT')}
              </Link>
              <Button
                size="small"
                onClick={() => {
                  if (record.enabled) {
                    updateUserEnable({ id: record.id, enabled: false, projectId });
                  } else {
                    updateUserEnable({ id: record.id, enabled: true, projectId });
                  }
                }}
              >
                {record.enabled ? t('DISABLE') : t('ENABLE')}
              </Button>
            </>
          }
          {
            (!record.isOwner && user.id !== record.id && !record.hasPermissions) &&
            <Button
              size="small"
              onClick={() => {
                attachUser({ id: record.id, projectId });
              }}
            >
              {t('ADD_TO_THIS_PROJECT')}
            </Button>
          }
        </div>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(project) ? project.name : ''}`, current: false },
          { name: t('USERS'), current: true },
        ]}
      />
      <BoardHeader
        title={t('USERS')}
        description={t('BOARD_USERS_PAGE_DESCRIPTION')}
        link={`/board/${projectId}/${t('SLUG_USERS')}/${t('SLUG_ADD')}`}
        linkText={`${t('ADD')} ${t('USER')}`}
      />
      <BoardContent>
        <Table
          columns={columns}
          dataSource={users}
          isLoading={isAnyLoading}
        />
      </BoardContent>
    </>
  );
}
