import { TranslationsType } from './TranslationTypes';

const Translations: TranslationsType = {
  TO_LOGIN: 'Iniciar Sesión',
  ARE_YOU_NEW_TO_GEOXPRO: '¿Eres nuevo en Geoxpro?',
  REGISTER_HERE: 'Registrate aquí',
  EMAIL_OR_USERNAME: 'Correo electrónico o usuario',
  USERNAME: 'Nombre de Usuario',
  EMAIL: 'Correo Electrónico',
  PASSWORD: 'Contraseña',
  LOGGING_IN: 'Iniciando Sesión',
  FIELD_REQUIRED: 'Este campo es requerido.',
  SHOULD_HAVE_MORE_CHARS: 'Debe tener más caracteres.',
  SHOULD_HAVE_FEWER_CHARS: 'Debe tener menos caracteres.',
  CONTAINS_INVALID_CHARS: 'Contiene caracteres inválidos.',
  TO_REGISTER: 'Registro',
  REGISTERING: 'Registrando',
  REGISTER: 'Registrarse',
  ALREADY_HAVE_AN_ACCOUNT: '¿Ya tienes cuenta en Geoxpro?',
  LOG_IN_HERE: 'Inicia sesión aquí',
  REGISTER_POLICIES: 'Al momento de "Registrarse", aceptas nuestras <1>Condiciones</1>, la <2>Política de privacidad</2> y la <3>Política de cookies</3>. Es posible que te enviemos notificaciones por SMS y Correo electrónico.',
  LOGGING_OUT: 'Cerrando Sesión',
  UNEXPECTED_ERROR: 'Error inesperado',
  GEOJSON_ADDED_SUCCESSFULLY: 'GeoJSON agregado con éxito',
  NAME: 'Nombre',
  PRELOAD_DATA: 'Precargar Datos',
  CLICK_OR_DRAG_DOC: 'Haga clic o arrastre el documento a esta área para cargarlo',
  DOCUMENT: 'Documento',
  UPLOAD: 'Subir',
  SELECT_DOCUMENT: 'Selecciona un documento',
  PREVIEW: 'Vista Previa',
  PROJECT: 'Proyecto',
  ADD: 'Agregar',
  CREATING: 'Creando',
  CREATE: 'Crear',
  CLOSE: 'Cerrar',
  DOCUMENT_UPLOADED_SUCCESSFULLY: 'Documento subido con éxito',
  ERROR_UPLOADING_DOCUMENT: 'Hubo un error al subir el documento',
  UPLOADING: 'Subiendo',
  LOG_OUT: 'Cerrar Sesión',
  COMPLETE_REGISTRATION: 'Completa tu Registro',
  LAYER_S_NAME: 'Nombre de la capa',
  DOCUMENT_S_NAME: 'Nombre del documento',
  PROJECT_S_NAME: 'Nombre del proyecto',
  LAST_NAME: 'Apellido',
  PHONE_NUMBER: 'Número de Teléfono',
  COMPANY: 'Empresa',
  COMPLETING_REGISTRATION: 'Completando Registro',
  TO_COMPLETE_REGISTRATION: 'Completar Registro',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  SUCCESSFUL_REGISTRATION: 'Registro Exitoso',
  LAYER_ADDED_SUCCESSFULLY: 'Capa Agregada con Éxito',
  REGISTRATION_COMPLETED: 'Registro Completado',
  LOGGED_IN: 'Sesión Iniciada',
  INVALID_CREDENTIALS: 'Credenciales Inválidas',
  ERROR_LOADING_GEOJSON: 'Error al cargar el GeoJSON',
  UPDATED_SUCCESSFULLY: 'actualizado con éxito',
  CLASSIFICATION_TAGS_MUST_BE_UNIQUE: 'Las etiquetas de clasificación deben ser únicas',
  PROPERTY: 'Propiedad',
  UPDATE: 'Actualizar',
  PROPERTIES: 'Propiedades',
  PROPERTIES_UPDATED_SUCCESSFULLY: 'Propiedades actualizadas con éxito',
  SAVE: 'Guardar',
  CONDITION: 'Condición',
  THERE_ARE_NO_CONDITIONS: 'No hay condiciones',
  LABEL: 'Etiqueta',
  INFORMATION: 'Información',
  BASIC_INFORMATION: 'Información Básica',
  GENERAL_INFORMATION: 'Información General',
  LIST: 'Lista',
  PROPERTY_ALREADY_EXISTS: 'Propiedad ya existe en la lista de propiedades',
  STRING: 'Cadena de Texto',
  NUMBER: 'Número',
  OPTION: 'Opción',
  PROPERTY_CANNOT_BE_EMPTY: 'Campo propiedad no puede ser vacío',
  PROPERTY_CONTAINS_INVALID_CHAR: 'Campo propiedad contiene un caracter inválido',
  ADD_AT_LEAST_ONE_OPTION: 'Agrega por lo menos una opción en el campo de opciones',
  MAP: 'Mapa',
  TYPE: 'Tipo',
  GRADIENT: 'Degradado',
  FIELD: 'Campo',
  THERE_ARE_NO_CLASSIFICATIONS: 'No hay clasificaciones',
  CLASSIFICATION: 'Clasificación',
  TO_PROCESS: 'Procesar',
  UNPROCESSED: 'Sin Procesar',
  LOADED: 'Cargado',
  LAYER: 'Capa',
  FEATURE_NOT_AVAILABLE_SAMPLE: 'Funcionalidad no disponible en mapas ejemplo',
  MAP_ADDED_SUCCESSFULLY: 'Mapa Agregado con Éxito',
  MAP_UPDATED_SUCCESSFULLY: 'Mapa Actualizado con Éxito',
  ORTHOPHOTO_ADDED_SUCCESSFULLY: 'Ortofoto Agregada con Éxito',
  ORTHOPHOTO_UPDATED_SUCCESSFULLY: 'Ortofoto Actualizada con Éxito',
  PROJECT_ADDED_SUCCESSFULLY: 'Proyecto Agregado con Éxito',
  PROJECT_UPDATED_SUCCESSFULLY: 'Proyecto Actualizado con Éxito',
  USER_ADDED_SUCCESSFULLY: 'Usuario Agregado con Éxito',
  USER_UPDATED_SUCCESSFULLY: 'Usuario Actualizado con Éxito',
  RECORD_ADDED_SUCCESSFULLY: 'Registro Agregado con Éxito',
  RECORD_UPDATED_SUCCESSFULLY: 'Registro Actualizado con Éxito',
  NOTE_ADDED_SUCCESSFULLY: 'Nota Agregada con Éxito',
  ERROR_UPLOADING_THE_DOCUMENT: 'Error al subir el documento',
  RECORD_NOT_SELECTED: 'Registro no seleccionado',
  RECORD_HAS_NOT_BEEN_DELETED: 'El registro no ha sido eliminado',
  DOCUMENT_NOT_SAVED: 'El documento no ha sido guardado',
  DOCUMENT_HAS_NOT_BEEN_UPLOADED: 'El documento no ha sido cargado',
  WINDOWS_CANNOT_BE_CLOSED_WHILE_LOADING: 'No se puede cerrar la ventana mientras está cargando',
  AN_ERROR_OCURRED_WHILE_REGISTERING_THE_NOTE: 'Un error ocurrió al registrar la nota',
  SELECT_A_LAYER: 'Selecciona una capa',
  THE_RECORD_HAS_NOT_BEEN_ADDED: 'No se ha agregado el registro',
  ADDRESS_NOT_FOUND: 'No se encontró la dirección',
  INPUT_AN_ADDRESS: 'Ingresa una Dirección',
  ORTHOPHOTO: 'Ortofoto',
  ORTHOPHOTOS: 'Ortofotos',
  CHART: 'Gráfica',
  CLICK_OR_DRAG_THE_DOCUMENT: 'Haga clic o arrastre el documento a esta área para cargarlo',
  SELECTED_DOCUMENT: 'Documento seleccionado',
  COORDINATE: 'Coordenada',
  COORDINATES: 'Coordenadas',
  POINT: 'Punto',
  POLYGON: 'Polígono',
  POLYLINE: 'Cadena de líneas',
  LATITUDE: 'Latitud',
  LONGITUDE: 'Longitud',
  DRAW: 'Dibujar',
  DESCRIPTION: 'Descripción',
  NOT_AVAILABLE: 'No disponible',
  SETTINGS: 'Configuración',
  DEFAULT_ZOOM: 'Zoom por defecto',
  MAXIMUM_ZOOM: 'Zoom Máximo',
  MINIMUM_ZOOM: 'Zoom Mínimo',
  LOCATION: 'Ubicación',
  USE_SETTINGS: 'Usar configuración',
  BASIC_SETTINGS: 'Configuración Básica',
  VALUE: 'Valor',
  ACTIONS: 'Actions',
  USERS: 'Usuarios',
  MAPS: 'Mapas',
  VIEW: 'Ver',
  USER: 'Usuario',
  CREDENTIALS: 'Credenciales',
  GENDER: 'Género',
  PERMISSIONS: 'Permisos',
  DATE_OF_BIRTH: 'Fecha de Birth',
  ADD_TO_THIS_PROJECT: 'Agregar a este proyecto',
  ENABLE: 'Habilitar',
  DISABLE: 'Deshabilitar',
  ENABLED: 'Habilitado',
  DISABLED: 'Deshabilitado',
  MY_ACCOUNT: 'Mi Cuenta',
  ADMIN: 'Administrador',
  PROJECTS: 'Proyectos',
  CONTENT: 'Contenido',
  NOTE: 'Nota',
  ACTION: 'Acción',
  SELECT: 'Select',
  OPEN_USER_MENU: 'Abrir menú de usuario',
  OPEN_TRANSLATE_MENU: 'Abrir menú de idiomas',
  OPEN_SIDEBAR: 'Abrir la barra lateral',
  CLOSE_SIDEBAR: 'Cerrar la barra lateral',
  DATE: 'Fecha',
  JANUARY: 'Enero',
  FEBRUARY: 'Febrero',
  MARCH: 'Marzo',
  APRIL: 'Abril',
  MAY: 'Mayo',
  JUNE: 'Junio',
  JULY: 'Julio',
  AUGUST: 'Agosto',
  SEPTEMBER: 'Septiembre',
  OCTOBER: 'Octubre',
  NOVEMBER: 'Noviembre',
  DECEMBER: 'Diciembre',
  RECORD: 'Registro',
  OPAQUE: 'Opaco',
  RECENT_ACTIVITY: 'Actividad Reciente',
  NO_RECENT_ACTIVITY: 'No hay actividad reciente',
  LOAD_MORE: 'Cargar más',
  LAYERS: 'Capas',
  DOCUMENTS: 'Documentos',
  GEOMETRY: 'Geometría',
  OPTIONS: 'Opciones',
  ENTER_THE_ADDRESS_TO_SEARCH: 'Ingresa la dirección a busca',
  NOTES: 'Notas',
  THERE_ARE_NO_NOTES: 'No hay notas',
  RECORDS: 'Registros',
  CLASSIFICATIONS: 'Clasificaciones',
  EXIT: 'Salir',
  THE_PAGE_HAS_AN_ERROR: 'La página tiene un error',
  SELECT_A_TAB: 'Selecciona una pestaña',
  CLOSE_PANEL: 'Cerrar panel',
  SLUG_ORTHOPHOTOS: 'ortofotos',
  SLUG_SETTINGS: 'configuracion',
  SLUG_USERS: 'usuarios',
  SLUG_MAPS: 'mapas',
  SLUG_MAP: 'mapa',
  SLUG_REGISTER: 'registro',
  LANGUAGE: 'Idioma',
  SLUG_EDIT: 'editar',
  SLUG_ADD: 'agregar',
  PROTECTED: 'Protegida',
  REGISTER_SLUG: 'registro',
  SLUG_GEOJSONS: 'geojsons',
  BOARD_MAPS_PAGE_DESCRIPTION: 'Una lista de todos los mapas en este proyecto, incluyendo su nombre y descripción.',
  BOARD_ORTHOPHOTOS_PAGE_DESCRIPTION: 'Una lista de todas las ortofotos en este proyecto, incluyendo su nombre.',
  BOARD_GEOJSONS_PAGE_DESCRIPTION: 'Una lista de todos los GeoJSONs en este proyecto, incluyendo su nombre y estado de procesamiento.',
  BOARD_USERS_PAGE_DESCRIPTION: 'Una lista de todos los usuarios en este proyecto, incluyendo su correo electrónico, nombre de usuario, nombre y apellido.',
  BOARD_SETTINGS_PAGE_DESCRIPTION: 'Configura el proyecto.',
  NO_DATA: 'Sin Datos',
  GEOJSON: 'GeoJSON',
  GEOJSONS: 'GeoJSONs',
  BOARD_MAP_CREATE_PAGE_DESCRIPTION: 'Crea y configura el mapa inteligente.',
  BOARD_MAP_EDIT_PAGE_DESCRIPTION: 'Edita y configura el mapa inteligente.',
  BOARD_ORTHOPHOTO_CREATE_PAGE_DESCRIPTION: 'Crea y configura la ortofoto.',
  BOARD_ORTHOPHOTO_EDIT_PAGE_DESCRIPTION: 'Edita y configura la ortofoto.',
  BOARD_GEOJSON_CREATE_PAGE_DESCRIPTION: 'Crea y configura el GeoJSON.',
  BOARD_GEOJSON_PROCESS_PAGE_DESCRIPTION: 'Edita, configura y procesa el GeoJSON.',
  BOARD_USER_CREATE_PAGE_DESCRIPTION: 'Ingresa los datos del usuario.',
  BOARD_USER_EDIT_PAGE_DESCRIPTION: 'Edita los datos del usuario.',
};

export default Translations;
