import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import EN from './components/translations/en';
import ES from './components/translations/es';

i18next
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    fallbackLng: 'en',
    lng: 'es',
    resources: {
      en: {
        translation: EN,
      },
      es: {
        translation: ES,
      },
    },
  });
