import { appApi } from './api';
import { getToken } from '../store';

export const aws = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    generatePresignedUrl: builder.mutation({
      query: (params) => ({
        url: '/api/v1/aws/generate-presigned-url',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Aws', id: 'LIST' }],
    }),
    generatePresignedUrlToPutObject: builder.mutation({
      query: (params) => ({
        url: '/api/v1/map-aws/generate-presigned-url-to-put-object',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Aws', id: 'LIST' }],
    }),
    getOrthophotosSignedUrls: builder.query({
      query: (params) => ({
        url: `/api/v1/map-orthophotos/get-orthophotos-signed-urls/${params.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [{ type: 'Aws', id: args || '' }],
    }),
    generatePresignedUrlToGetObject: builder.mutation({
      query: (params) => ({
        url: '/api/v1/map-aws/generate-presigned-url-to-get-object',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Aws', id: 'LIST' }],
    }),
  }),
});

export const {
  useGeneratePresignedUrlMutation,
  useGeneratePresignedUrlToPutObjectMutation,
  useLazyGetOrthophotosSignedUrlsQuery,
  useGeneratePresignedUrlToGetObjectMutation,
} = aws;
