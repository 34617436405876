import qs from 'qs';
import { omit } from 'lodash';
import { appApi } from './api';
import { getToken } from '../store';
import { OrthophotoSchema } from '@/types/types';

export const orthophoto = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getOrthophotos: builder.query({
      query: (params) => ({
        url: `/api/v1/orthophotos?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [
        { type: 'Orthophoto', id: 'LIST' },
        ...(
          (returnValue) ?
          returnValue.map((orthophoto: OrthophotoSchema) => ({
            type: 'Orthophoto',
            id: orthophoto.id
          })) :
          []
        ),
      ],
    }),
    getOrthophoto: builder.query({
      query: (params) => ({
        url: `/api/v1/orthophotos/${params.orthophotoId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [{ type: 'Orthophoto', id: args || '' }],
    }),
    createOrthophoto: builder.mutation({
      query: (params) => ({
        url: '/api/v1/orthophotos',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Orthophoto', id: 'LIST' }],
    }),
    updateOrthophoto: builder.mutation({
      query: (params) => ({
        url: `/api/v1/orthophotos/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id'),
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: (returnValue, error, args) => [{ type: 'Orthophoto', id: args.id }],
    }),
    createUserOrthophoto: builder.mutation({
      query: (params) => ({
        url: '/api/v1/orthophotos/user',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Orthophoto', id: 'LIST' }],
    }),
    updateUserOrthophoto: builder.mutation({
      query: (params) => ({
        url: `/api/v1/orthophotos/user/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id'),
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Orthophoto', id: 'LIST' }],
    }),
    updateUserPasswordOrthophoto: builder.mutation({
      query: (params) => ({
        url: `/api/v1/orthophotos/user/password/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id'),
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Orthophoto', id: 'LIST' }],
    }),
    updateUserStatusOrthophoto: builder.mutation({
      query: (params) => ({
        url: `/api/v1/orthophotos/user/status/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id'),
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Orthophoto', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetOrthophotosQuery,
  useLazyGetOrthophotoQuery,
  useCreateOrthophotoMutation,
  useUpdateOrthophotoMutation,
  useCreateUserOrthophotoMutation,
  useUpdateUserOrthophotoMutation,
  useUpdateUserPasswordOrthophotoMutation,
  useUpdateUserStatusOrthophotoMutation,
} = orthophoto;
