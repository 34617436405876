import qs from 'qs';
import { appApi } from './api';
import { getToken } from '../store';

export const registry = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getRegistriesByGeojsons: build.query({
      query: (params) => ({
        url: `/api/v1/registries/by-geojsons?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (result, error, arg) => [
        ...(
          (result) ?
          result.map((registry: { id: string }) => ({
            type: 'Registry',
            id: registry.id,
          })) :
          []
        ),
      ],
    }),
    createRegistryByMap: build.mutation({
      query: (params) => ({
        url: '/api/v1/map-registries/create-registry/by-map/', // Request to Map Controller
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Registry', id: 'LIST' }],
    }),
    updateRegistryPropertiesByMap: build.mutation({
      query: (params) => ({
        url: '/api/v1/map-registries/update-registry-properties/by-map/', // Request to Map Controller
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    createRegistryPropertiesByMap: build.mutation({
      query: (params) => ({
        url: '/api/v1/map-registries/create-registry-properties/by-map/', // Request to Map Controller
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    updateRegistryGeometryByMap: build.mutation({
      query: (params) => ({
        url: '/api/v1/map-registries/update-registry-geometry/by-map/', // Request to Map Controller
        method: 'PUT',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    createRegistryDocumentByMap: build.mutation({
      query: (params) => ({
        url: '/api/v1/map-registries/create-registry-document/by-map/',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    deleteRegistryByMap: build.mutation({
      query: (params) => ({
        url: '/api/v1/map-registries/delete-registry/by-map/', // Request to Map Controller
        method: 'DELETE',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetRegistriesByGeojsonsQuery,
  useCreateRegistryByMapMutation,
  useUpdateRegistryPropertiesByMapMutation,
  useCreateRegistryPropertiesByMapMutation,
  useUpdateRegistryGeometryByMapMutation,
  useCreateRegistryDocumentByMapMutation,
  useDeleteRegistryByMapMutation,
} = registry;
