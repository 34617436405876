import { AuthUserSchema, ProjectSchema } from '@/types/types';
import { includes } from 'lodash';

export const leapYear = (year: number) => {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

export const onlyOwner = (user: AuthUserSchema) => {
  if (!user) {
    return false;
  }

  if (user.isOwner) {
    return true;
  }
}

export const onlyOwnerOrAdmin = (user: AuthUserSchema, project: ProjectSchema) => {
  if (!user) {
    return false;
  }

  if (user.isOwner) {
    return true;
  }

  if (!project || !project.permission) {
    return false;
  }

  if (!project || !project.permission || project.permission.enabled !== true) {
    return false;
  }

  if (project && project.permission && project.permission.role === 'admin') {
    return true;
  }

  return false;
}

export const checkAuthorization = (user: AuthUserSchema, project: ProjectSchema, action: string) => {
  if (!user || !project) {
    return false;
  }

  if (user.isOwner) {
    return true;
  }

  if (!project || !project.permission) {
    return false;
  }

  if (!project || !project.permission || project.permission.enabled !== true) {
    return false;
  }

  if (project && project.permission && project.permission.role === 'admin') {
    return true;
  }

  if (project && project.permission && project.permission.permissions && includes(project.permission.permissions, action)) {
    return true;
  }

  return false;
}

export const generateRandomPassword = () => {
  let result = '';
  let result2 = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 4; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    result2 += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `${result}_${result2}`;
}

export const permissionsParser = (model: string, permissions: string[]) => {
  const tempArray: string[] = [];
  permissions.forEach((e: string) => {
    tempArray.push(`${model}_${e}`);
  });
  return tempArray;
}

export const removeAccents = (string = '') => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
