import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { find, findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FeatureSpecial, GeojsonSchema, MapSchema, RegistriesObjectState } from '@/types/types';
import UpdatePropertiesModal from './UpdatePropertiesModal';
import MapFeatureProperties from './feature-drawer/MapFeatureProperties';
import MapFeatureGeometry from './feature-drawer/MapFeatureGeometry';
import MapUpdateRegistryGeometry from './selected-layer/MapUpdateRegistryGeometry';
import MapFeatureFiles from './feature-drawer/MapFeatureFiles';
import AddRegistryFileModal from './feature-drawer/AddRegistryFileModal';
import MapFeatureOptions from './feature-drawer/MapFeatureOptions';
import { useDispatch } from 'react-redux';
import { actionsNotification } from '../../../features/notificationSlice';
import Tabs from '../../ui/data-display/Tabs';
import Drawer from '../../ui/feedback/Drawer';

export default function MapFeatureDrawer({
  visible,
  setVisible,
  selectedFeature,
  selectedGeojson,
  map,
  loadedGeojsons,
  registriesObject,
  setRegistriesObject,
  setSelectedFeature,
  onClose,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedFeature: FeatureSpecial | undefined;
  selectedGeojson: GeojsonSchema | undefined;
  map: MapSchema;
  loadedGeojsons: GeojsonSchema[];
  registriesObject: RegistriesObjectState;
  setRegistriesObject: Dispatch<SetStateAction<RegistriesObjectState>>;
  setSelectedFeature: Dispatch<SetStateAction<FeatureSpecial | undefined>>;
  onClose: () => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [updatePropertiesModalVisible, setUpdatePropertiesModalVisible] = useState<boolean>(false);
  const [updateRegistryGeometryModalVisible, setUpdateRegistryGeometryModalVisible] = useState<boolean>(false);
  const [geojson, setGeojson] = useState<GeojsonSchema>();
  const [addRegistryFileModalOpen, setAddRegistryFileModalOpen] = useState<boolean>(false);

  const items = [
    {
      label: t('PROPERTIES'),
      key: 'properties',
      children: (
        <MapFeatureProperties
          selectedFeature={selectedFeature}
          loadedGeojsons={loadedGeojsons}
          setUpdatePropertiesModalVisible={setUpdatePropertiesModalVisible}
          map={map}
        />
      ),
    },
    {
      label: t('DOCUMENTS'),
      key: 'documents',
      children: (
        <MapFeatureFiles
          map={map}
          selectedFeature={selectedFeature}
          handleAddFile={() => {
            if (map.isExample) {
              dispatch(actionsNotification.createAlert({ message: t('FEATURE_NOT_AVAILABLE_SAMPLE'), type: 'warning' }));
              return;
            }

            setAddRegistryFileModalOpen(true);
          }}
        />
      ),
    },
    {
      label: t('GEOMETRY'),
      key: 'geometria',
      children: (
        <MapFeatureGeometry
          selectedFeature={selectedFeature}
          setFeatureDrawerVisible={setVisible}
          setUpdateRegistryGeometryModalVisible={setUpdateRegistryGeometryModalVisible}
        />
      ),
    },
    {
      label: t('OPTIONS'),
      key: 'options',
      children: (
        <MapFeatureOptions
          map={map}
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
          registriesObject={registriesObject}
          setRegistriesObject={setRegistriesObject}
        />
      ),
    },
  ];

  useEffect(() => {
    const temp = find(loadedGeojsons, (o: GeojsonSchema) => o.id === selectedFeature?.geojson); 
    if (temp) {
      setGeojson({...temp});
    } else {
      setGeojson(undefined);
    }
  }, [selectedFeature, loadedGeojsons]);

  const handleOk = (val: any) => {
    setSelectedFeature({...val});
    const temp = {...registriesObject};
    if (temp && temp[val.geojson] && findIndex(temp[val.geojson], (o) => o.id === val.id) >= 0) {
      const tempIndex = findIndex(temp[val.geojson], (o) => o.id === val.id);
      temp[val.geojson][tempIndex] = {...val};
      setRegistriesObject({...temp});
    }
    setUpdatePropertiesModalVisible(false);
  }

  const handleUpdateRegistryGeometry = (val: any) => {
    setSelectedFeature({...val});
    const temp = {...registriesObject};
    if (temp && temp[val.geojson] && findIndex(temp[val.geojson], (o) => o.id === val.id) >= 0) {
      const tempIndex = findIndex(temp[val.geojson], (o) => o.id === val.id);
      temp[val.geojson][tempIndex] = {...val};
      setRegistriesObject({...temp});
    }
    setUpdateRegistryGeometryModalVisible(false);
  }

  return (
    <Drawer
      isOpen={visible}
      onClose={onClose}
      closable={false}
      width={500}
    >
      <Tabs defaultActiveKey="properties" items={items} />
      <UpdatePropertiesModal
        visible={updatePropertiesModalVisible}
        setVisible={setUpdatePropertiesModalVisible}
        feature={selectedFeature}
        map={map}
        geojson={geojson}
        handleOk={(val) => { handleOk(val); }}
      />
      <MapUpdateRegistryGeometry
        visible={updateRegistryGeometryModalVisible}
        setVisible={setUpdateRegistryGeometryModalVisible}
        feature={selectedFeature}
        map={map}
        handleOk={(data) => { handleUpdateRegistryGeometry(data); }}
      />
      <AddRegistryFileModal
        open={addRegistryFileModalOpen}
        setOpen={setAddRegistryFileModalOpen}
        selectedFeature={selectedFeature}
        map={map}
        handleOk={(val) => { handleOk(val); }}
        onCancel={() => { setAddRegistryFileModalOpen(false); }}
      />
    </Drawer>
  );
}
