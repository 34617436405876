import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

export default function BoardEmpty({
  icon = <></>,
  title = '',
  description = '',
  buttonText = '',
  buttonAction = () => {},
  canCreate = false,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
  canCreate: boolean;
}) {
  return (
    <div className="text-center">
      {icon}
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      {
        (canCreate) &&
        <>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
          <div className="mt-6">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => { buttonAction(); }}
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              {buttonText}
            </button>
          </div>
        </>
      }
    </div>
  )
}
