import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../ui/feedback/Modal';

export default function GradientSelectModal({
  visible,
  setVisible,
  selectgradient,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectgradient: (val: [string, string]) => void;
}) {
  const { t } = useTranslation();

  const gradients: [string, string][] = [
    ['#8ff1c9', '#232123'],
    ['#8fc9f1', '#232321'],
    ['#f18fc9', '#212323'],
    ['#232321', '#8fc9f1'],
  ];

  return (
    <Modal
      title={`${t('SELECT')} ${t('GRADIENT')}`}
      isOpen={visible}
      setIsOpen={setVisible}
      width={900}
    >
      <div>
        <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
          {
            (gradients && gradients.length > 0) &&
            gradients.map((o, index) =>
              <div key={index}>
                <div
                  onClick={() => { selectgradient(o); }}
                  className="h-8 w-full cursor-pointer mb-5"
                  style={{
                    backgroundImage: `linear-gradient(to right, ${o[0]}, ${o[1]})`
                  }}
                ></div>
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  );
}
