import { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { GeojsonSchema } from '@/types/types';
import { Square3Stack3DIcon } from '@heroicons/react/24/outline';
import { checkAuthorization } from '../../../utils/util';
import { useGetGeojsonsQuery } from '../../../features/geojson';
import { useGetProjectQuery } from '../../../features/project';
import { RootState } from '../../../store';
import Breadcrumbs from '../../global/Breadcrumbs';
import Badge from '../../ui/data-display/Badge';
import Table from '../../ui/data-display/Table';
import BoardContent from '../common/BoardContent';
import BoardEmpty from '../common/BoardEmpty';
import BoardHeader from '../common/BoardHeader';

export default function Geojsons() {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const { data, isFetching } = useGetGeojsonsQuery({ projectId });
  const { data: project } = useGetProjectQuery({ projectId });

  const canUpsertGeojson = useMemo(() => checkAuthorization(user, project, 'geojsons_upsert'), [user, project]);
  const hasData = useMemo(() => (data && data.length > 0), [data]);
  const isEmpty = useMemo(() => (data && data.length <= 0), [data]);
  const geojsons = useMemo(() => [...map(data, o => ({...o, key: o.id}))], [data]);
  const isAnyLoading = useMemo<boolean>(() => (isFetching), [isFetching]);

  const columns = [
    {
      title: t('NAME'),
      key: 'name',
      render: (record: GeojsonSchema) => {
        return (record.name);
      }
    },
    {
      title: t('LOADED'),
      key: 'hasBeenLoaded',
      render: (record: GeojsonSchema) => {
        if (record.hasBeenLoaded) {
          return <Badge>{t('LOADED')}</Badge>;
        }
        return <Badge color="yellow">{t('UNPROCESSED')}</Badge>;
      },
    },
    {
      title: t('ACTIONS'),
      key: 'action',
      areActions: true,
      render: (record: GeojsonSchema) => (
        <>
          {
            (canUpsertGeojson) &&
            <Link className="text-blue-600 hover:text-blue-900" to={`/board/${projectId}/geojsons/procesar/${record.id}`}>
              {t('TO_PROCESS')}
            </Link>
          }
        </>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(project) ? project.name : ''}`, current: false },
          { name: 'GeoJSONs', current: true },
        ]}
      />
      {
        (hasData) &&
        <BoardHeader
          title={t('GEOJSONS')}
          description={t('BOARD_GEOJSONS_PAGE_DESCRIPTION')}
          link={(canUpsertGeojson) ? `/board/${projectId}/${t('SLUG_GEOJSONS')}/${t('SLUG_ADD')}` : ''}
          linkText={(canUpsertGeojson) ? `${t('ADD')} ${t('GEOJSON')}` : ''}
        />
      }
      <BoardContent>
        {
          (hasData) &&
          <Table
            columns={columns}
            dataSource={geojsons}
            isLoading={isAnyLoading}
          />
        }
        {
          (isEmpty) &&
          <BoardEmpty
            icon={<Square3Stack3DIcon className="mx-auto h-12 w-12 text-gray-400" />}
            title="Sin GeoJSONs"
            description="Comienza creando un nuevo geojson."
            buttonText="Nuevo GeoJSON"
            buttonAction={() => { navigate(`/board/${projectId}/${t('SLUG_GEOJSONS')}/${t('SLUG_ADD')}`); }}
            canCreate={canUpsertGeojson}
          />
        }
      </BoardContent>
    </>
  );
}
