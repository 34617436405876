import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { EditControl } from 'react-leaflet-draw';
import { FeatureSpecial, MapSchema, RegistriesObjectState } from '@/types/types';
import { useCreateRegistryByMapMutation, useUpdateRegistryGeometryByMapMutation } from '../../../features/registry';
import { findIndex } from 'lodash';
import { useDispatch } from 'react-redux';
import { actionsNotification } from '../../../features/notificationSlice';

export default function MapEditCapa({
  idx,
  selectedFeature,
  map,
  selectedCapa,
  featureDrawerVisible,
  registriesObject,
  setRegistriesObject,
}: {
  idx: number;
  selectedFeature: FeatureSpecial | undefined;
  map: MapSchema;
  selectedCapa: string;
  featureDrawerVisible: boolean;
  registriesObject: RegistriesObjectState;
  setRegistriesObject: Dispatch<SetStateAction<RegistriesObjectState>>;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const editableFG = useRef<any>();
  const [isCreatingPrev, setIsCreatingPrev] = useState<boolean>(false);
  const [isUpdatingPrev, setIsUpdatingPrev] = useState<boolean>(false);

  const [createRegistryByMap, { data: createData, isLoading: isCreating, isError: creatingIsError }] = useCreateRegistryByMapMutation();
  const [updateRegistryGeometryByMap, { data: updateData, isLoading: isUpdating, isError: updatingIsError }] = useUpdateRegistryGeometryByMapMutation();

  const _onCreated = (e: any) => {
    const layerType: 'marker' | 'polygon' | 'polyline' = e.layerType;
    let geometry = null;
    if (layerType === 'marker') {
      geometry = e.layer._latlng;
      geometry = [geometry.lng, geometry.lat];
    } else if (layerType === 'polygon') {
      geometry = e.layer._latlngs;
      geometry = [geometry[0].map((o: any) => [o.lng, o.lat])];
    } else if (layerType === 'polyline') {
      geometry = e.layer._latlngs;
      geometry = [...geometry.map((o: any) => [o.lng, o.lat])];
    }

    if (!geometry || selectedCapa === '') {
      dispatch(actionsNotification.createAlert({ message: t('SELECT_A_LAYER'), type: 'warning' }));
      return;
    }

    if (editableFG && editableFG.current && editableFG.current && editableFG.current._layers) {
      const drawnItems = editableFG.current._layers;
      if (Object.keys(drawnItems).length > 0) {
        Object.keys(drawnItems).forEach((layerid) => {
            const layer = drawnItems[layerid];
            editableFG.current.removeLayer(layer);
        });
      }
    }

    if (selectedFeature && selectedFeature.id && selectedFeature.geojson && !featureDrawerVisible) {
      if (map.isExample) {
        dispatch(actionsNotification.createAlert({ message: t('FEATURE_NOT_AVAILABLE_SAMPLE'), type: 'warning' }));
        return;
      }

      let layerTypeGeojson = '';
      if (layerType === 'marker') {
        layerTypeGeojson = 'Point';
      } else if (layerType === 'polyline') {
        layerTypeGeojson = 'LineString';
      } else if (layerType === 'polygon') {
        layerTypeGeojson = 'Polygon';
      }
      const geometryObj = {
        type: layerTypeGeojson,
        coordinates: geometry,
      };
      updateRegistryGeometryByMap({
        registryId: selectedFeature.id,
        geojsonId: selectedFeature.geojson,
        mapId: map.id,
        geometry: JSON.stringify(geometryObj),
      });
    } else {
      if (map.isExample) {
        dispatch(actionsNotification.createAlert({ message: t('FEATURE_NOT_AVAILABLE_SAMPLE'), type: 'warning' }));
        return;
      }

      createRegistryByMap({
        mapId: map.id,
        geojsonId: selectedCapa,
        geometry: JSON.stringify(geometry),
        type: layerType,
      });
    }
  }

  useEffect(() => {
    if (!isCreating && isCreatingPrev && !creatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('RECORD_ADDED_SUCCESSFULLY'), type: 'success' }));
      const temp: RegistriesObjectState = {...registriesObject};
      if (temp && temp[createData.geojson]) {
        temp[createData.geojson].push({...createData});
        setRegistriesObject({...temp});
      } else if (temp) {
        temp[createData.geojson] = [{...createData}];
        setRegistriesObject({...temp});
      }
    }
    setIsCreatingPrev(isCreating);
  }, [isCreating, isCreatingPrev, creatingIsError, createData, registriesObject, setRegistriesObject, dispatch, t]);

  useEffect(() => {
    if (!isUpdating && isUpdatingPrev && !updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('RECORD_UPDATED_SUCCESSFULLY'), type: 'success' }));
      const temp: RegistriesObjectState = {...registriesObject};
      if (temp && temp[updateData.geojson] && findIndex(temp[updateData.geojson], (o) => o.id === updateData.id) >= 0) {
        const tempIndex = findIndex(temp[updateData.geojson], (o) => o.id === updateData.id);
        temp[updateData.geojson][tempIndex] = {...updateData};
        setRegistriesObject({...temp});
      }
    }
    setIsUpdatingPrev(isUpdating);
  }, [isUpdating, isUpdatingPrev, updatingIsError, updateData, registriesObject, setRegistriesObject, dispatch, t]);

  return (
    <FeatureGroup ref={editableFG} key={idx}>
      <EditControl
        position="topright"
        onEdited={() => {}}
        onCreated={_onCreated}
        onDeleted={() => {}}
        draw={{
          rectangle: false,
          circlemarker: false,
          circle: false,
          polygon: true,
          polyline: true,
          marker: true,
        }}
        edit={{
          edit: false,
          remove: false,
        }}
      />
    </FeatureGroup>
  );
}
