import qs from 'qs';
import { appApi } from './api';
import { getToken } from '../store';

export const map = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getNotesByMap: builder.query({
      query: (params) => ({
        url: `/api/v1/map-notes/by-map/${params.mapId}?${qs.stringify(params.query)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [{ type: 'Map', id: args || '' }],
    }),
    createNote: builder.mutation({
      query: (params) => ({
        url: '/api/v1/map-notes',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Map', id: 'LIST' }],
    }),
  }),
});

export const {
  useLazyGetNotesByMapQuery,
  useCreateNoteMutation,
} = map;
