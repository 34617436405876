import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import { cloneDeep, filter } from 'lodash';
import { FieldValues, useForm } from 'react-hook-form';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLazyGetGeojsonQuery, useUpdateGeojsonMutation } from '../../../features/geojson';
import { useGetProjectQuery } from '../../../features/project';
import GradientSelectModal from '../../global-modals/GradientSelectModal';
import { Clasificacion, GeojsonProperty } from '@/types/types';
import UpsertPropertyModal from './UpsertPropertyModal';
import { useDispatch } from 'react-redux';
import { actionsNotification } from '../../../features/notificationSlice';
import Breadcrumbs from '../../global/Breadcrumbs';
import Button from '../../ui/general/Button';
import { PaintBrushIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import Select from '../../ui/data-entry/Select';
import InputHook from '../../ui/data-entry/InputHook';
import Input from '../../ui/data-entry/Input';
import Table from '../../ui/data-display/Table';
import Tabs from '../../ui/data-display/Tabs';
import RadioGroup from '../../ui/data-entry/RadioGroup';
import Collapse, { CollapsePanel } from '../../ui/data-display/Collapse';
import BoardFooterBar from '../common/BoardFooterBar';
import BoardContent from '../common/BoardContent';
import BoardCard from '../common/BoardCard';
import CaretUpIcon from '../../../components/icons/CaretUpIcon';
import CaretDownIcon from '../../../components/icons/CaretDownIcon';
import BoardHeader from '../common/BoardHeader';

export default function GeojsonProcess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { projectId, geojsonId } = useParams<{ projectId: string; geojsonId: string }>();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [isUpdatingPrev, setIsUpdatingPrev] = useState<boolean>(false);
  const [isFetchingPrev, setIsFetchingPrev] = useState<boolean>(false);
  const [classifications, setClassifications] = useState<Clasificacion[]>([]);
  const [visibleGradientSelectModal, setVisibleGradientSelectModal] = useState<boolean>(false);
  const [clasificacionIndex, setClasificacionIndex] = useState<number>(0);
  const [defaultColor, setDefaultColor] = useState<string>('#0062b1');
  const [selectDefaultColor, setSelectDefaultColor] = useState<boolean>(false);
  const [properties, setProperties] = useState<GeojsonProperty[]>([]);
  const [upsertPropertyModalVisible, setUpsertPropertyModalVisible] = useState<boolean>(false);
  const [propertyModalAction, setPropertyModalAction] = useState<'ADD' | 'EDIT'>('ADD');
  const [propertyObject, setPropertyObject] = useState<GeojsonProperty>();

  const { data: project } = useGetProjectQuery({ projectId });
  const [getGeojson, { data, isFetching, isLoading, isError: fetchingIsError }] = useLazyGetGeojsonQuery();
  const [updateGeojson, { isLoading: isUpdating, isError: updatingIsError }] = useUpdateGeojsonMutation();

  const isAnyLoading = useMemo<boolean>(() => (isUpdating || isFetching || isLoading), [isUpdating, isFetching, isLoading]);

  useEffect(() => {
    getGeojson({ geojsonId, projectId });
  }, [getGeojson, geojsonId, projectId]);

  useEffect(() => {
    if (!isUpdating && isUpdatingPrev && !updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: `GeoJSON ${t('UPDATED_SUCCESSFULLY')}`, type: 'success' }));
      navigate(`/board/${projectId}/geojsons`); 
      return;
    }
    setIsUpdatingPrev(isUpdating);
  }, [isUpdating, projectId, isUpdatingPrev, updatingIsError, navigate, dispatch, t]);

  useEffect(() => {
    if (!isFetching && isFetchingPrev && !fetchingIsError && data) {
      if (data.defaultColor) {
        setDefaultColor(data.defaultColor);
      }
      if (data.properties && data.properties.length > 0) {
        setProperties([...data.properties]);
      }
      if (data.classifications && data.classifications.length > 0) {
        setClassifications([...cloneDeep(data.classifications)]);
      }
      if (data.name) {
        setValue('name', data.name);
      }
    }
    setIsFetchingPrev(isFetching);
  }, [isFetching, data, isFetchingPrev, fetchingIsError, setValue]);

  const validateForm = () => {
    const uniqueValues = new Set(classifications.map((v) => v.label));

    if (uniqueValues.size < classifications.length) {
      return false;
    }
    return true;
  }

  const onFinish = (fieldValues: FieldValues) => {
    if (!validateForm()) {
      dispatch(actionsNotification.createAlert({ message: t('CLASSIFICATION_TAGS_MUST_BE_UNIQUE'), type: 'warning' }));
      return;
    }

    updateGeojson({
      ...fieldValues,
      id: geojsonId,
      classifications: JSON.stringify(classifications),
      defaultColor,
      projectId,
      properties,
    });
  };

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      render: (value: any, record: any, index: number) => {
        return (
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => {
                if ((index === 0)) {
                  return;
                }
                const temp: GeojsonProperty[] = [...properties];
                [temp[index], temp[index - 1]] = [temp[index - 1], temp[index]];
                setProperties([...temp]);
              }}
            >
              <CaretUpIcon className={cx('w-6', (index === 0) ? 'text-[#8c8c8c] cursor-default' : 'text-[#555555]')} />
            </button>
            <button
              type="button"
              onClick={() => {
                if (index === properties.length - 1) {
                  return;
                }
                const temp: GeojsonProperty[] = [...properties];
                [temp[index], temp[index + 1]] = [temp[index + 1], temp[index]];
                setProperties([...temp]);
              }}
            >
              <CaretDownIcon className={cx('w-6', (index === properties.length - 1) ? 'text-[#8c8c8c] cursor-default' : 'text-[#555555]')} />
            </button>
          </div>
        );
      },
    },
    {
      title: t('PROPERTY'),
      key: 'property',
      render: (record: GeojsonProperty) => {
        return (record.property);
      }
    },
    {
      title: t('ACTIONS'),
      key: 'action',
      render: (record: GeojsonProperty) => (
        <div className="flex gap-4 items-center">
          <Button
            size="small"
            onClick={() => {
              setPropertyModalAction('EDIT');
              setPropertyObject(record);
              setUpsertPropertyModalVisible(true);
            }}
          >
            <PencilSquareIcon className="w-5 h-5 mr-1" /> {t('EDIT')}
          </Button>
          <Button
            size="small"
            onClick={() => {
              const temp = filter(properties, (o: GeojsonProperty) => record.property !== o.property);
              setProperties([...temp]);
            }}
          >
            <TrashIcon className="w-5 h-5 mr-1" /> {t('DELETE')}
          </Button>
        </div>
      ),
    },
  ];

  const tabItems = [
    {
      label: t('INFORMATION'),
      key: 'informacion',
      children: (
        <div className="board-padding">
          <div className="mb-5">
            <Button
              size="small"
              onClick={() => {
                setPropertyModalAction('ADD');
                setUpsertPropertyModalVisible(true);
                setPropertyObject(undefined);
              }}
              disabled={isAnyLoading}
            >
              <PlusIcon className="w-5 h-5 mr-1" /> {t('ADD')} {t('PROPERTY')}
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={[...properties]}
            isLoading={isAnyLoading}
          />
        </div>
      ),
    },
    {
      label: 'General',
      key: 'general',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3">
            <div>
              <InputHook
                register={{
                  ...register('name', {
                    required: true,
                    maxLength: 256,
                    minLength: 2,
                  }),
                }}
                label={t('NAME')}
                disabled={isAnyLoading}
                errors={errors}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-base text-gray-700">
                Color
              </label>
              <div className="flex gap-2">
                <span
                  className="inline-flex items-center py-0.5 font-medium px-2 rounded text-xs text-white cursor-pointer w-fit"
                  style={{ backgroundColor: defaultColor }}
                  onClick={() => {
                    setSelectDefaultColor(true);
                  }}
                >
                  {defaultColor}
                </span>
                <Button
                  size="small"
                  onClick={() => { 
                    setSelectDefaultColor(true);
                  }}
                  className="w-fit"
                >
                  <PaintBrushIcon className="w-5 h-5" />
                </Button>
              </div>
              <div className={(selectDefaultColor) ? 'block' : 'hidden'}>
                <SketchPicker
                  color={defaultColor}
                  onChangeComplete={(e) => {
                    setDefaultColor(e.hex);
                    setSelectDefaultColor(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: t('CLASSIFICATION'),
      key: 'clasificacion',
      children: (
        <div className="board-padding">
          {
            !(classifications && classifications.length > 0) &&
            <div>{t('THERE_ARE_NO_CLASSIFICATIONS')}</div>
          }
          {
            (classifications && classifications.length > 0) &&
            (
              <Collapse>
                {classifications.map((o, index) => (
                  <CollapsePanel
                    key={o.idx}
                    header={`${t('CLASSIFICATION')} ${index + 1}`}
                    extra={
                      <button
                        type="button"
                        className="p-0 h-4 bg-transparent text-red-600 flex items-center mr-2"
                        onClick={() => {
                          const tempClassifications = [...classifications];
                          tempClassifications.splice(index, 1);
                          setClassifications([...tempClassifications]);
                        }}
                      >
                        <TrashIcon className="w-5 h-5" />
                      </button>
                    }
                  >
                    <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
                      <div className="flex flex-col gap-1">
                        <label className="text-base text-gray-700">
                          {t('TYPE')}
                        </label>
                        <RadioGroup
                          options={[
                            { label: 'Exacto', value: 'exacto' },
                            { label: t('CONDITION'), value: 'condicion' },
                          ]}
                          value={classifications[index].type}
                          onChange={(e) => {
                            const tempClassifications: Clasificacion[] = [...classifications];
                            tempClassifications[index].type = e;
                            setClassifications([...tempClassifications]);
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <label className="text-base text-gray-700">
                          {t('FIELD')}
                        </label>
                        <Select
                          selected={{
                            id: classifications[index].field,
                            name: classifications[index].field,
                          }}
                          setSelected={(e: any) => {
                            const tempClassifications = [...classifications];
                            tempClassifications[index].field = e.id;
                            setClassifications([...tempClassifications]);
                          }}
                          options={(properties && properties.length > 0) ? [...properties.map((o) => ({ id: o.property, name: o.property }))] : []}
                        />
                      </div>
                      <div>
                        <Input
                          id={`label_${index}`}
                          label={t('LABEL')}
                          value={classifications[index].label as string}
                          placeholder={t('LABEL') as string}
                          onChange={(e: any) => {
                            const tempClassifications = [...classifications];
                            tempClassifications[index].label = e.target.value;
                            setClassifications([...tempClassifications]);
                          } }
                        />
                      </div>
                      {
                        (classifications[index].gradient && classifications[index].gradient[0] && classifications[index].type === 'exacto') &&
                        <div className="flex flex-col gap-1">
                          <label className="text-base text-gray-700">
                            {t('GRADIENT')}
                          </label>
                          <div
                            onClick={() => { setVisibleGradientSelectModal(true); setClasificacionIndex(index); }}
                            className="h-8 w-[50%] cursor-pointer"
                            style={{
                              backgroundImage: `linear-gradient(to right, ${classifications[index].gradient[0]}, ${classifications[index].gradient[1]})`
                            }}
                          />
                        </div>
                      }
                    </div>
                    {
                      (classifications[index].type === 'condicion' && (classifications[index].conditions && classifications[index].conditions.length <= 0)) &&
                      <div>{t('THERE_ARE_NO_CONDITIONS')}</div>
                    }
                    {
                      (classifications[index].type === 'condicion' && classifications[index].conditions && classifications[index].conditions.length > 0) &&
                      <Collapse>
                        {
                          (classifications[index].conditions) &&
                          classifications[index].conditions.map((condicion, index2) => 
                            <CollapsePanel
                              key={condicion.idx}
                              header={`${t('CONDITION')} ${index2 + 1}`}
                              extra={
                                <Button
                                  color="red"
                                  size="small"
                                  className="p-0 h-4"
                                  onClick={() => {
                                    const tempClassifications = [...classifications];
                                    tempClassifications[index].conditions.splice(index2, 1);
                                    setClassifications([...tempClassifications]);
                                  }}
                                >
                                  <TrashIcon className="w-5 h-5" />
                                </Button>
                              }
                            >
                              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3">
                                <div>
                                  <Input
                                    id={`label_${index}`}
                                    label={t('LABEL')}
                                    type="text"
                                    placeholder={t('LABEL') as string}
                                    disabled={isAnyLoading}
                                    value={classifications[index].conditions[index2].label}
                                    onChange={(e: any) => {
                                      const tempClassifications = [...classifications];
                                      tempClassifications[index].conditions[index2].label = e.target.value;
                                      setClassifications([...tempClassifications]);
                                    }}
                                  />
                                </div>
                                <div className="flex flex-col gap-1">
                                  <label className="text-base text-gray-700">
                                    {t('TYPE')}
                                  </label>
                                  <RadioGroup
                                    options={[
                                      { label: '<', value: '<' },
                                      { label: '<=', value: '<=' },
                                      { label: '==', value: '==' },
                                      { label: '>=', value: '>=' },
                                      { label: '>', value: '>' },
                                    ]}
                                    onChange={(e) => {
                                      const tempClassifications = [...classifications];
                                      tempClassifications[index].conditions[index2].type = e;
                                      setClassifications([...tempClassifications]);
                                    }}
                                    value={classifications[index].conditions[index2].type}
                                  />
                                </div>
                                <div>
                                  <Input
                                    id={`value_${index}_${index2}`}
                                    label={t('VALUE')}
                                    type="text"
                                    placeholder={t('VALUE') as string}
                                    disabled={isAnyLoading}
                                    value={classifications[index].conditions[index2].value}
                                    onChange={(e: any) => {
                                      const tempClassifications = [...classifications];
                                      tempClassifications[index].conditions[index2].value = e.target.value;
                                      setClassifications([...tempClassifications]);
                                    }}
                                  />
                                </div>
                                <div className="flex flex-col mt-1">
                                  <label className="text-base text-gray-700">
                                    Color
                                  </label>
                                  <div>
                                    <span
                                      className="inline-flex items-center py-0.5 font-medium px-2 rounded text-xs text-white cursor-pointer"
                                      style={{ backgroundColor: classifications[index].conditions[index2].color }}
                                      onClick={() => {
                                        const tempClassifications = [...classifications];
                                        tempClassifications[index].conditions[index2].selectColor = true;
                                        setClassifications([...tempClassifications]);
                                      }}
                                    >
                                      {classifications[index].conditions[index2].color}
                                    </span>
                                    <Button
                                      size="small"
                                      onClick={() => { 
                                        const tempClassifications = [...classifications];
                                        tempClassifications[index].conditions[index2].selectColor = true;
                                        setClassifications([...tempClassifications]);
                                      }}
                                    >
                                      <PaintBrushIcon className="w-5 h-5" />
                                    </Button>
                                    <div className={(classifications[index].conditions[index2].selectColor) ? 'block' : 'hidden'}>
                                      <SketchPicker
                                        color={classifications[index].conditions[index2].color}
                                        onChangeComplete={(e) => {
                                          const tempClassifications = [...classifications];
                                          tempClassifications[index].conditions[index2].color = e.hex;
                                          tempClassifications[index].conditions[index2].selectColor = false;
                                          setClassifications([...tempClassifications]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CollapsePanel>
                          )
                        }
                      </Collapse>
                    }
                    {
                      (classifications[index].type === 'condicion') &&
                      <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
                        <div>
                          <Button
                            className="mt-5"
                            size="small"
                            onClick={() => {
                              const tempClassifications = [...classifications];
                              tempClassifications[index].conditions.push({
                                idx: Math.random(),
                                label: '',
                                color: '#0062B1',
                                type: '==',
                                value: '',
                              });
                              setClassifications([...tempClassifications]);
                            }}
                          >
                            {t('ADD')} {t('CONDITION')}
                          </Button>
                        </div>
                      </div>
                    }
                  </CollapsePanel>
                ))}
              </Collapse>
            )
          }
          <Button
            className="mt-5"
            size="small"
            onClick={() => {
              setClassifications([...classifications, {
                type: 'exacto',
                idx: Math.random(),
                conditions: [],
                gradient: ['#8ff1c9', '#232123'],
              }]);
            }}
          >
            {t('ADD')} {t('CLASSIFICATION')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(project) ? project.name : ''}`, current: false },
          { name: 'GeoJSONs', to: `/board/${projectId}/geojsons`, current: false },
          { name: t('TO_PROCESS'), current: true },
        ]}
      />
      <BoardHeader
        title={`${t('TO_PROCESS')} ${t('GEOJSON')}`}
        description={t('BOARD_GEOJSON_PROCESS_PAGE_DESCRIPTION')}
      />
      <form onSubmit={handleSubmit((data) => onFinish(data))}>
        <BoardContent>
          <BoardCard>
            <Tabs defaultActiveKey="informacion" items={tabItems} />
          </BoardCard>
        </BoardContent>
        <BoardFooterBar
          disabled={isAnyLoading}
          isLoading={isAnyLoading}
          onClick={() => {}}
        >
          {geojsonId ? t('UPDATE') : t('CREATE')} GeoJSON
        </BoardFooterBar>
      </form>
      <GradientSelectModal
        visible={visibleGradientSelectModal}
        setVisible={setVisibleGradientSelectModal}
        selectgradient={(e) => {
          const tempClassifications = [...classifications];
          tempClassifications[clasificacionIndex].gradient = e;
          setClassifications([...tempClassifications]);
          setVisibleGradientSelectModal(false);
        }}
      />
      <UpsertPropertyModal
        visible={upsertPropertyModalVisible}
        setVisible={setUpsertPropertyModalVisible}
        properties={properties}
        setProperties={setProperties}
        action={propertyModalAction}
        propertyObject={propertyObject}
      />
    </>
  );
}
