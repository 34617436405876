import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MapColumnPlot from '../charts/MapColumnPlot';
import { FeatureSpecial, GeojsonSchema, SelectedClassificationsType, SelectedFiltrosType } from '@/types/types';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import Button from '../../../ui/general/Button';
import Toggle from '../../../ui/data-entry/Toggle';
import Select from '../../../ui/data-entry/Select';

export default function MapClassifications({
  selectedGeojson,
  selectedClassifications,
  setSelectedClassifications,
  selectedFiltros,
  setSelectedFiltros,
  opacidad,
  setOpacidad,
  setSelectedFeature,
}: {
  selectedGeojson: GeojsonSchema | undefined,
  selectedClassifications: SelectedClassificationsType,
  setSelectedClassifications: Dispatch<SetStateAction<SelectedClassificationsType>>,
  selectedFiltros: SelectedFiltrosType,
  setSelectedFiltros: Dispatch<SetStateAction<SelectedFiltrosType>>,
  opacidad: boolean,
  setOpacidad: Dispatch<SetStateAction<boolean>>,
  setSelectedFeature: Dispatch<SetStateAction<FeatureSpecial | undefined>>,
}) {
  const { t } = useTranslation();

  const [chartsModalVisible, setChartsModalVisible] = useState<boolean>(false);

  const toggleFiltro = (value: string) => {
    if (selectedGeojson && selectedGeojson.id) {
      const tempSelectedFiltros = {...selectedFiltros};
      const index = tempSelectedFiltros[selectedGeojson.id].indexOf(value);
      if (index === -1) {
        tempSelectedFiltros[selectedGeojson.id].push(value);
      } else {
        tempSelectedFiltros[selectedGeojson.id].splice(index, 1);
      }
      setSelectedFiltros({...tempSelectedFiltros});
    }
    setSelectedFeature(undefined);
  }

  return (
    <Fragment>
      <div className="mb-6">
        <div className="flex items-center">
          {t('OPAQUE')}: <Toggle className="ml-2" enabled={opacidad} setEnabled={() => { setOpacidad(prev => !prev); }} />
        </div>
        {
          (selectedClassifications && selectedGeojson && selectedClassifications[selectedGeojson.id]) &&
          <div className="mt-4">
            <Button
              size="small"
              onClick={() => { setChartsModalVisible(true); }}
            >
              <ChartBarIcon className="w-5 h-5" />
            </Button>
          </div>
        }
      </div>
      {
        (selectedGeojson && selectedGeojson.classifications && selectedGeojson.classifications.length > 0) &&
        <Select
          selected={{
            id: selectedClassifications[selectedGeojson.id],
            name: selectedClassifications[selectedGeojson.id] ? selectedClassifications[selectedGeojson.id] : 'Default',
          }}
          setSelected={(e: any) => {
            const tempSelectedClassifications: SelectedClassificationsType = {...selectedClassifications};
            tempSelectedClassifications[selectedGeojson.id] = e.id;
            setSelectedClassifications({ ...tempSelectedClassifications });
            if (e && e.id && e.id !== 'EKIS' && selectedGeojson) {
              const tempSelectedFiltrosItems = selectedGeojson.classificationsKeys[tempSelectedClassifications[selectedGeojson.id]].map(o => o.color);
              const tempSelectedFiltros = {...selectedFiltros};
              tempSelectedFiltros[selectedGeojson.id] = [...tempSelectedFiltrosItems];
              setSelectedFiltros({...tempSelectedFiltros});
            }
          }}
          options={[{ id: '', name: 'Default' }, ...selectedGeojson.classifications.map((o) => ({ id: o.label, name: o.label }))]}
        />
      }
      {
        (selectedGeojson && selectedClassifications[selectedGeojson.id] && selectedGeojson.classificationsKeys && selectedGeojson.classificationsKeys[selectedClassifications[selectedGeojson.id]]) &&
        selectedGeojson.classificationsKeys[selectedClassifications[selectedGeojson.id]].map((o, oIndex) => 
          {
            let bgColor = 'transparent';
            if (selectedFiltros && selectedFiltros[selectedGeojson.id] && selectedFiltros[selectedGeojson.id].length > 0 && selectedFiltros[selectedGeojson.id].includes(o.color)) {
              bgColor = o.color;
            }
            return (
              <div key={oIndex} className="flex items-center pt-3 cursor-pointer" onClick={() => { toggleFiltro(o.color); }}>
                <span className="block w-4 h-4 rounded-sm mr-1 border-2" style={{ backgroundColor: bgColor, borderColor: o.color }}></span>
                {o.value}
              </div>
            )
          }
        )
      }
      <MapColumnPlot
        visible={chartsModalVisible}
        setVisible={setChartsModalVisible}
        selectedGeojson={selectedGeojson}
        selectedClassifications={selectedClassifications}
      />
    </Fragment>
  );
}
