import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './features/authSlice';
import { appApi } from './features/api';
import { notificationSlice } from './features/notificationSlice';
import translateReducer from './features/translateSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedTranslateReducer = persistReducer(persistConfig, translateReducer.reducer);

export const getToken = () => {
  return store.getState().auth.token;
};

export const store = configureStore({
  reducer: {
    [appApi.reducerPath]: appApi.reducer,
    auth: persistedAuthReducer,
    notifications: notificationSlice.reducer,
    translate: persistedTranslateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(appApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
