export const leafletDrawLanguage = {
  draw: {
    toolbar: {
      actions: {
        title: 'Cancelar dibujo',
        text: 'Cancelar',
      },
      finish: {
        title: 'Finalizar dibujo',
        text: 'Finalizar',
      },
      undo: {
        title: 'Eliminar último punto dibujado',
        text: 'Eliminar último punto',
      },
      buttons: {
        polyline: 'Dibuja una polilínea',
        polygon: 'Dibuja un polígono',
        rectangle: 'Dibuja un rectángulo',
        circle: 'Dibuja una círculo',
        marker: 'Dibuja un marcador',
        circlemarker: 'Dibuja un marcador circular',
      },
    },
    handlers: {
      circle: {
        tooltip: {
          start: 'Haz clic y arrastra para dibujar un círculo.',
        },
        radius: 'Radio',
      },
      circlemarker: {
        tooltip: {
          start: 'Haz clic en el mapa para colocar un marcador circular.',
        },
      },
      marker: {
        tooltip: {
          start: 'Haz clic en el mapa para colocar un marcador.',
        },
      },
      polygon: {
        tooltip: {
          start: 'Haz clic para empezar a dibujar.',
          cont: 'Haz clic para continuar dibujando.',
          end: 'Haz clic en el último punto para cerrar la figura.',
        },
      },
      polyline: {
        error: '<strong>Error:</strong> los bordes de la línea no se pueden cruzar!',
        tooltip: {
          start: 'Haz clic para empezar a dibujar.',
          cont: 'Haz clic para continuar dibujando.',
          end: 'Haz clic en el último punto para finalizar la línea.',
        },
      },
      rectangle: {
        tooltip: {
          start: 'Haz clic y arrastra para dibujar un rectángulo.',
        },
      },
      simpleshape: {
        tooltip: {
          end: 'Suelta el ratón para finalizar el dibujo.',
        },
      },
    },
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: 'Guardar cambios',
          text: 'Guardar',
        },
        cancel: {
          title: 'Cancelar edición, descartar todos los cambios',
          text: 'Cancelar',
        },
        clearAll: {
          title: 'Limpiar todas las capas',
          text: 'Limpiar todo',
        },
      },
      buttons: {
        edit: 'Editar capas',
        editDisabled: 'No hay capas para editar',
        remove: 'Eliminar capas',
        removeDisabled: 'No hay capas para eliminar',
      },
    },
    handlers: {
      edit: {
        tooltip: {
          text: 'Arrastra las figuras para editar.',
          subtext: 'Haz clic en cancelar para deshacer los cambios.',
        },
      },
      remove: {
        tooltip: {
          text: 'Haz clic en una figura para removerla.',
        },
      },
    },
  },
};
