import { ReactNode, useState } from 'react';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';

type Tab = {
  label: string;
  key: string;
  children: ReactNode;
}

export default function Tabs({
  defaultActiveKey,
  items,
  tabChanged = () => {},
}: {
  defaultActiveKey: string;
  items: Tab[];
  tabChanged?: () => void;
}) {
  const { t } = useTranslation();

  const [active, setActive] = useState<string>(defaultActiveKey);

  return (
    <div className="">
      <div className="sm:hidden pt-6">
        <label htmlFor="tabs" className="sr-only">
          {t('SELECT_A_TAB')}
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
          defaultValue={items.find((tab) => tab.key === active)?.label}
          onChange={(event) => {
            const selectedKey = items.find(tab => tab.label === event.target.value)?.key;
            if (selectedKey) {
              setActive(selectedKey);
              tabChanged();
            }
          }}
        >
          {
            items.map((tab) => (
              <option key={tab.key} value={tab.label}>
                {tab.label}
              </option>
            ))
          }
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex space-x-2 lg:space-x-8" aria-label="Tabs">
            {items.map((tab) => (
              <div
                key={tab.key}
                className={cx(
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
                  tab.key === active ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                )}
                aria-current={tab.key === active ? 'page' : undefined}
                onClick={() => { setActive(tab.key); tabChanged(); }}
              >
                {tab.label}
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-3">
        {
          items.map((o) => (
            <div className={cx(o.key === active ? 'w-full h-full visible' : 'w-0 h-0 invisible')}>
              {o.children}
            </div>
          ))
        }
      </div>
    </div>
  )
}
