import { useTranslation } from 'react-i18next';
import { MapSchema, ProjectSchema } from '@/types/types';
import Drawer from '../../ui/feedback/Drawer';
import Divider from '../../ui/layout/Divider';

function DescriptionItem({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  return (
    <div className="mb-4 text-gray-600 text-sm">
      <div className="inline-block mr-2 mb-0 text-gray-800">{title}:</div>
      {content}
    </div>
  )
};

export default function MapInfo({
  visible,
  onClose,
  map,
}: {
  visible: boolean;
  onClose: () => void;
  map: MapSchema;
}) {
  const { t } = useTranslation();

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      isOpen={visible}
      onClose={onClose}
    >
      <div className="text-lg mb-8 font-medium text-gray-700">{t('GENERAL_INFORMATION')}</div>
      <div className="grid grid-cols-1 2xl:grid-cols-2 gap-3">
        <DescriptionItem title={t('NAME')} content={map.name} />
        <DescriptionItem title={t('PROJECT')} content={(map && map.project) ? (map.project as ProjectSchema).name : t('NOT_AVAILABLE')} />
        <DescriptionItem title={t('DESCRIPTION')} content={map.description} />
      </div>
      <Divider />
      <div className="text-lg mb-8 font-medium text-gray-700">{t('SETTINGS')}</div>
      <div className="grid grid-cols-1 2xl:grid-cols-2 gap-3">
        <DescriptionItem title={t('DEFAULT_ZOOM')} content={`${map.defaultZoom}`} />
        <DescriptionItem title={t('MAXIMUM_ZOOM')} content={`${map.maxZoom}`} />
        <DescriptionItem title={t('MINIMUM_ZOOM')} content={`${map.minZoom}`} />
      </div>
      <Divider />
      <div className="text-lg mb-8 font-medium text-gray-700">{t('LOCATION')}</div>
      <div className="grid grid-cols-1 2xl:grid-cols-2 gap-3">
        <DescriptionItem title={t('LATITUDE')} content={`${map.latitude}`} />
        <DescriptionItem title={t('LONGITUDE')} content={`${map.longitude}`} />
      </div>
    </Drawer>
  );
}
