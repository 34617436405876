import { ReactNode } from 'react';
import cx from 'clsx';

export default function Badge({
  color = 'green',
  size = 'regular',
  children,
}:{
  color?: 'gray' | 'red' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink';
  size?: 'regular' | 'large';
  children: ReactNode;
}) {
  return (
    <span
      className={cx(
        'inline-flex items-center py-0.5 font-medium',
        color === 'gray' ? 'bg-gray-100 text-gray-800' : '',
        color === 'red' ? 'bg-red-100 text-red-800' : '',
        color === 'yellow' ? 'bg-yellow-100 text-yellow-800' : '',
        color === 'green' ? 'bg-green-100 text-green-800' : '',
        color === 'blue' ? 'bg-blue-100 text-blue-800' : '',
        color === 'indigo' ? 'bg-indigo-100 text-indigo-800' : '',
        color === 'purple' ? 'bg-purple-100 text-purple-800' : '',
        color === 'pink' ? 'bg-pink-100 text-pink-800' : '',
        size === 'regular' ? 'px-2 rounded text-xs' : '',
        size === 'large' ? 'px-2.5 rounded-md text-sm' : '',
      )}
    >
      {children}
    </span>
  );
}
