import { useState, useEffect, Fragment, useMemo } from 'react';
import { unionBy, isEqual } from 'lodash';
import cx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useLazyGetMapActivitiesByMapQuery } from '../../../features/mapActivity';
import { MapActivitySchema, MapSchema, UserSchema } from '@/types/types';
import Button from '../../ui/general/Button';
import Drawer from '../../ui/feedback/Drawer';

export default function MapActivity({
  visible,
  onClose,
  map,
}: {
  visible: boolean;
  onClose: () => void;
  map: MapSchema;
}) {
  const { t } = useTranslation();

  const [isFetchingPrev, setIsFetchingPrev] = useState<boolean>(false);
  const [mapActivityOpened, setMapActivityOpened] = useState<boolean>(false);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
  const [list, setList] = useState<MapActivitySchema[]>([]);
  const [listPrev, setListPrev] = useState<MapActivitySchema[] | null>(null);
  const [dataPrev, setDataPrev] = useState<MapActivitySchema[] | null>(null);

  const [getMapActivitiesByMap, { data, isFetching, isLoading, isError }] = useLazyGetMapActivitiesByMapQuery();

  const isAnyLoading = useMemo<boolean>(() => (isFetching || isLoading), [isFetching, isLoading]);

  useEffect(() => {
    if (!isFetching && isFetchingPrev && !isError) {
      if (data && data.length < 10) {
        setShowLoadMore(false);
      }
    }
    setIsFetchingPrev(isFetching);
  }, [isFetching, isFetchingPrev, isError, data]);

  useEffect(() => {
    if (mapActivityOpened && map && map.id) {
      getMapActivitiesByMap({ mapId: map.id });
    }
  }, [mapActivityOpened, getMapActivitiesByMap, map]);

  useEffect(() => {
    const checkEquals = listPrev !== null && isEqual(list, listPrev);
    const checkEquals2 = dataPrev !== null && isEqual(data, dataPrev);
    if (data && data.length > 0 && (!checkEquals || !checkEquals2)) {
      let tempList = unionBy(list, data, 'id');
      tempList.sort(function(a, b) {
        return (a.createdAt > b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);
      });
      setListPrev([...list]);
      setList([...tempList]);
      setDataPrev([...data]);
    }
  }, [data, dataPrev, list, listPrev]);

  useEffect(() => {
    if (visible === true) {
      setMapActivityOpened(true);
    }
  }, [visible]);

  return (
    <Drawer
      isOpen={visible}
      onClose={onClose}
      width={640}
      closable={false}
    >
      <div className="">
        <div className="text-lg font-medium text-gray-700 mb-3">
          {t('RECENT_ACTIVITY')}
        </div>
        <div className="">
          {
            (list && list.length > 0) &&
            <ul className="-mb-8">
              {
                list.map((o, index) => {
                  let dot = <Fragment />;
                  let color = 'bg-blue-500';

                  if (o.type === 'UPDATE') {
                    dot = <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />;
                    color = 'bg-blue-500';
                  } else if (o.type === 'CREATE') {
                    dot = <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />;
                    color = 'bg-green-500';
                  }

                  return (
                    <li key={index}>
                      <div className="relative pb-8">
                        {index !== list.length - 1 ? (
                          <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                        ) : null}
                        <div className="relative flex space-x-3">
                          <div>
                            <span
                              className={cx(
                                color,
                                'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                              )}
                            >
                              {dot}
                            </span>
                          </div>
                          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                            <div>
                              <p className="text-sm text-gray-500">
                                {o.description} by <span className="font-semibold">{(o.user as UserSchema).displayName}</span>
                              </p>
                            </div>
                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                              {moment(o.createdAt).startOf('minute').fromNow()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          }
          {
            (list && list.length <= 0) &&
            <div>{t('NO_RECENT_ACTIVITY')}</div>
          }
        </div>
        <div className="mt-8">
          {
            (isAnyLoading) &&
            <ArrowPathIcon className="w-5 h-5 animate-spin" />
          }
          {
            (!isAnyLoading && (list && list.length > 0) && showLoadMore) &&
            <Button
              size="small"
              onClick={() => { getMapActivitiesByMap({ mapId: map.id, query: { skip: list.length } }); }}
            >
              {t('LOAD_MORE')}
            </Button>
          }
        </div>
      </div>
    </Drawer>
  );
}
