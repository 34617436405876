import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { forEach, orderBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddProjectModal from './AddProjectModal';
import { useGetProjectsQuery } from '../../features/project';
import { logOut } from '../../features/authSlice';
import { RootState } from '../../store';
import { ProjectSchema } from '@/types/types';
import MainHeader from '../global/MainHeader';

export default function SelectProject() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useSelector((state: RootState) => state.auth);

  const [items, setItems] = useState<ProjectSchema[]>([]);
  const [showAddProjectModal, setShowAddProjectModal] = useState<boolean>(false);

  const { data: projects, isError, error } = useGetProjectsQuery({ userId: user.id });

  useEffect(() => {
    let tempItems: ProjectSchema[] = [];
    if (projects && projects.length > 0) {
      forEach(projects, o => {
        tempItems.push({ ...o, kind: 'project' });
      });
    }
    tempItems = orderBy(tempItems, ['createdAt'], ['desc']);
    setItems(tempItems);
  }, [projects]);

  useEffect(() => {
    if (isError && error && 'status' in error && error.status === 401) {
      dispatch(logOut());
      navigate('/');
    }
  }, [isError, error, dispatch, navigate]);

  return (
    <>
      <div className="!min-h-screen bg-slate-100">
        <MainHeader />
        <div className="my-0 mx-4">
          <div className="max-w-[256px] sm:max-w-[527px] lg:max-w-[798px] mt-10 mb-16 mx-auto">
            <h2 className="text-lg mb-9">
              {t('PROJECTS')}
            </h2>
            <div className="flex flex-wrap gap-3">
              {
                (items && items.length > 0) &&
                items.map(o => {
                  return (
                    <Link
                      key={o.id}
                      to={`/board/${o.id}/mapas`}
                      className="w-64 h-14 hover:ring-gray-400 hover:shadow-md group rounded-md p-3 bg-white ring-1 ring-slate-200 shadow-sm text-gray-800 hover:text-gray-900"
                    >
                      {o.name}
                    </Link>
                  );
                })
              }
              {
                (user.isOwner) &&
                <div
                  className="w-64 h-14 hover:border-blue-500 hover:border-solid hover:bg-white hover:text-blue-500 group flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 font-medium py-3 cursor-pointer"
                  onClick={() => { setShowAddProjectModal(true); }}
                >
                  {t('ADD')} {t('PROJECT')}
                </div>
              }
            </div>
          </div>
          <AddProjectModal
            visible={showAddProjectModal}
            handleOk={() => {
              setShowAddProjectModal(false);
            }}
            handleCancel={() => {
              setShowAddProjectModal(false);
            }}
          />
        </div>
      </div>
    </>
  );
}
