import { Fragment, ReactNode } from 'react';
import cx from 'clsx';
import IconLoading from '../../../components/icons/IconLoading';

export default function Button({
  type = 'button',
  className = '',
  onClick,
  isLoading = false,
  children,
  size = 'medium',
  color = 'blue',
  disabled = false,
  preventDefault = true,
}: {
  type?: 'button' | 'submit';
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  children: ReactNode;
  size?: 'xs' | 'small' | 'medium';
  color?: 'blue' | 'red';
  disabled?: boolean;
  preventDefault?: boolean;
}) {
  return (
    <button
      type={type}
      className={cx(
        'text-white',
        size === 'medium' ? 'text-base font-semibold px-4 rounded-md h-11' : '',
        size === 'small' ? 'text-sm font-semibold px-3 rounded h-8' : '',
        size === 'xs' ? 'text-xs font-semibold px-1 rounded h-5' : '',
        color === 'blue' ? 'bg-blue-600 hover:bg-blue-500 focus:outline-blue-700' : '',
        color === 'blue' && isLoading ? '!bg-blue-700' : '',
        color === 'blue' && disabled ? '!bg-blue-400' : '',
        color === 'red' ? 'bg-red-600 hover:bg-red-500 focus:outline-red-700' : '',
        color === 'red' && isLoading ? '!bg-red-700' : '',
        color === 'red' && disabled ? '!bg-red-400' : '',
        'text-white cursor-pointer relative flex items-center justify-center',
        isLoading ? 'cursor-progress' : '',
        disabled ? 'cursor-not-allowed' : '',
        className,
      )}
      onClick={(e) => {
        if (preventDefault) {
          e.preventDefault();
        }
        if (onClick) {
          onClick();
        }
      }}
      disabled={isLoading || disabled}
    >
      {(isLoading) ? <IconLoading className="w-5 h-5 animate-spin mr-2" /> : <Fragment />}
      {children}
    </button>
  );
}
