import { useEffect, useState, Fragment, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FeatureSpecial, GeojsonSchema, MapSchema } from '@/types/types';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import Button from '../../../ui/general/Button';
import { actionsNotification } from '../../../../features/notificationSlice';

export default function MapFeatureProperties({
  selectedFeature,
  loadedGeojsons,
  setUpdatePropertiesModalVisible,
  map,
}: {
  selectedFeature: FeatureSpecial | undefined;
  loadedGeojsons: GeojsonSchema[];
  setUpdatePropertiesModalVisible: Dispatch<SetStateAction<boolean>>;
  map: MapSchema,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [geojson, setGeojson] = useState<GeojsonSchema>();

  useEffect(() => {
    const temp = find(loadedGeojsons, (o: GeojsonSchema) => o.id === selectedFeature?.geojson); 
    if (temp) {
      setGeojson({...temp});
    } else {
      setGeojson(undefined);
    }
  }, [selectedFeature, loadedGeojsons]);

  return (
    <Fragment>
      <Button
        size="small"
        onClick={() => {
          if (map.isExample) {
            dispatch(actionsNotification.createAlert({ message: t('FEATURE_NOT_AVAILABLE_SAMPLE'), type: 'warning' }));
            return;
          }

          setUpdatePropertiesModalVisible(true);
        }}
      >
        <PencilSquareIcon className="w-4 h-4 mr-1" /> {t('EDIT')} {t('PROPERTIES')}
      </Button>
      {
        (geojson && geojson.properties && geojson.properties.length > 0) &&
        geojson.properties.map((o, index) => {
            return (
              <div
                key={index}
                className="pt-3"
              >
                <span className="font-semibold">{o.property}</span>: {(selectedFeature && selectedFeature.properties) ? selectedFeature.properties[o.property] : ''}
              </div>
            );
        })
      }
    </Fragment>
  );
}
