import { ReactNode } from 'react';
import Button from '../../ui/general/Button';

export default function BoardFooterBar({
  children,
  isLoading,
  disabled,
  onClick,
}: {
  children: ReactNode;
  isLoading: boolean;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <div className="fixed right-0 bottom-0 flex items-center pl-[24px] pr-[40px] h-[50px] bg-white border border-[#f0f0f0] shadow-lg w-full z-[1000]">
      <div className="ml-auto">
        <Button
          type="submit"
          size="small"
          disabled={disabled}
          isLoading={isLoading}
          onClick={onClick}
          preventDefault={false}
        >
          {children}
        </Button>
      </div>
    </div>
  );
}
