import cx from 'clsx';

export default function Divider() {
  return (
    <div
      className={cx(
        'flex clear-both w-full min-w-full mx-0 my-6 box-border p-0 text-gray-800',
        'text-sm leading-normal list-none border-t border-solid border-gray-200',
      )}
    />
  );
}
