import { NavLink } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';

type BreadcrumbType = {
  name: string;
  to?: string;
  current: boolean;
};

export default function Breadcrumbs({
  pages,
}: {
  pages: BreadcrumbType[],
}) {
  return (
    <>
    <nav className="flex m-[24px]" aria-label="Breadcrumb">
      <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li className="flex">
          <div className="flex items-center">
            <NavLink to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </NavLink>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              {
                (page.to) &&
                <NavLink
                  to={page.to}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </NavLink>
              }
              {
                (!page.to) &&
                <div className="ml-4 text-sm font-medium text-gray-500">
                  {page.name}
                </div>
              }
            </div>
          </li>
        ))}
      </ol>
    </nav>
    </>
  );
}
