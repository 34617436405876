import { appApi } from './api';
import { getToken } from '../store';

export const authApi = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (creds) => ({
        url: 'api/v1/auth/sign-up',
        method: 'POST',
        body: creds,
      }),
      invalidatesTags: ['Auth'],
    }),
    logIn: builder.mutation({
      query: (creds) => ({
        url: `api/v1/auth/sign-in`,
        method: 'POST',
        body: creds,
      }),
      invalidatesTags: ['Auth'],
    }),
    completeRegistration: builder.mutation({
      query: (creds) => ({
        url: `api/v1/auth/complete-registration`,
        method: 'POST',
        body: creds,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const {
  useSignUpMutation,
  useLogInMutation,
  useCompleteRegistrationMutation,
} = authApi;
