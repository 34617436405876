import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../ui/data-entry/Input';
import Modal from '../ui/feedback/Modal';
import Button from '../ui/general/Button';

export default function CreateNoteModal({
  visible,
  handleOk,
  handleCancel,
  isLoading,
}: {
  visible: boolean;
  handleOk: (val: string) => void;
  handleCancel: () => void;
  isLoading: boolean;
}) {
  const { t } = useTranslation();

  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (visible) {
      setContent('');
    }
  }, [visible]);

  return (
    <Modal
      title={`${t('CREATE')} ${t('NOTE')}`}
      isOpen={visible}
      setIsOpen={() => { handleCancel(); }}
      width={500}
    >
      <div>
        <Input
          id="content"
          label={t('NOTE')}
          placeholder={t('NOTE') as string}
          disabled={isLoading}
          value={content}
          onChange={(e: any) => { setContent(e.target.value); }}
        />
        <Button
          className="mt-3"
          size="small"
          onClick={() => { handleOk(content); }}
        >
          {t('CREATE')} {t('NOTE')}
        </Button>
      </div>
    </Modal>
  );
}
