import { useEffect, Fragment } from 'react';
import { useMap } from 'react-leaflet';

export default function MapResetView({
  lat,
  lng,
  triggerViewportReset,
  defaultZoom,
}: {
  lat: number;
  lng: number;
  triggerViewportReset: number;
  defaultZoom: number;
}) {
  const map = useMap();

  useEffect(() => {
    map.flyTo([lat, lng], defaultZoom);
  }, [lat, lng, map, triggerViewportReset, defaultZoom]);

  return <Fragment />;
}
