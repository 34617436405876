import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLogInMutation } from '../../features/auth';
import { setCredentials } from '../../features/authSlice';
import { actionsNotification } from '../../features/notificationSlice';
import { appApi } from '../../features/api';
import InputHook from '../ui/data-entry/InputHook';
import Button from '../ui/general/Button';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [logIn, { isLoading }] = useLogInMutation();

  const onFinish = async (fieldValues: FieldValues) => {
    try {
      dispatch(appApi.util.resetApiState());
      const user = await logIn({...fieldValues, password: fieldValues.pass}).unwrap();
      dispatch(setCredentials(user));
      dispatch(actionsNotification.createAlert({ message: t('LOGGED_IN'), type: 'success' }));
      navigate('/');
    } catch (err) {
      dispatch(actionsNotification.createAlert({ message: t('INVALID_CREDENTIALS'), type: 'error' }));
    }
  };

  return (
    <div className="min-h-screen py-10 px-5 bg-slate-100 flex items-center justify-center text-slate-600 ">
      <div className="max-w-[416px] sm:max-w-[456px] w-full">
        <h2 className="text-3xl font-bold text-slate-600 tracking-tight mb-2 text-center">
          {t('TO_LOGIN')}
        </h2>
        <div className="text-base text-center mb-8">
          {t('ARE_YOU_NEW_TO_GEOXPRO')} <Link to={`/${t('REGISTER_SLUG')}`} className="text-blue-700 hover:text-blue-500">{t('REGISTER_HERE')}</Link>
        </div>
        <form
          className="py-9 px-5 sm:px-10 bg-white w-full rounded-lg flex flex-col gap-4 shadow"
          onSubmit={handleSubmit((data) => onFinish(data))}
        >
          <InputHook
            register={{
              ...register('username', {
                required: true,
                maxLength: 256,
                minLength: 4,
              }),
            }}
            label={t('EMAIL_OR_USERNAME')}
            disabled={isLoading}
            errors={errors}
          />
          <InputHook
            register={{
              ...register('pass', {
                required: true,
              }),
            }}
            type="password"
            label={t('PASSWORD')}
            disabled={isLoading}
            errors={errors}
          />
          <Button
            type="submit"
            className="mt-5"
            onClick={() => {}}
            isLoading={isLoading}
            preventDefault={false}
          >
            {(isLoading) ? `${t('LOGGING_IN')}...` : t('TO_LOGIN') }
          </Button>
        </form>
      </div>
    </div>
  );
}
