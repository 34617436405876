import { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './store';
import Notification from './components/global/Notification';
import './i18n';
import 'moment/locale/es';

let persistor = persistStore(store);

const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Notification />
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>
);

reportWebVitals();
