import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_BASE_URL }),
  tagTypes: [
    'Auth',
    'Orthophoto',
    'Map',
    'User',
    'Company',
    'Project',
    'Geojson',
    'Aws',
    'Registry',
    'MapActivity',
    'Google',
  ],
  endpoints: (builder) => ({
  }),
});
