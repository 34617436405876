import { Link } from 'react-router-dom';
import Button from '../../ui/general/Button';

export default function BoardHeader({
  title = '',
  description = '',
  link = '',
  linkText = '',
}: {
  title: string;
  description: string;
  link?: string;
  linkText?: string;
}) {

  return (
    <div className="m-[24px] sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h1>
        <p className="mt-2 text-sm text-gray-700">
          {description}
        </p>
      </div>
      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        {
          (link && linkText) &&
          <Link to={link} type="primary">
            <Button size="small" preventDefault={false}>
              {linkText}
            </Button>
          </Link>
        }
      </div>
    </div>
  );
}
