import qs from 'qs';
import { omit } from 'lodash';
import { appApi } from './api';
import { getToken } from '../store';
import { UserSchema } from '@/types/types';

export const user = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => ({
        url: `/api/v1/users?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, args) => [
        { type: 'User', id: 'LIST' },
        ...(
          (returnValue) ?
          returnValue.map((user: UserSchema) => ({
            type: 'User',
            id: user.id,
          })) :
          []
        ),
      ],
    }),
    getUsersForMap: builder.query({
      query: (params) => ({
        url: `/api/v1/users/for-mapa?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, args) => [
        { type: 'User', id: 'LIST' },
        ...(
          (returnValue) ?
          returnValue.map((user: UserSchema) => ({
            type: 'User',
            id: user.id,
          })) :
          []
        ),
      ],
    }),
    getUser: builder.query({
      query: (params) => ({
        url: `/api/v1/users/${params.id}?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [{ type: 'User', id: args.id }],
    }),
    createUser: builder.mutation({
      query: (params) => ({
        url: '/api/v1/users',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    updateUser: builder.mutation({
      query: (params) => ({
        url: `/api/v1/users/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id')
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: (returnValue, error, args) => [{ type: 'User', id: args.id }],
    }),
    updateUserEnable: builder.mutation({
      query: (params) => ({
        url: `/api/v1/users/enable/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id'),
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: (returnValue, error, args) => [{ type: 'User', id: args.id }],
    }),
    attachUser: builder.mutation({
      query: (params) => ({
        url: `/api/v1/users/attach/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id'),
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: (returnValue, error, args) => [{ type: 'User', id: args.id }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUsersForMapQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserEnableMutation,
  useAttachUserMutation,
} = user;
