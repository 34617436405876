import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateGeojsonInMapMutation } from '../../../../features/geojson';
import { GeojsonSchema, MapSchema } from '@/types/types';
import { actionsNotification } from '../../../../features/notificationSlice';
import { useDispatch } from 'react-redux';
import Button from '../../../ui/general/Button';
import Input from '../../../ui/data-entry/Input';
import Modal from '../../../ui/feedback/Modal';

export default function ModalAddCapa({
  visible,
  setVisible,
  map,
  onCreated,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  map: MapSchema;
  onCreated: (val: GeojsonSchema) => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);

  const [createGeojsonInMap, { data, isLoading, isError, error, }] = useCreateGeojsonInMapMutation();

  useEffect(() => {
    if (!isLoading && isLoadingPrev && !isError) {
      dispatch(actionsNotification.createAlert({ message: t('LAYER_ADDED_SUCCESSFULLY'), type: 'success' }));
      onCreated(data);
    }
    setIsLoadingPrev(isLoading);
  }, [isLoading, isLoadingPrev, isError, onCreated, data, dispatch, t]);

  useEffect(() => {
    if (isError) {
      if (error) {
        if ('status' in error) {
          const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
          dispatch(actionsNotification.createAlert({ message: errMsg, type: 'error' }));
        } else {
          dispatch(actionsNotification.createAlert({ message: error.message, type: 'error' }));
        }
      } else {
        dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      }
    }
  }, [isError, error, dispatch, t]);

  useEffect(() => {
    if (visible) {
      setName('');
    }
  }, [visible]);

  return (
    <Modal
      title={`${t('ADD')} ${t('LAYER')}`}
      isOpen={visible}
      setIsOpen={setVisible}
      width={400}
    >
      <form>
        <Input
          id="name"
          label={t('NAME')}
          value={name}
          disabled={isLoading}
          onChange={(e: any) => { setName(e.currentTarget.value); }}
          placeholder={t('LAYER_S_NAME') as string}
        />
      </form>
      <Button
        className="mt-3"
        isLoading={isLoading}
        onClick={() => {
          if (map.isExample) {
            dispatch(actionsNotification.createAlert({ message: t('FEATURE_NOT_AVAILABLE_SAMPLE'), type: 'warning' }));
            return;
          }

          createGeojsonInMap({ name, map: map.id });
        }}
      >
        {t('ADD')}
      </Button>
    </Modal>
  );
}
