import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSignUpMutation } from '../../features/auth';
import { setCredentials } from '../../features/authSlice';
import { actionsNotification } from '../../features/notificationSlice';
import InputHook from '../ui/data-entry/InputHook';
import Button from '../ui/general/Button';
import { emailRegex, usernameRegex } from '../../utils/regex';

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [signUp, { isLoading }] = useSignUpMutation();

  const onFinish = async (fieldValues: FieldValues) => {
    try {
      const user = await signUp({ ...fieldValues, password: fieldValues.pass }).unwrap();
      dispatch(setCredentials(user));
      dispatch(actionsNotification.createAlert({ message: t('SUCCESSFUL_REGISTRATION'), type: 'success' }));
      navigate('/');
    } catch (err) {
      dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
    }
  };

  return (
    <div className="min-h-screen py-10 px-5 bg-slate-100 flex items-center justify-center text-slate-600 ">
      <div className="max-w-[416px] sm:max-w-[456px] w-full">
        <h2 className="text-3xl font-bold text-slate-600 tracking-tight mb-2 text-center">
          {t('TO_REGISTER')}
        </h2>
        <div className="text-base text-center mb-8">
          {t('ALREADY_HAVE_AN_ACCOUNT')} <Link to="/" className="text-blue-700 hover:text-blue-500">{t('LOG_IN_HERE')}</Link>
        </div>
        <form
          className="py-9 px-5 sm:px-10 bg-white w-full rounded-lg flex flex-col gap-4 shadow"
          onSubmit={handleSubmit((data) => onFinish(data))}
        >
          <InputHook
            register={{
              ...register('email', {
                required: true,
                maxLength: 256,
                minLength: 4,
                pattern: emailRegex,
              }),
            }}
            label={t('EMAIL')}
            disabled={isLoading}
            errors={errors}
          />
          <InputHook
            register={{
              ...register('username', {
                required: true,
                maxLength: 50,
                minLength: 4,
                pattern: usernameRegex,
              }),
            }}
            label={t('USERNAME')}
            disabled={isLoading}
            errors={errors}
          />
          <InputHook
            register={{
              ...register('pass', {
                required: true,
                maxLength: 80,
                minLength: 8,
              }),
            }}
            type="password"
            label={t('PASSWORD')}
            disabled={isLoading}
            errors={errors}
          />
          <Button
            type="submit"
            className="mt-5"
            onClick={() => {}}
            isLoading={isLoading}
            preventDefault={false}
          >
            {(isLoading) ? `${t('REGISTERING')}...` : t('REGISTER') }
          </Button>
          <div className="text-xs text-gray-500 mt-3">
            <Trans i18nKey="REGISTER_POLICIES">
              H
              <a
                className="text-blue-700 hover:text-blue-500"
                href="https://www.geoxpro.com/terminos-y-condiciones"
                target="_blank"
                rel="noreferrer"
              >
              </a>
              <a
                className="text-blue-700 hover:text-blue-500"
                href="https://www.geoxpro.com/politica-de-privacidad"
                target="_blank"
                rel="noreferrer"
              >
              </a>
              <a
                className="text-blue-700 hover:text-blue-500"
                href="https://www.geoxpro.com/politica-de-cookies"
                target="_blank"
                rel="noreferrer"
              >
              </a>
            </Trans>
          </div>
        </form>
      </div>
    </div>
  );
}
