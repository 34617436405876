import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureSpecial, MapSchema } from '@/types/types';
import { useTranslation } from 'react-i18next';
import { useUpdateRegistryGeometryByMapMutation } from '../../../../features/registry';
import { actionsNotification } from '../../../../features/notificationSlice';
import { TrashIcon } from '@heroicons/react/24/outline';
import Button from '../../../ui/general/Button';
import Input from '../../../ui/data-entry/Input';
import Table from '../../../ui/data-display/Table';
import Modal from '../../../ui/feedback/Modal';
import RadioGroup from 'src/components/ui/data-entry/RadioGroup';

enum ShapeTypes {
  Point = 'Point',
  LineString = 'LineString',
  Polygon = 'Polygon',
};

enum AddShapeTechnique {
  Wait = 'Wait',
  Manual = 'Manual',
  Draw = 'Draw',
};

type Coordenada = {
  lng: number;
  lat: number;
};

const coordenadaInitialState = { lat: 0, lng: 0 };
const coordenadasInitialState = [{ lat: 0, lng: 0 }, { lat: 0, lng: 0 }, { lat: 0, lng: 0 }];
const coordenadas2InitialState = [{ lat: 0, lng: 0 }, { lat: 0, lng: 0 }];

export default function MapUpdateRegistryGeometry({
  visible,
  setVisible,
  feature,
  map,
  handleOk,
} : {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  feature: FeatureSpecial | undefined;
  map: MapSchema;
  handleOk: (val: any) => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);
  const [shapeType, setShapeType] = useState<ShapeTypes>(ShapeTypes.Point);
  const [coordenada, setCoordenada] = useState<Coordenada>(coordenadaInitialState);
  const [coordenadas, setCoordenadas] = useState<Coordenada[]>(coordenadasInitialState);
  const [coordenadas2, setCoordenadas2] = useState<Coordenada[]>(coordenadas2InitialState);
  const [addShapeTechnique, setAddShapeTechnique] = useState<AddShapeTechnique>(AddShapeTechnique.Wait);

  const [updateRegistryGeometryByMap, { data, isLoading, isError, error }] = useUpdateRegistryGeometryByMapMutation();

  useEffect(() => {
    if (!isLoading && isLoadingPrev && !isError) {
      dispatch(actionsNotification.createAlert({ message: t('RECORD_ADDED_SUCCESSFULLY'), type: 'success' }));
      handleOk(data);
    }
    setIsLoadingPrev(isLoading);
  }, [isLoading, isLoadingPrev, isError, data, handleOk, dispatch, t]);

  useEffect(() => {
    if (visible) {
      setAddShapeTechnique(AddShapeTechnique.Wait);
      setCoordenada(coordenadaInitialState);
      setCoordenadas(coordenadasInitialState);
      setCoordenadas2(coordenadas2InitialState);
    }
  }, [visible]);

  useEffect(() => {
    if (addShapeTechnique === AddShapeTechnique.Draw) {
      setVisible(false);
    }
  }, [addShapeTechnique, setVisible]);

  useEffect(() => {
    if (isError) {
      if (error) {
        if ('status' in error) {
          const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
          dispatch(actionsNotification.createAlert({ message: errMsg, type: 'error' }));
        } else {
          dispatch(actionsNotification.createAlert({ message: error.message, type: 'error' }));
        }
      } else {
        dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      }
    }
  }, [isError, error, dispatch, t]);

  const saveGeometry = () => {
    const geometry = {
      type: ShapeTypes.Point,
      coordinates: [0, 0],
    } as {
      type: ShapeTypes;
      coordinates: any;
    };

    geometry.type = shapeType;

    if (shapeType === ShapeTypes.Point) {
      geometry.coordinates = [coordenada.lng, coordenada.lat];
    } else if (shapeType === ShapeTypes.Polygon) {
      const temp = [];
      coordenadas.forEach((o) => {
        temp.push([o.lng, o.lat]);
      });
      temp.push([coordenadas[0].lng, coordenadas[0].lat]);
      geometry.coordinates = [temp];
    } else if (shapeType === ShapeTypes.LineString) {
      const temp: any[] = [];
      coordenadas2.forEach((o) => {
        temp.push([o.lng, o.lat]);
      });
      geometry.coordinates = temp;
    }
    if (feature && feature.id && feature.geojson) {
      updateRegistryGeometryByMap({
        registryId: feature?.id,
        mapId: map.id,
        geojsonId: feature.geojson,
        geometry: JSON.stringify(geometry),
      });
    }
  }

  const columns = [
    {
      title: t('LATITUDE'),
      key: 'lat',
      render: (_text: any, _record: Coordenada, index: number) => {
        return (
          <Input
            id="latitude"
            label={t('LATITUDE')}
            value={`${coordenadas[index].lat}`}
            onChange={(val: any) => {
              if (typeof val !== 'number') {
                return;
              }
              const temp = [...coordenadas];
              coordenadas[index].lat = val;
              setCoordenadas([...temp]);
            }}
          />
        );
      },
    },
    {
      title: t('LONGITUDE'),
      key: 'lng',
      render: (_text: any, _record: Coordenada, index: number) => {
        return (
          <Input
            id="longitude"
            label={t('LONGITUDE')}
            value={`${coordenadas[index].lng}`}
            onChange={(val: any) => {
              const temp = [...coordenadas];
              coordenadas[index].lng = val;
              setCoordenadas([...temp]);
            }}
          />
        );
      },
    },
    (coordenadas.length > 3) ?
    {
      title: '',
      key: 'delete',
      width: 40,
      render: (_text: any, _record: Coordenada, index: number) => {
        return (
          <Button
            size="small"
            color="red"
            onClick={() => {
              const temp = [...coordenadas];
              temp.splice(index, 1);
              setCoordenadas([...temp]);
            }}
          >
            <TrashIcon className="w-5 h-5" />
          </Button>
        );
      },
    } : {},
  ];

  const columns2 = [
    {
      title: t('LATITUDE'),
      key: 'lat',
      render: (_text: any, _record: Coordenada, index: number) => {
        return (
          <Input
            id="latitude2"
            label={t('LATITUDE')}
            value={`${coordenadas2[index].lat}`}
            onChange={(val: any) => {
              const temp = [...coordenadas2];
              coordenadas2[index].lat = val;
              setCoordenadas2([...temp]);
            }}
          />
        );
      }
    },
    {
      title: t('LONGITUDE'),
      key: 'lng',
      render: (_text: any, _record: Coordenada, index: number) => {
        return (
          <Input
            id="longitude2"
            label={t('LONGITUDE')}
            value={`${coordenadas2[index].lng}`}
            onChange={(val: any) => {
              const temp = [...coordenadas2];
              coordenadas2[index].lng = val;
              setCoordenadas2([...temp]);
            }}
          />
        );
      }
    },
    (coordenadas2.length > 2) ?
    {
      title: '',
      key: 'delete',
      width: 40,
      render: (_text: any, _record: Coordenada, index: number) => {
        return (
          <Button
            size="small"
            color="red"
            onClick={() => {
              const temp = [...coordenadas2];
              temp.splice(index, 1);
              setCoordenadas2([...temp]);
            }}
          >
            <TrashIcon className="w-5 h-5" />
          </Button>
        );
      },
    } : {},
  ];

  return (
    <Modal
      title={`${t('ADD')} ${t('COORDINATES')}`}
      isOpen={visible}
      setIsOpen={setVisible}
      width={(addShapeTechnique === AddShapeTechnique.Manual) ? 900 : 400}
    >
      {
        (addShapeTechnique !== AddShapeTechnique.Manual) &&
        <div className="flex gap-2">
          <Button
            size="small"
            onClick={() => { setAddShapeTechnique(AddShapeTechnique.Manual); }}  
          >
            Manual
          </Button>
          <Button
            size="small"
            onClick={() => { setAddShapeTechnique(AddShapeTechnique.Draw); }}  
          >
            {t('DRAW')}
          </Button>
        </div>
      }
      {
        (addShapeTechnique === AddShapeTechnique.Manual) &&
        <Fragment>
          <div className="flex">
            <RadioGroup
              options={[
                {
                  label: t('POINT'),
                  value: ShapeTypes.Point,
                },
                {
                  label: t('POLYGON'),
                  value: ShapeTypes.Polygon,
                },
                {
                  label: t('POLYLINE'),
                  value: ShapeTypes.LineString,
                },
              ]}
              value={shapeType}
              onChange={(e) => {
                setShapeType(e);
              }}
            />
          </div>
          <h5 className="mt-4">{t('COORDINATES')}</h5>
          {
            (shapeType === ShapeTypes.Point) &&
            <div className="flex gap-4 items-center mb-4">
              <div className="w-[50%]">
                <Input
                  id="latitude3"
                  label={t('LATITUDE')}
                  value={`${coordenada.lat}`}
                  onChange={(e: any) => {
                    setCoordenada((prev) => ({ ...prev, lat: e }));
                  }}
                />
              </div>
              <div className="w-[50%]">
                <Input
                  id="longitude3"
                  label={t('LONGITUDE')}
                  value={`${coordenada.lng}`}
                  onChange={(e: any) => {
                    setCoordenada((prev) => ({ ...prev, lng: e }));
                  }}
                />
              </div>
            </div>
          }
          {
            (shapeType === ShapeTypes.Polygon) &&
            <Fragment>
              <Button
                className="mb-2"
                size="small"
                onClick={() => { setCoordenadas((prev) => [...prev, { lat: 0, lng: 0 }]); }}
              >
                {t('ADD')} {t('COORDINATE')}
              </Button>
              <Table
                className="mb-4"
                columns={columns}
                dataSource={coordenadas}
                isLoading={false}
              />
            </Fragment>
          }
          {
            (shapeType === ShapeTypes.LineString) &&
            <Fragment>
              <Button
                className="mb-2"
                size="small"
                onClick={() => { setCoordenadas2((prev) => [...prev, { lat: 0, lng: 0 }]); }}
              >
                {t('ADD')} {t('COORDINATE')}
              </Button>
              <Table
                className="mb-4"
                columns={columns2}
                dataSource={coordenadas2}
                isLoading={false}
              />
            </Fragment>
          }
          <div className="flex">
            <Button
              className="ml-auto"
              onClick={saveGeometry}
              disabled={isLoading}
            >
              {t('SAVE')}
            </Button>
          </div>
        </Fragment>
      }
    </Modal>
  );
}
