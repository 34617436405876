import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Board from './components/board/Board';
import SelectProject from './components/project/SelectProject';
import CompleteRegistration from './components/auth/CompleteRegistration';
import LogOut from './components/auth/LogOut';
import MapLeaflet from './components/map/MapLeaflet';
import { RootState } from './store';
import { AuthSchema } from './types/types';
import './styles/app.css';
import LanguageSwitcher from './components/translations/LanguageSwitcher';

export default function App() {
  const { isAuthenticated, user }: AuthSchema = useSelector((state: RootState) => state.auth);

  const completeRegistration = (isAuthenticated && user && !user.name);

  if (completeRegistration) {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/log-out" element={<LogOut />} />
            <Route path="/" element={<CompleteRegistration />} />
          </Routes>
        </div>
      </Router>
    );
  }

  if (isAuthenticated) {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/mapa/:slug" element={<MapLeaflet />} />
            <Route path="/map/:slug" element={<MapLeaflet />} />
            <Route path="/board/:projectId/*" element={<Board />} />
            <Route path="/log-out" element={<LogOut />} />
            <Route path="/" element={<SelectProject />} />
          </Routes>
        </div>
      </Router>
    );
  }

  return (
    <Router>
      <div className="App">
        <LanguageSwitcher />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/registro" element={<Register />} />
          <Route path="/register" element={<Register />} />
          <Route path="/log-out" element={<LogOut />} />
        </Routes>
      </div>
    </Router>
  );
}
