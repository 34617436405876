import { Dispatch, SetStateAction } from 'react';
import { Switch } from '@headlessui/react';
import cx from 'clsx';

export default function Toggle({
  enabled,
  setEnabled,
  srOnly = 'Use settings',
  className,
  disabled = false,
}: {
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>
  srOnly?: string;
  className?: string;
  disabled?: boolean;
}) {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={cx(
        'relative inline-flex h-[24px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors',
        'duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        enabled ? 'bg-blue-700' : 'bg-gray-400',
        className,
      )}
      disabled={disabled}
    >
      <span className="sr-only">{srOnly}</span>
      <span
        aria-hidden="true"
        className={cx(
          'pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
          enabled ? 'translate-x-4' : 'translate-x-0',
        )}
      />
    </Switch>
  )
}
