import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logOut } from '../../features/authSlice';
import { appApi } from '../../features/api';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function LogOut() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(function () {
      dispatch(logOut());
      dispatch(appApi.util.resetApiState());
      navigate('/');
    }, 1000);
  }, [dispatch, navigate]);

  return (
    <div className="min-h-screen py-10 px-5 bg-slate-100 flex items-center justify-center text-slate-600 ">
      <div className="max-w-[416px] sm:max-w-[456px] w-full">
        <div className="py-9 px-5 sm:px-10 bg-white w-full rounded-lg flex justify-center shadow">
          <ArrowPathIcon className="w-5 h-5 animate-spin mr-2" /> {t('LOGGING_OUT')}...
        </div>
      </div>
    </div>
  );
}
