import { Fragment, useState, useEffect } from 'react';
import { Route, Routes, useParams, useNavigate, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';
import {
  Bars3BottomLeftIcon,
  GlobeAltIcon,
  MapIcon,
  Square3Stack3DIcon,
  Cog6ToothIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Maps from './maps/Maps';
import Map from './maps/Map';
import Orthophotos from './orthophotos/Orthophotos';
import Orthophoto from './orthophotos/Orthophoto';
import Geojsons from './geojsons/Geojsons';
import Geojson from './geojsons/Geojson';
import GeojsonProcess from './geojsons/GeojsonProcess';
import Users from './users/Users';
import User from './users/User';
import Settings from './settings/Settings';
import { logOut } from '../../features/authSlice';
import { useGetProjectQuery } from '../../features/project';
import { useGetCompanyQuery } from '../../features/company';
import { checkAuthorization, onlyOwnerOrAdmin, onlyOwner } from '../../utils/util';
import { RootState } from '../../store';
import { actionsNotification } from '../../features/notificationSlice';
import IconTranslate from '../icons/IconTranslate';
import { useTranslate } from '../translations/useTranslate';

export default function Example() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { setLanguage, currentLanguage } = useTranslate();

  const { projectId } = useParams<{ projectId: string }>();
  const { user } = useSelector((state: RootState) => state.auth);

  const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<any[]>([]);

  const { data: project, isFetching, isError: fetchingIsError } = useGetProjectQuery({ projectId });
  const { data: company } = useGetCompanyQuery({});

  const userNavigation = [
    { name: t('PROJECTS'), href: '/' },
    { name: t('LOG_OUT'), href: '/log-out' },
  ];

  useEffect(() => {
    if (!project) {
      return;
    }

    const tempMenuItems = [];

    tempMenuItems.push({
      name: t('MAPS'),
      href: `/board/${projectId}/${t('SLUG_MAPS')}`,
      icon: MapIcon,
      current: location.pathname.includes('maps') || location.pathname.includes('mapas'),
    });

    if (checkAuthorization(user, project, 'orthophotos_view') && !project.isExample) {
      tempMenuItems.push({
        name: t('ORTHOPHOTOS'),
        href: `/board/${projectId}/${t('SLUG_ORTHOPHOTOS')}`,
        icon: GlobeAltIcon,
        current: location.pathname.includes('orthophotos') || location.pathname.includes('ortofotos'),
      });
    }

    if (checkAuthorization(user, project, 'geojsons_view') && !project.isExample) {
      tempMenuItems.push({
        name: 'GeoJSONs',
        href: `/board/${projectId}/geojsons`,
        icon: Square3Stack3DIcon,
        current: location.pathname.includes('geojsons'),
      });
    }

    if (onlyOwner(user) && !project.isExample) {
      tempMenuItems.push({
        name: t('USERS'),
        href: `/board/${projectId}/${t('SLUG_USERS')}`,
        icon: UsersIcon,
        current: location.pathname.includes('users') || location.pathname.includes('usuarios'),
      });
    }

    if (onlyOwner(user) && !project.isExample) {
      tempMenuItems.push({
        name: t('SETTINGS'),
        href: `/board/${projectId}/${t('SLUG_SETTINGS')}`,
        icon: Cog6ToothIcon,
        current: location.pathname.includes('settings') || location.pathname.includes('configuracion'),
      });
    }

    setMenuItems([...tempMenuItems]);
  }, [company, project, projectId, user, location, t]);

  useEffect(() => {
    if (!isFetching && isLoadingPrev && fetchingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      navigate('/');
    }
    setIsLoadingPrev(isFetching);
  }, [isFetching, isLoadingPrev, fetchingIsError, navigate, dispatch, t]);

  useEffect(() => {
    if (fetchingIsError) {
      dispatch(logOut());
      navigate('/');
    }
  }, [fetchingIsError, dispatch, navigate]);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1001] md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-slate-800 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">{t('CLOSE_SIDEBAR')}</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <NavLink to="/">
                    <img
                      className="block h-8 w-auto sm:block"
                      src="/logo-s-dark.png"
                      alt="Geoxpro"
                    />
                  </NavLink>
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">
                    {menuItems.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={cx(
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                          item.current ? 'bg-slate-900 text-white' : 'text-slate-300 hover:bg-slate-700 hover:text-white',
                        )}
                      >
                        <item.icon
                          className={cx(
                            'mr-4 flex-shrink-0 h-6 w-6',
                            item.current ? 'text-slate-300' : 'text-slate-400 group-hover:text-slate-300',
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-[1001]">
        <div className="flex min-h-0 flex-1 flex-col bg-slate-800">
          <div className="flex h-16 flex-shrink-0 items-center bg-slate-900 px-4">
            <NavLink to="/">
              <img
                className="block h-8 w-auto sm:block"
                src="/logo-s-dark.png"
                alt="Geoxpro"
              />
            </NavLink>
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav className="flex-1 space-y-1 px-2 py-4">
              {
                menuItems.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={cx(
                      item.current ? 'bg-slate-900 text-white' : 'text-slate-300 hover:bg-slate-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={cx(
                        item.current ? 'text-slate-300' : 'text-slate-400 group-hover:text-slate-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))
              }
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:pl-64">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
          <button
            type="button"
            className="border-r border-slate-200 px-4 text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">{t('OPEN_SIDEBAR')}</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4">
            <div className="ml-auto flex items-center">
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex rounded-full bg-slate-100 justify-center items-center focus:outline-none focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 focus:ring-white">
                    <span className="sr-only">{t('OPEN_TRANSLATE_MENU')}</span>
                    <div className="h-8 w-8 rounded-full flex text-base text-slate-900 items-center justify-center">
                      <IconTranslate className="h-5 w-5" />
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-28 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {() => (
                        <span
                          onClick={() => { setLanguage('es'); }}
                          className={cx((currentLanguage === 'es') ? 'bg-gray-100' : '', 'flex cursor-pointer items-center gap-1 px-4 py-2 text-sm text-gray-700')}
                        >
                          Español
                        </span>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {() => (
                        <span
                          onClick={() => { setLanguage('en'); }}
                          className={cx((currentLanguage === 'en') ? 'bg-gray-100' : '', 'flex cursor-pointer items-center gap-1 px-4 py-2 text-sm text-gray-700')}
                        >
                          English
                        </span>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex rounded-full bg-slate-800 justify-center items-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-slate-800">
                    <span className="sr-only">{t('OPEN_USER_MENU')}</span>
                    <div className="h-8 w-8 rounded-full flex text-base text-white items-center justify-center bg-violet-500">
                      {(user) && user.nameInitials}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <NavLink
                            to={item.href}
                            className={cx(
                              active ? 'bg-slate-100' : '',
                              'block px-4 py-2 text-sm text-slate-700'
                            )}
                          >
                            {item.name}
                          </NavLink>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <main className="">
          <Routes>
            <Route path="/mapas" element={<Maps />} />
            <Route path="/maps" element={<Maps />} />
            {
              (onlyOwnerOrAdmin(user, project)) &&
              <Fragment>
                <Route path="/mapas/agregar" element={<Map />} />
                <Route path="/mapas/editar/:mapId" element={<Map />} />
                <Route path="/maps/add" element={<Map />} />
                <Route path="/maps/edit/:mapId" element={<Map />} />
              </Fragment>
            }
            {
              (checkAuthorization(user, project, 'geojsons_view')) &&
              <Route path="/geojsons" element={<Geojsons />} />
            }
            {
              (checkAuthorization(user, project, 'geojsons_upsert')) &&
              <Fragment>
                <Route path="/geojsons/agregar" element={<Geojson />} />
                <Route path="/geojsons/procesar/:geojsonId" element={<GeojsonProcess />} />
                <Route path="/geojsons/add" element={<Geojson />} />
                <Route path="/geojsons/process/:geojsonId" element={<GeojsonProcess />} />
              </Fragment>
            }
            {
              (checkAuthorization(user, project, 'orthophotos_view')) &&
              <Fragment>
                <Route path="/ortofotos" element={<Orthophotos />} />
                <Route path="/orthophotos" element={<Orthophotos />} />
              </Fragment>
            }
            {
              (checkAuthorization(user, project, 'orthophotos_upsert')) &&
              <Fragment>
                <Route path="/ortofotos/agregar" element={<Orthophoto />} />
                <Route path="/ortofotos/editar/:orthophotoId" element={<Orthophoto />} />
                <Route path="/orthophotos/add" element={<Orthophoto />} />
                <Route path="/orthophotos/edit/:orthophotoId" element={<Orthophoto />} />
              </Fragment>
            }
            {
              (onlyOwner(user)) &&
              <Fragment>
                <Route path="/usuarios" element={<Users />} />
                <Route path="/users" element={<Users />} />
              </Fragment>
            }
            {
              (onlyOwner(user)) &&
              <Fragment>
                <Route path="/usuarios/agregar" element={<User />} />
                <Route path="/usuarios/editar/:userId" element={<User />} />
                <Route path="/users/add" element={<User />} />
                <Route path="/users/edit/:userId" element={<User />} />
              </Fragment>
            }
            {
              (onlyOwner(user)) &&
              <Fragment>
                <Route path="/configuracion" element={<Settings />} />
                <Route path="/settings" element={<Settings />} />
              </Fragment>
            }
          </Routes>
        </main>
      </div>
    </div>
  )
}
