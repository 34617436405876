import qs from 'qs';
import { omit } from 'lodash';
import { appApi } from './api';
import { getToken } from '../store';
import { GeojsonSchema } from '@/types/types';

export const geojson = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getGeojsons: build.query({
      query: (params) => ({
        url: `/api/v1/geojsons?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (result, error, arg) => [
        { type: 'Geojson', id: 'LIST' },
        ...(
          (result) ?
          result.map((geojson: GeojsonSchema) => ({
            type: 'Geojson',
            id: geojson.id
          })) :
          []
        ),
      ],
    }),
    getGeojson: build.query({
      query: (params) => ({
        url: `/api/v1/geojsons/${params.geojsonId}?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (result, error, arg) => [{ type: 'Geojson', id: (result && result.id) ? result.id : '' }],
    }),
    createGeojson: build.mutation({
      query: (params) => ({
        url: '/api/v1/geojsons',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Geojson', id: 'LIST' }],
    }),
    updateGeojson: build.mutation({
      query: (params) => ({
        url: `/api/v1/geojsons/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id'),
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Geojson', id: arg.id }],
    }),
    createGeojsonInMap: build.mutation({
      query: (params) => ({
        url: '/api/v1/map-geojsons',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Geojson', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetGeojsonsQuery,
  useLazyGetGeojsonQuery,
  useCreateGeojsonMutation,
  useUpdateGeojsonMutation,
  useCreateGeojsonInMapMutation,
} = geojson;
