import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { FeatureSpecial } from '@/types/types';
import Button from '../../../ui/general/Button';

export default function MapFeatureGeometry({
  selectedFeature,
  setFeatureDrawerVisible,
  setUpdateRegistryGeometryModalVisible,
}: {
  selectedFeature: FeatureSpecial | undefined;
  setFeatureDrawerVisible: Dispatch<SetStateAction<boolean>>;
  setUpdateRegistryGeometryModalVisible: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  return (
    <Fragment>
      {
        (selectedFeature && !selectedFeature.geometry) &&
        <Button
          size="small"
          onClick={() => {
            setFeatureDrawerVisible(false);
            setUpdateRegistryGeometryModalVisible(true);
          }}
        >
          <PencilSquareIcon className="w-4 h-4 mr-1" /> {t('ADD')} {t('COORDINATES')}
        </Button>
      }
      {
        (selectedFeature && selectedFeature.geometry) &&
        <pre>{JSON.stringify(selectedFeature.geometry, null, 2)}</pre>
      }
    </Fragment>
  );
}
