import { Dispatch, SetStateAction, useState, useRef } from 'react';
import cx from 'clsx';
import { PlusIcon } from '@heroicons/react/20/solid';

export default function SelectTags({
  id,
  label,
  disabled = false,
  placeholder = '',
  values,
  setValues,
  className = '',
}: {
  id: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>
  className?: string;
}) {
  const [value, setValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const addValue = () => {
    if (!value) {
      return;
    }

    setValues((prev) => [...prev, value]);
    setValue('');
    inputRef.current?.focus();
  }

  return (
    <div className={cx('', className)}>
      <label
        htmlFor={id}
        className={cx('text-base text-gray-700')}
      >
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            ref={inputRef}
            type="text"
            id={id}
            className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 text-base"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(e) => { setValue(e.target.value); }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                addValue();
              }
            }}
          />
        </div>
        <button
          type="button"
          className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          disabled={disabled}
          onClick={addValue}
        >
          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-2 rounded-md p-2 border border-gray-100 flex flex-wrap gap-2 min-h-[36px]">
        {
          values.map((o, index) => (
            <Tag
              value={o}
              deleteTag={() => {
                const tempValues = [...values];
                tempValues.splice(index, 1);
                setValues([...tempValues]);
              }}
            />
          ))
        }
      </div>
    </div>
  );
}

function Tag({
  value,
  deleteTag,
}: {
  value: string;
  deleteTag: () => void;
}) {
  return (
    <span className="inline-flex items-center rounded-full bg-blue-100 py-0.5 pl-2 pr-0.5 text-xs font-medium text-blue-700">
      {value}
      <button
        onClick={deleteTag}
        type="button"
        className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:bg-blue-500 focus:text-white focus:outline-none"
      >
        <span className="sr-only">Remover opción</span>
        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
}
