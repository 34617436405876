import { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCreateProjectMutation } from '../../features/project';
import { actionsNotification } from '../../features/notificationSlice';
import InputHook from '../ui/data-entry/InputHook';
import Button from '../ui/general/Button';

export default function AddProjectModal ({
  visible,
  handleOk,
  handleCancel,
}: {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);

  const [createProject, { isLoading, isError: creatingIsError }] = useCreateProjectMutation();

  const onFinish = (fieldValues: FieldValues) => {
    createProject({ name: fieldValues.name });
  };

  useEffect(() => {
    if (creatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
    }
  }, [creatingIsError, dispatch, t]);

  useEffect(() => {
    if (!isLoading && isLoadingPrev && !creatingIsError) {
      dispatch(actionsNotification.createAlert({message: t('PROJECT_ADDED_SUCCESSFULLY'), type: 'success'}));
      handleOk();
    }
    setIsLoadingPrev(isLoading);
  }, [isLoading, creatingIsError, handleOk, isLoadingPrev, dispatch, t]);

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible, reset]);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t('CREATE')} {t('PROJECT')}
                </Dialog.Title>
                <form
                  className="mt-4"
                  onSubmit={handleSubmit((data) => onFinish(data))}
                >
                  <InputHook
                    register={{
                      ...register('name', {
                        required: true,
                        maxLength: 50,
                        minLength: 1,
                      }),
                    }}
                    label={t('NAME')}
                    placeholder={t('PROJECT_S_NAME') as string}
                    disabled={isLoading}
                    errors={errors}
                  />
                  <Button
                    size="small"
                    className="mt-3 w-[180px]"
                    type="submit"
                    preventDefault={false}
                    isLoading={isLoading}
                  >
                    {(isLoading) ? `${t('CREATING')} ${t('PROJECT')}...` : `${t('CREATE')} ${t('PROJECT')}` }
                  </Button>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
