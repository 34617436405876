import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GeojsonPropertyTypes, GeojsonSchema, MapSchema, ProjectSchema } from '../../../../types/types';
import { useCreateRegistryPropertiesByMapMutation } from '../../../../features/registry';
import { actionsNotification } from '../../../../features/notificationSlice';
import Button from '../../../ui/general/Button';
import Select from '../../../ui/data-entry/Select';
import Input from '../../../ui/data-entry/Input';
import Modal from '../../../ui/feedback/Modal';

type Properties = {
  [name: string]: string;
};

export default function MapAddRegistry({
  visible,
  setVisible,
  map,
  geojson,
  handleOk,
} : {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  map: MapSchema;
  geojson: GeojsonSchema | undefined;
  handleOk: (val: any) => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);
  const [fields, setFields] = useState<Properties>({});

  const [createRegistryPropertiesByMap, { data, isLoading, isError, error }] = useCreateRegistryPropertiesByMapMutation();

  useEffect(() => {
    if (visible) {
      setFields({});
    }
  }, [visible]);

  useEffect(() => {
    if (!isLoading && isLoadingPrev && !isError) {
      dispatch(actionsNotification.createAlert({ message: t('RECORD_ADDED_SUCCESSFULLY'), type: 'success' }));
      handleOk(data);
    }
    setIsLoadingPrev(isLoading);
  }, [isLoading, isLoadingPrev, isError, data, handleOk, dispatch, t]);

  useEffect(() => {
    if (isError) {
      if (error) {
        if ('status' in error) {
          const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
          dispatch(actionsNotification.createAlert({ message: errMsg, type: 'error' }));
        } else {
          dispatch(actionsNotification.createAlert({ message: error.message, type: 'error' }));
        }
      } else {
        dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      }
    }
  }, [isError, error, dispatch, t]);

  const saveProperties = () => {
    if (geojson && geojson.id) {
      createRegistryPropertiesByMap({
        geojsonId: geojson.id,
        projectId: (map.project as ProjectSchema).id,
        properties: JSON.stringify(fields),
        mapId: map.id,
      });
    }
  }

  return (
    <Modal
      title={`${t('ADD')} ${t('RECORD')}`}
      isOpen={visible}
      setIsOpen={setVisible}
      width={900}
    >
      <form>
        {
          (geojson && geojson.properties && geojson.properties.length > 0) &&
          geojson.properties.map((o, index) => {
            return (
              <div key={index}>
                {
                  (o.type === GeojsonPropertyTypes.STRING) &&
                  <Input
                    id={o.property}
                    label={o.property}
                    value={fields[o.property]}
                    disabled={isLoading}
                    onChange={(e: any) => {
                      const tempFields: Properties = {...fields};
                      tempFields[o.property] = e.target.value;
                      setFields({...tempFields});
                    }}
                  />
                }
                {
                  (o.type === GeojsonPropertyTypes.NUMBER) &&
                  <Input
                    id={o.property}
                    label={o.property}
                    value={fields[o.property]}
                    disabled={isLoading}
                    onChange={(e: any) => {
                      const tempFields: Properties = {...fields};
                      tempFields[o.property] = e;
                      setFields({...tempFields});
                    }}
                  />
                }
                {
                  (o.type === GeojsonPropertyTypes.OPTION) &&
                  <>
                    <label className="text-base text-gray-700">
                      {o.property}
                    </label>
                    <Select
                      selected={{
                        id: fields[o.property],
                        name: fields[o.property],
                      }}
                      setSelected={(e: any) => {
                        const tempFields: Properties = {...fields};
                        tempFields[o.property] = e.id;
                        setFields({...tempFields});
                      }}
                      options={(o.options && o.options.length > 0) ? [...o.options.map((o) => ({ id: o, name: o }))] : []}
                    />
                  </>
                }
              </div>
            );
          })
        }
      </form>
      <div className="flex">
        <Button
          size="small"
          className="ml-auto"
          onClick={saveProperties}
          disabled={isLoading}
        >
          {t('SAVE')}
        </Button>
      </div>
    </Modal>
  );
}
