import { AuthSchema } from '@/types/types';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  token: '',
  isAuthenticated: false,
  user: {},
} as AuthSchema;

const slice = createSlice({
  name: 'auth',
  initialState: { ...initialState },
  reducers: {
    setCredentials: (
      state,
      { payload: { access_token, user } }
    ) => {
      state.token = access_token;
      state.isAuthenticated = true;
      state.user = user;
    },
    logOut: (state) => {
      state.token = '';
      state.isAuthenticated = false;
      state.user = {
        id: '',
        name: '',
        nameInitials: '',
        displayName: '',
        username: '',
        isOwner: false,
        surname: '',
        motherSurname: '',
        email: '',
      };
    },
  },
})

export const { setCredentials, logOut } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
