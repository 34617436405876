import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FeatureSpecial, GeojsonPropertyTypes, GeojsonSchema, MapSchema } from '../../../types/types';
import { useUpdateRegistryPropertiesByMapMutation } from '../../../features/registry';
import { actionsNotification } from '../../../features/notificationSlice';
import Button from '../../ui/general/Button';
import Select from '../../ui/data-entry/Select';
import Input from '../../ui/data-entry/Input';
import Modal from '../../ui/feedback/Modal';

type Properties = {
  [name: string]: string;
};

export default function UpdatePropertiesModal({
  visible,
  setVisible,
  feature,
  map,
  geojson,
  handleOk,
} : {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  feature: FeatureSpecial | undefined;
  map: MapSchema;
  geojson: GeojsonSchema | undefined;
  handleOk: (val: any) => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);
  const [fields, setFields] = useState<Properties>({});

  const [updateRegistryPropertiesByMap, { data, isLoading, isError, error }] = useUpdateRegistryPropertiesByMapMutation();

  useEffect(() => {
    if (visible && geojson && geojson.properties && geojson.properties.length > 0) {
      const temp: Properties = {};
      geojson.properties.forEach((o) => {
        temp[o.property] = (feature?.properties) ? feature?.properties[o.property] : '';
      });
      setFields({...temp});
    } else {
      setFields({});
    }
  }, [visible, feature, geojson]);

  useEffect(() => {
    if (!isLoading && isLoadingPrev && !isError) {
      dispatch(actionsNotification.createAlert({ message: t('PROPERTIES_UPDATED_SUCCESSFULLY'), type: 'success' }));
      handleOk(data);
    }
    setIsLoadingPrev(isLoading);
  }, [isLoading, isLoadingPrev, isError, data, handleOk, dispatch, t]);

  useEffect(() => {
    if (isError) {
      if (error) {
        if ('status' in error) {
          const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
          dispatch(actionsNotification.createAlert({ message: errMsg, type: 'error' }));
        } else {
          dispatch(actionsNotification.createAlert({ message: error.message, type: 'error' }));
        }
      } else {
        dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      }
    }
  }, [isError, error, dispatch, t]);

  const saveProperties = () => {
    if (feature && feature.id && feature.geojson) {
      updateRegistryPropertiesByMap({
        registryId: feature.id,
        geojsonId: feature.geojson,
        properties: JSON.stringify(fields),
        mapId: map.id,
      });
    }
  }

  return (
    <Modal
      title={`${t('UPDATE')} ${t('PROPERTIES')}`}
      isOpen={visible}
      setIsOpen={setVisible}
      width={900}
    >
      <form>
        {
          (geojson && geojson.properties && geojson.properties.length > 0) &&
          geojson.properties.map((o, index) => {
            return (
              <div key={index}>
                {
                  (o.type === GeojsonPropertyTypes.STRING) &&
                  <Input
                    value={fields[o.property]}
                    disabled={isLoading}
                    onChange={(e: any) => {
                      const tempFields: Properties = { ...fields };
                      tempFields[o.property] = e.target.value;
                      setFields({ ...tempFields });
                    }}
                    id={o.property}
                    label={o.property}
                  />
                }
                {
                  (o.type === GeojsonPropertyTypes.NUMBER) &&
                  <Input
                    value={fields[o.property]}
                    disabled={isLoading}
                    onChange={(e: any) => {
                      const tempFields: Properties = { ...fields };
                      tempFields[o.property] = e.target.value;
                      setFields({ ...tempFields });
                    }}
                    id={o.property}
                    label={o.property}
                  />
                }
                {
                  (o.type === GeojsonPropertyTypes.OPTION) &&
                  <>
                    <label className="text-base text-gray-700">
                      {o.property}
                    </label>
                    <Select
                      selected={{
                        id: fields[o.property],
                        name: fields[o.property],
                      }}
                      setSelected={(e: any) => {
                        const tempFields: Properties = {...fields};
                        tempFields[o.property] = e.id;
                        setFields({...tempFields});
                      }}
                      options={(o.options && o.options.length > 0) ? [...o.options.map((o) => ({ id: o, name: o }))] : []}
                    />
                  </>
                }
              </div>
            );
          })
        }
      </form>
      <div className="flex">
        <Button
          size="small"
          className="ml-auto"
          onClick={saveProperties}
          disabled={isLoading}
        >
          {t('SAVE')}
        </Button>
      </div>
    </Modal>
  );
}
