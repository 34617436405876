import { useTranslate } from './useTranslate';

export default function LanguageSwitcher() {
  const { setLanguage, currentLanguage } = useTranslate();

  return (
    <div className="absolute top-[40px] right-[40px]">
      <span className="isolate inline-flex rounded-md shadow-sm">
        <button
          onClick={() => { setLanguage('es') }}
          disabled={currentLanguage === 'es'}
          type="button"
          className="relative -ml-px inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:bg-gray-100"
        >
          Español
        </button>
        <button
          onClick={() => { setLanguage('en') }}
          disabled={currentLanguage === 'en'}
          type="button"
          className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:bg-gray-100"
        >
          English
        </button>
      </span>
    </div>
  );
}
