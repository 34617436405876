import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import IconTranslate from '../icons/IconTranslate';
import { useTranslate } from '../translations/useTranslate';

export default function MainHeader() {
  const { t } = useTranslation();
  const { setLanguage, currentLanguage } = useTranslate();

  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <Disclosure as="nav" className="bg-slate-800">
      {() => (
        <div className="mx-auto max-w-6xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="flex flex-1 items-center justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="block h-8 w-auto sm:block"
                  src="./logo-dark.png"
                  alt="Geoxpro"
                />
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex rounded-full bg-slate-900 justify-center items-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">{t('OPEN_TRANSLATE_MENU')}</span>
                    <div className="h-8 w-8 rounded-full flex text-base text-white items-center justify-center">
                      <IconTranslate className="h-5 w-5" />
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-28 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {() => (
                        <span
                          onClick={() => { setLanguage('es'); }}
                          className={cx((currentLanguage === 'es') ? 'bg-gray-100' : '', 'flex cursor-pointer items-center gap-1 px-4 py-2 text-sm text-gray-700')}
                        >
                          Español
                        </span>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {() => (
                        <span
                          onClick={() => { setLanguage('en'); }}
                          className={cx((currentLanguage === 'en') ? 'bg-gray-100' : '', 'flex cursor-pointer items-center gap-1 px-4 py-2 text-sm text-gray-700')}
                        >
                          English
                        </span>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex rounded-full bg-slate-800 justify-center items-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">{t('OPEN_USER_MENU')}</span>
                    <div className="h-8 w-8 rounded-full flex text-base text-white items-center justify-center bg-violet-500">
                      {(user) && user.nameInitials}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }: { active: boolean }) => (
                        <NavLink
                          to="/"
                          className={cx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          {t('PROJECTS')}
                        </NavLink>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }: { active: boolean }) => (
                        <NavLink
                          to="/log-out"
                          className={cx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          {t('LOG_OUT')}
                        </NavLink>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  );
}
