import { FieldErrorsImpl } from 'react-hook-form';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function InputHook({
  register,
  label,
  type = 'text',
  disabled = false,
  errors,
  placeholder = '',
}: {
  register: any;
  label: string;
  type?: 'text' | 'password';
  disabled?: boolean;
  errors?: Partial<FieldErrorsImpl<{[x: string]: any;}>>;
  placeholder?: string;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-1">
      <label
        className="text-base text-gray-700"
        htmlFor={register.name}
      >
        {label}
      </label>
      <input
        {...register}
        id={register.name}
        className={cx(
          'text-base relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700',
          'placeholder-gray-500 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500',
          disabled ? 'bg-slate-100' : '',
          errors && errors[register.name] ? '!border-red-400' : '',
        )}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
      />
      {errors && errors[register.name] && errors[register.name]?.type === 'required' && <span className="text-sm text-red-500">{t('FIELD_REQUIRED')}</span>}
      {errors && errors[register.name] && errors[register.name]?.type === 'minLength' && <span className="text-sm text-red-500">{t('SHOULD_HAVE_MORE_CHARS')}</span>}
      {errors && errors[register.name] && errors[register.name]?.type === 'maxLength' && <span className="text-sm text-red-500">{t('SHOULD_HAVE_FEWER_CHARS')}</span>}
      {errors && errors[register.name] && errors[register.name]?.type === 'pattern' && <span className="text-sm text-red-500">{t('CONTAINS_INVALID_CHARS')}</span>}
    </div>
  );
}
