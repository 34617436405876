/* eslint-disable no-loop-func */
import { useState, useEffect, useCallback, Fragment, Dispatch, SetStateAction, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'clsx';
import { FieldValues, useForm } from 'react-hook-form';
import { ArrowPathIcon, CheckCircleIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { MapContainer, TileLayer, ZoomControl, GeoJSON, useMap } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import { useCreateGeojsonMutation } from '../../../features/geojson';
import { useGeneratePresignedUrlMutation } from '../../../features/aws';
import { useGetProjectQuery } from '../../../features/project';
import { FeatureCollection } from '@/types/geojson';
import { actionsNotification } from '../../../features/notificationSlice';
import Breadcrumbs from '../../global/Breadcrumbs';
import Button from '../../ui/general/Button';
import Toggle from '../../ui/data-entry/Toggle';
import InputHook from '../../ui/data-entry/InputHook';
import Tabs from '../../ui/data-display/Tabs';
import BoardFooterBar from '../common/BoardFooterBar';
import BoardContent from '../common/BoardContent';
import BoardCard from '../common/BoardCard';
import BoardHeader from '../common/BoardHeader';

type Bounds = [[number, number], [number, number]];

export default function Geojson() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { projectId } = useParams<{ projectId: string }>();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [isCreatingPrev, setIsCreatingPrev] = useState<boolean>(false);
  const [isGeneratingPrev, setIsGeneratingPrev] = useState<boolean>(false);
  const [geojsonFile, setGeojsonFile] = useState<File>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadedSuccessfully, setUploadedSuccessfully] = useState<boolean>(false);
  const [showUploadingModal, setShowUploadingModal] = useState<boolean>(false);
  const [geojsonFileContent, setGeojsonFileContent] = useState<FeatureCollection | null>(null);
  const latitude = 19.432608;
  const longitude = -99.133209;
  const [geojsonObj, setGeojsonObj] = useState<FeatureCollection | null>(null);
  const [bounds, setBounds] = useState<Bounds>([
    [50.505, -29.09],
    [52.505, 29.09],
  ]);
  const [preload, setPreload] = useState<boolean>(true);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setGeojsonFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/json' : ['.json', '.geojson'],
    },
  });

  const [createGeojson, { isLoading: isCreating, isError: creatingIsError, error: creatingError }] = useCreateGeojsonMutation();
  const [generatePresignedUrl, { data: generatePresignedUrlData, isLoading: isGenerating, isError: generatingIsError }] = useGeneratePresignedUrlMutation();

  const { data: project } = useGetProjectQuery({ projectId });

  const isAnyLoading = useMemo<boolean>(() => (isCreating), [isCreating])

  const uploadToS3 = (file: File | undefined, url: string) => {
    if (file) {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.addEventListener('load', (e: any) => { handleUploadLoad(e); });
      xhr.send(file);
      setShowUploadingModal(true);
      setIsUploading(true);
    }
  }

  const handleUploadLoad = (event: any) => {
    if (event.target.status !== 200) {
      setIsUploading(false);
      setUploadedSuccessfully(false);
      return;
    }
    setUploadedSuccessfully(true);
    setIsUploading(false);
  }

  useEffect(() => {
    if (!isGenerating && isGeneratingPrev && !generatingIsError) {
      uploadToS3(geojsonFile, generatePresignedUrlData.presignedUrl);
    }
    setIsGeneratingPrev(isGenerating);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGenerating, isGeneratingPrev, generatingIsError, generatePresignedUrlData, geojsonFile]);

  useEffect(() => {
    if (!isCreating && isCreatingPrev && !creatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('GEOJSON_ADDED_SUCCESSFULLY'), type: 'success' }));
      navigate(`/board/${projectId}/geojsons`);
      return;
    }
    setIsCreatingPrev(isCreating);
  }, [isCreating, projectId, isCreatingPrev, creatingIsError, navigate, dispatch, t]);

  useEffect(() => {
    if (creatingIsError) {
      if (creatingError) {
        if ('status' in creatingError) {
          const errMsg = 'error' in creatingError ? creatingError.error : JSON.stringify(creatingError.data);
          dispatch(actionsNotification.createAlert({ message: errMsg, type: 'error' }));
        } else {
          dispatch(actionsNotification.createAlert({ message: creatingError.message, type: 'error' }));
        }
      } else {
        dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
      }
    }
  }, [creatingIsError, creatingError, dispatch, t]);

  const onFinish = (fieldValues: FieldValues) => {
    createGeojson({
      ...fieldValues,
      projectId,
      fileUrl: (preload) ? generatePresignedUrlData.fileName : '',
      hasBeenLoaded: false,
    });
  };

  useEffect(() => {
    if (geojsonFile) {
      const reader = new FileReader();
      reader.readAsText(geojsonFile, 'UTF-8');
      reader.onload = (evt: any) => {
        setGeojsonObj(JSON.parse(evt.target.result));
      };
      reader.onerror = (evt) => {
        dispatch(actionsNotification.createAlert({ message: t('ERROR_LOADING_GEOJSON'), type: 'error' }));
      };
    }
  }, [dispatch, geojsonFile, t]);

  useEffect(() => {
    setGeojsonFileContent(geojsonObj);
    if (geojsonObj && geojsonObj.features && geojsonObj.features.length > 0) {
      let highestLongitude = 0;
      let lowestLongitude = 0;
      let highestLatitude = 0;
      let lowestLatitude = 0;
      for (let i = 0; i < geojsonObj.features.length; i += 1) {
        const feature = geojsonObj.features[i];
        if (feature && feature.geometry) {
          if ((feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiLineString') && feature.geometry.coordinates && feature.geometry.coordinates.length > 0) {
            feature.geometry.coordinates.forEach((o, oIndex) => {
              o.forEach((u, uIndex) => {
                if (oIndex === 0 && uIndex === 0) {
                  highestLongitude = u[0];
                  lowestLongitude = u[0];
                  highestLatitude = u[1];
                  lowestLatitude = u[1];
                }
                if (highestLongitude < u[0]) {
                  highestLongitude = u[0];
                }
                if (lowestLongitude > u[0]) {
                  lowestLongitude = u[0];
                }
                if (highestLatitude < u[1]) {
                  highestLatitude = u[1];
                }
                if (lowestLatitude > u[1]) {
                  lowestLatitude = u[1];
                }
              });
            });
          }
        }
      }
      const tempBounds: Bounds = [
        [highestLatitude, highestLongitude],
        [lowestLatitude, lowestLongitude],
      ];
      setBounds(tempBounds);
    }
  }, [geojsonObj]);

  const tabItems = [
    {
      label: 'General',
      key: 'general',
      children: (
        <div className="board-padding">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3">
            <div>
              <InputHook
                register={{
                  ...register('name', {
                    required: true,
                    maxLength: 256,
                    minLength: 2,
                  }),
                }}
                label={t('NAME')}
                disabled={isAnyLoading}
                errors={errors}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-base text-gray-700">
                {t('PRELOAD_DATA')}
              </label>
              <Toggle enabled={preload} disabled={isAnyLoading} setEnabled={() => { setPreload(prev => !prev); }} />
            </div>
            {
              (!uploadedSuccessfully) &&
              <div className={cx('flex flex-col gap-1', preload ? 'block' : 'hidden')}>
                <label className="text-base text-gray-700">
                  {t('PRELOAD_DATA')}
                </label>
                <div {...getRootProps({
                    className: cx(
                      'flex flex-col items-center p-5 border-2 rounded-[6px] border-[#EEEEEE] border-dashed bg-[#FAFAFA] text-[#BDBDBD] outline-none transition-all',
                      isDragActive ? 'border-[#1890FF]' : '',
                    )
                })}>
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <div>{t('CLICK_OR_DRAG_DOC')}</div> :
                      <div>{t('CLICK_OR_DRAG_DOC')}</div>
                  }
                </div>
              </div>
            }
            <div className={cx('flex flex-col gap-1', preload ? 'block' : 'hidden')}>
              <label className="text-base text-gray-700">
                {t('DOCUMENT')}
              </label>
              {
                (geojsonFile) ?
                <div className="flex items-center">
                  {geojsonFile.name}{(uploadedSuccessfully) ? <CheckCircleIcon className="ml-1 text-[#52c41a] w-5" /> : ''}<br />
                  {
                    (!uploadedSuccessfully) &&
                    <Button
                      size="small"
                      isLoading={isGenerating}
                      onClick={() => { generatePresignedUrl({ projectId, name: geojsonFile.name, type: geojsonFile.type }); }}
                    >
                      {t('UPLOAD')}
                    </Button>
                  }
                </div> :
                <i>{t('SELECT_DOCUMENT')}</i>
              }
            </div>
          </div>
          <div className={cx('flex flex-col gap-1 mt-3', preload ? 'block' : 'hidden')}>
            <label className="text-base text-gray-700">
              {t('PREVIEW')}
            </label>
            <MapContainer
              center={[latitude, longitude]}
              zoom={13}
              className="h-[50vh] w-full"
              zoomControl={false}
              preferCanvas={true}
            >
              {
                (geojsonFileContent && geojsonFileContent.features && geojsonFileContent.features.length > 0) &&
                <GeoJSON
                  pointToLayer={(e, ll) => L.circleMarker(ll)}
                  data={{
                    type: 'FeatureCollection',
                    features: [...(geojsonFileContent.features.map((o) => o))],
                  } as FeatureCollection}
                />
              }
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                zIndex={1}
              />
              <ZoomControl position="topright" />
              <MapEventsComponent bounds={bounds} />
            </MapContainer>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(project) ? project.name : ''}`, current: false },
          { name: 'GeoJSONs', to: `/board/${projectId}/geojsons`, current: false },
          { name: t('ADD'), current: true },
        ]}
      />
      <BoardHeader
        title={`${t('ADD')} ${t('GEOJSON')}`}
        description={t('BOARD_GEOJSON_CREATE_PAGE_DESCRIPTION')}
      />
      <form onSubmit={handleSubmit((data) => onFinish(data))}>
        <BoardContent>
          <BoardCard>
            <Tabs defaultActiveKey="general" items={tabItems} />
          </BoardCard>
        </BoardContent>
        <BoardFooterBar
          disabled={(!uploadedSuccessfully && preload) || isAnyLoading}
          isLoading={isAnyLoading}
          onClick={() => {}}
        >
          {isCreating ? `${t('CREATING')} GeoJSON...` : `${t('CREATE')} GeoJSON`}
        </BoardFooterBar>
      </form>
      <Result
        open={showUploadingModal}
        setOpen={setShowUploadingModal}
        isUploading={isUploading}
        uploadedSuccessfully={uploadedSuccessfully}
      />
    </>
  );
}

function MapEventsComponent({
  bounds,
}: {
  bounds: Bounds;
}) {
  const map = useMap();
  useEffect(() => {
    // map.fitBounds(bounds);
  }, [bounds, map]);
  return null;
}

function Result({
  open,
  setOpen,
  isUploading,
  uploadedSuccessfully,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isUploading: boolean;
  uploadedSuccessfully: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1100]" onClose={() => { if (!isUploading) { setOpen(false); } }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-[1100] overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div
                    className={cx(
                      'mx-auto flex h-12 w-12 items-center justify-center rounded-full',
                      isUploading ? 'bg-blue-100' : '',
                      !isUploading && uploadedSuccessfully ? 'bg-green-100' : '',
                      !isUploading && !uploadedSuccessfully ? 'bg-red-100' : '',
                    )}
                  >
                    {isUploading ? <ArrowPathIcon className="w-6 h-6 animate-spin text-blue-600" /> : ''}
                    {!isUploading && uploadedSuccessfully ? <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> : ''}
                    {!isUploading && !uploadedSuccessfully ? <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> : ''}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {(isUploading) ? `${t('UPLOADING')} ${t('DOCUMENT')}` : ''}
                      {(!isUploading && uploadedSuccessfully) ? t('DOCUMENT_UPLOADED_SUCCESSFULLY') : ''}
                      {(!isUploading && !uploadedSuccessfully) ? t('ERROR_UPLOADING_DOCUMENT') : ''}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center">
                  <Button
                    disabled={isUploading}
                    size="small"
                    onClick={() => { if (!isUploading) { setOpen(false); }}}
                  >
                    {t('CLOSE')}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
