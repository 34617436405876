import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FeatureSpecial, GeojsonSchema, MapSchema, RegistriesObjectState } from '@/types/types';
import cx from 'clsx';
import { findIndex, filter } from 'lodash';
import { CursorArrowRaysIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import MapAddRegistry from './MapAddRegistry';
import MapUpdateRegistryGeometry from './MapUpdateRegistryGeometry';
import { removeAccents } from '../../../../utils/util';
import { actionsNotification } from '../../../../features/notificationSlice';
import Button from '../../../ui/general/Button';
import Select from '../../../ui/data-entry/Select';
import Input from '../../../ui/data-entry/Input';

export default function MapRegistries({
  selectedFeature,
  setSelectedFeature,
  selectedGeojson,
  registriesObject,
  setRegistriesObject,
  registriesObjectVisible,
  setFeatureDrawerVisible,
  map,
}: {
  selectedFeature: FeatureSpecial | undefined;
  setSelectedFeature: Dispatch<SetStateAction<FeatureSpecial | undefined>>;
  selectedGeojson: GeojsonSchema | undefined;
  registriesObject: RegistriesObjectState;
  setRegistriesObject: Dispatch<SetStateAction<RegistriesObjectState>>;
  registriesObjectVisible: RegistriesObjectState;
  setFeatureDrawerVisible: Dispatch<SetStateAction<boolean>>;
  map: MapSchema;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [propertyToShow, setPropertyToShow] = useState<string>('');
  const [registries, setRegistries] = useState<FeatureSpecial[]>([]);
  const [properties, setProperties] = useState<string[]>([]);
  const [mapAddRegistryVisible, setMapAddRegistryVisible] = useState<boolean>(false);
  const [mapUpdateRegistryGeometryVisible, setMapUpdateRegistryGeometryVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (selectedGeojson && registriesObjectVisible && registriesObjectVisible[selectedGeojson.id] && Array.isArray(registriesObjectVisible[selectedGeojson.id])) {
      const temp = [...registriesObjectVisible[selectedGeojson.id]];
      temp.sort(function (a, b) {
        if (a && a.id && b && b.id) {
          if (a.id > b.id) {
            return -1;
          }
          if (a.id < b.id) {
            return 1;
          }
        }
        return 0;
      });
      if (!search || !propertyToShow) {
        setRegistries(temp);
      } else {
        const searchNoAccentsLowerCase = removeAccents(search).toLowerCase();
        const tempFiltered = filter(temp, (o) => {
          if (!o || !o.properties || o.properties[propertyToShow] === undefined) {
            return false;
          }
          if (typeof o.properties[propertyToShow] === 'string' && removeAccents(o.properties[propertyToShow]).toLowerCase().includes(searchNoAccentsLowerCase)) {
            return true;
          }
          if (typeof o.properties[propertyToShow] === 'number' && o.properties[propertyToShow].toString().includes(search)) {
            return true;
          }
          return false;
        });
        setRegistries(tempFiltered);
      }
    }
  }, [selectedGeojson, registriesObjectVisible, search, propertyToShow]);

  useEffect(() => {
    if (selectedGeojson && selectedGeojson.properties) {
      setProperties([...selectedGeojson.properties.map(o => o.property)]);
    }
  }, [selectedGeojson]);

  useEffect(() => {
    if (properties && properties.length > 0) {
      setPropertyToShow(properties[0]);
    }
  }, [properties]);

  const handleUpdateRegistryGeometry = (val: any) => {
    setSelectedFeature({...val});
    const temp = {...registriesObject};
    if (temp && temp[val.geojson] && findIndex(temp[val.geojson], (o) => o.id === val.id) >= 0) {
      const tempIndex = findIndex(temp[val.geojson], (o) => o.id === val.id);
      temp[val.geojson][tempIndex] = {...val};
      setRegistriesObject({...temp});
    }
    setMapUpdateRegistryGeometryVisible(false);
  }

  return (
    <Fragment>
      <Button
        size="small"
        className="mb-3"
        onClick={() => {
          if (map.isExample) {
            dispatch(actionsNotification.createAlert({ message: t('FEATURE_NOT_AVAILABLE_SAMPLE'), type: 'warning' }));
            return;
          }

          setMapAddRegistryVisible(true);
        }}
      >
        {t('ADD')} {t('RECORD')}
      </Button>
      <Input
        id="buscar"
        label="Buscar"
        placeholder={`Buscar por ${propertyToShow}`}
        value={search}
        onChange={(e: any) => { setSearch(e.target.value); setSelectedFeature(undefined); }}
      />
      <div className="mb-1">{t('PROPERTIES')}:</div>
      <Select
        selected={{
          id: propertyToShow,
          name: propertyToShow,
        }}
        setSelected={(e: any) => { setPropertyToShow(e.id); setSearch(''); setSelectedFeature(undefined); }}
        options={(properties && properties.length > 0) ? [...properties.map((o) => ({ id: o, name: o }))] : []}
      />
      {
        (registries !== undefined && Array.isArray(registries)) &&
        registries.map((o: FeatureSpecial) => {
          const isSelected = selectedFeature?.id === o.id;
          const hasGeometry = o?.geometry;
          return (
            <div key={o.id} className="pt-3 flex items-center">
              <Button
                size="xs"
                className="mr-2"
                onClick={() => { setSelectedFeature(o); setFeatureDrawerVisible(true); }}
              >
                <CursorArrowRaysIcon className="w-4 h-4" />
              </Button>
              {
                (!hasGeometry) &&
                <Button
                  size="xs"
                  className="mr-2 text-white"
                  onClick={() => { setMapUpdateRegistryGeometryVisible(true); setSelectedFeature(o); setFeatureDrawerVisible(false); }}
                >
                  <InformationCircleIcon className="w-4 h-4" />
                </Button>
              }
              {
                (o && o.properties) &&
                <span className={cx({ underline: isSelected })}>{o.properties[propertyToShow]}</span>
              }
            </div>
          );
        })
      }
      <MapAddRegistry
        visible={mapAddRegistryVisible}
        setVisible={setMapAddRegistryVisible}
        map={map}
        geojson={selectedGeojson}
        handleOk={(data) => {
          if (selectedGeojson && selectedGeojson.id && registriesObject) {
            const temp = {...registriesObject};
            if (temp && temp[selectedGeojson.id]) {
              temp[selectedGeojson.id].push({...data});
              setRegistriesObject({...temp});
            } else if (temp) {
              temp[selectedGeojson.id] = [{...data}];
              setRegistriesObject({...temp});
            }
          } else {
            dispatch(actionsNotification.createAlert({ message: t('THE_RECORD_HAS_NOT_BEEN_ADDED'), type: 'error' }));
          }
          setMapAddRegistryVisible(false);
        }}
      />
      <MapUpdateRegistryGeometry
        visible={mapUpdateRegistryGeometryVisible}
        setVisible={setMapUpdateRegistryGeometryVisible}
        feature={selectedFeature}
        map={map}
        handleOk={(data) => { handleUpdateRegistryGeometry(data); }}
      />
    </Fragment>
  );
}
