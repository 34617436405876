import { useTranslation } from 'react-i18next';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function LoadingPage() {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex flex-col items-center justify-center mb-2 text-gray-600">
        <ArrowPathIcon className="w-8 h-8 animate-spin mb-2 text-gray-600" />
        <div className="text-lg font-medium">{t('LOADING')}...</div>
      </div>
    </div>
  );
}
