import qs from 'qs';
import { appApi } from './api';
import { getToken } from '../store';

export const map = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getMapActivitiesByMap: builder.query({
      query: (params) => ({
        url: `/api/v1/map-activities/by-map/${params.mapId}?${qs.stringify(params.query)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [{ type: 'MapActivity', id: args || '' }],
    }),
  }),
});

export const {
  useLazyGetMapActivitiesByMapQuery,
} = map;
