import { Feature } from 'geojson';

export type AuthSchema = {
  isAuthenticated: boolean;
  user: AuthUserSchema;
  token: string | null;
};

export type AuthUserSchema = {
  id: string;
  name: string;
  nameInitials: string;
  displayName: string;
  username: string;
  isOwner: boolean;
  surname: string;
  motherSurname: string;
  email: string;
};

export type ProjectSchema = {
  id: string;
  name: string;
  timezone: string;
  users: string[] | UserSchema[];
  company: string | CompanySchema;
  isExample: boolean;
  // special
  permission?: any;
  kind?: string;
  project?: {
    id: string;
    name: string;
  }
};

export type CompanySchema = {
  id: string;
  name: string;
};

export type GeojsonSchema = {
  id: string;
  name: string;
  slug: string;
  fileUrl: string;
  hasBeenLoaded: boolean;
  properties: GeojsonProperty[];
  totalFeatures: number;
  defaultColor: string;
  classifications: Array<Clasificacion>;
  classificationsKeys: {
    [field: string]: PlotItem[];
  };
  project: string;
  map: string[];
  company: string;
};

export type MapSchema = {
  id: string;
  name: string;
  slug: string;
  description: string;
  status: string;
  latitude: number;
  longitude: number;
  defaultZoom: number;
  minZoom: number;
  maxZoom: number;
  isExample: boolean;
  imageUrl: string;
  layerSelected: string;
  files: string;
  activeGeojsons: [string];
  activeOrthophotos: [string];
  project: string | ProjectSchema;
  notes: string[] | NoteSchema[];
  users: string[] | UserSchema[];
  company: string | CompanySchema;
  //
  geojsons: GeojsonSchema[];
  orthophotos: OrthophotoSchema[];
}

export type NoteSchema = {
  id: string;
  content: string;
  createdAt: Date;
  user: string | UserSchema;
  map: string | MapSchema;
  company: string | CompanySchema;
};

export type UserSchema = {
  id: string;
  email: string;
  username: string;
  displayName: string;
  name: string;
  surname: string;
  motherSurname: string;
  fullName: string;
  nameInitials: string;
  birthDate: string;
  gender: string;
  role: string;
  password: string;
  status: string;
  isOwner: boolean;
  agreeTermsPrivacy: boolean;
  updatePassword: boolean;
  tempPassword: string;
  company: string;
  enabled: boolean;
  hasPermissions: boolean;
};

export type OrthophotoSchema = {
  id: string;
  name: string;
  url: string;
  tms: boolean;
  isProtected: boolean;
  slug: string;
  bar: string;
  barLeft: string;
  barRight: string;
  project: string | ProjectSchema;
  maps: string[] | MapSchema[];
  company: string | CompanySchema;
};

export type GeojsonsStateType = {
  [id: string]: GeojsonSchema;
};

export type RegistriesObjectState = {
  [id: string]: FeatureSpecial[];
};

export interface FeatureSpecial extends Feature {
  classifications?: any;
  geojson?: string;
  documents?: any;
}

export type FeatureStyles = {
  fillOpacity?: number;
  color?: string;
  fillColor?: string;
  weight?: number;
  opacity?: number;
  strokeWidth?: number;
}

export type MapActivitySchema = {
  id: string;
  type: string;
  description: string;
  createdAt: Date;
  newObject: Object;
  oldObject: Object;
  map: string | MapSchema;
  user: string | UserSchema;
  company: string | CompanySchema;
}

export type SelectedClassificationsType = {
  [geojsonId: string]: string;
};

export type SelectedFiltrosType = {
  [geojsonId: string]: string[];
};

export type Clasificacion = {
  idx: number;
  type: string;
  field?: string;
  label?: string;
  gradient: [string, string];
  conditions: Condicion[];
};

export type Condicion = {
  idx: number;
  label: string;
  type: string;
  value: string;
  color: string;
  selectColor?: boolean;
};

export type PlotItem = {
  value: any;
  color: string;
  count: number;
};

export type GeojsonProperty = {
  property: string;
  type: GeojsonPropertyTypes;
  options?: string[];
}

export enum GeojsonPropertyTypes {
  STRING = 'string',
  NUMBER = 'number',
  OPTION = 'option',
};