import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetProjectQuery, useUpdateProjectMutation } from '../../../features/project';
import { actionsNotification } from '../../../features/notificationSlice';
import Breadcrumbs from '../../global/Breadcrumbs';
import InputHook from '../../ui/data-entry/InputHook';
import BoardFooterBar from '../common/BoardFooterBar';
import BoardContent from '../common/BoardContent';
import BoardCard from '../common/BoardCard';
import BoardCardHeader from '../common/BoardCardHeader';
import BoardHeader from '../common/BoardHeader';

export default function Settings() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { projectId } = useParams();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [isFetchingPrev, setIsFetchingPrev] = useState<boolean>(false);
  const [isUpdatingPrev, setIsUpdatingPrev] = useState<boolean>(false);

  const { data, isFetching, isError: fetchingIsError, refetch } = useGetProjectQuery({ projectId });

  const [updateProject, { isLoading: isUpdating, isError: updatingIsError }] = useUpdateProjectMutation();

  const isAnyLoading = useMemo<boolean>(() => (isFetching || isUpdating), [isFetching, isUpdating]);

  useEffect(() => {
    if (!isUpdating && isUpdatingPrev && !updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('PROJECT_UPDATED_SUCCESSFULLY'), type: 'success' }));
      refetch();
    }
    setIsUpdatingPrev(isUpdating);
  }, [isUpdating, projectId, isUpdatingPrev, updatingIsError, refetch, dispatch, t]);

  useEffect(() => {
    if ((!isFetching && isFetchingPrev && !fetchingIsError) || data) {
      setValue('name', data.name);
    }
    setIsFetchingPrev(isFetching);
  }, [isFetching, data, isFetchingPrev, fetchingIsError, setValue]);

  useEffect(() => {
    if (updatingIsError) {
      dispatch(actionsNotification.createAlert({ message: t('UNEXPECTED_ERROR'), type: 'error' }));
    }
  }, [dispatch, t, updatingIsError]);

  const onFinish = (fieldValues: FieldValues) => {
    updateProject({
      ...fieldValues,
      id: projectId,
    });
  };

  return (
    <>
      <Breadcrumbs
        pages={[
          { name: `${t('PROJECT')} ${(data) ? data.name : ''}`, current: false },
          { name: t('SETTINGS'), current: true },
        ]}
      />
      <BoardHeader
        title={t('SETTINGS')}
        description={t('BOARD_SETTINGS_PAGE_DESCRIPTION')}
      />
      <form onSubmit={handleSubmit((data) => onFinish(data))}>
        <BoardContent>
          <BoardCard>
            <BoardCardHeader>
              <h3 className="board-h3">{t('BASIC_SETTINGS')}</h3>
            </BoardCardHeader>
            <div className="board-padding">
              <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3">
                <div>
                  <InputHook
                    register={{
                      ...register('name', {
                        required: true,
                        maxLength: 256,
                        minLength: 2,
                      }),
                    }}
                    label={t('NAME')}
                    disabled={isAnyLoading}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
          </BoardCard>
        </BoardContent>
        <BoardFooterBar
          onClick={() => {}}
          disabled={isAnyLoading}
          isLoading={isAnyLoading}
        >
          {t('UPDATE')}
        </BoardFooterBar>
      </form>
    </>
  );
}
