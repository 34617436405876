import qs from 'qs';
import { omit } from 'lodash';
import { appApi } from './api';
import { getToken } from '../store';
import { MapSchema } from '@/types/types';

export const map = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getMaps: build.query({
      query: (params) => ({
        url: `/api/v1/maps?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [
        { type: 'Map', id: 'LIST' },
        ...(
          (returnValue) ?
          returnValue.map((map: MapSchema) => ({
            type: 'Map',
            id: map.id
          })) :
          []
        ),
      ]
    }),
    getMap: build.query({
      query: (params) => ({
        url: `/api/v1/maps/${params.mapId}?${qs.stringify({ ...omit(params, 'mapId') })}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [{ type: 'Map', id: args || '' }],
    }),
    getMapBySlug: build.query<MapSchema, any>({
      query: (params) => ({
        url: `/api/v1/maps/by-slug/${params.slug}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [{ type: 'Map', id: args || '' }],
    }),
    createMap: build.mutation({
      query: (params) => ({
        url: '/api/v1/maps',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Map', id: 'LIST' }],
    }),
    updateMap: build.mutation({
      query: (params) => ({
        url: `/api/v1/maps/${params.id}`,
        method: 'PUT',
        body: {
          ...omit(params, 'id'),
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: (returnValue, error, args) => [{ type: 'Map', id: args.id }],
    }),
    getMapsSearch: build.query({
      query: (params) => ({
        url: `/api/v1/maps?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [
        { type: 'Map', id: 'LIST' },
        ...(
          (returnValue) ?
          returnValue.map((map: MapSchema) => ({
            type: 'Map',
            id: map.id
          })) :
          []
        ),
      ]
    }),
  }),
});

export const {
  useGetMapsQuery,
  useLazyGetMapQuery,
  useCreateMapMutation,
  useUpdateMapMutation,
  useLazyGetMapsQuery,
  useLazyGetMapBySlugQuery,
} = map;
