import { ReactNode } from 'react';

export default function BoardContent({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="m-[24px] mb-[100px]">
      {children}
    </div>
  );
}
