import { ReactNode } from 'react';
import cx from 'clsx';

export default function BoardCard({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className="py-2">
      <div className={cx('py-0 px-5 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg', className)}>
        {children}
      </div>
    </div>
  );
}
