import qs from 'qs';
import { omit } from 'lodash';
import { appApi } from './api';
import { getToken } from '../store';
import { ProjectSchema } from '@/types/types';

export const project = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: (params) => ({
        url: `/api/v1/projects?${qs.stringify(params)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [
        { type: 'Project', id: 'LIST' },
        ...(
          (returnValue && returnValue.data) ?
          returnValue.data.map((project: ProjectSchema) => ({
            type: 'Project',
            id: project.id
          })) :
          []
        )
      ],
    }),
    getProject: builder.query({
      query: (params) => ({
        url: `/api/v1/projects/${params.projectId}?${qs.stringify(omit(params, 'projectId'))}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      providesTags: (returnValue, error, args) => [{ type: 'Project', id: (args) ? args.id : '' }],
    }),
    createProject: builder.mutation({
      query: (params) => ({
        url: '/api/v1/projects',
        method: 'POST',
        body: {
          ...params
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: [{ type: 'Project', id: 'LIST' }],
    }),
    updateProject: builder.mutation({
      query: (params) => ({
        url: `/api/v1/projects/${params.id}`,
        method: 'PUT',
        body: {
          ...params,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: (returnValue, error, args) => [{ type: 'Project', id: args.id }],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
} = project;
