import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'clsx';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function Drawer({
  title = '',
  children,
  isOpen,
  onClose,
  width,
  closable = true,
  placement = 'right',
}: {
  title?: string;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  width: number;
  closable?: boolean;
  placement?: 'right' | 'left';
}) {
  const { t } = useTranslation();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={cx('relative z-[1000]', closable ? 'pointer-events-auto' : 'pointer-events-none')}
        onClose={() => { if (closable) { onClose(); }}}
      >
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className={cx('pointer-events-none fixed inset-y-0 flex max-w-full', placement === 'right' ? 'pl-10 right-0' : 'pr-10 left-0')}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease duration-300"
                enterFrom={cx(placement === 'right' ? 'translate-x-full' : '-translate-x-full')}
                enterTo="translate-x-0"
                leave="transform transition ease duration-300"
                leaveFrom="translate-x-0"
                leaveTo={cx(placement === 'right' ? 'translate-x-full' : '-translate-x-full')}
              >
                <Dialog.Panel className="pointer-events-auto w-screen" style={{ width }}>
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-auto py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                              onClick={() => onClose()}
                            >
                              <span className="sr-only">{t('CLOSE_PANEL')}</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {children}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
