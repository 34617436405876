import { appApi } from './api';
import { getToken } from '../store';

export const google = appApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getGeocode: build.query({
      query: (address) => ({
        url: `/api/v1/google/${address}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetGeocodeQuery,
} = google;
