import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { OrthophotoSchema } from '@/types/types';
import Button from '../ui/general/Button';
import Table from '../ui/data-display/Table';
import Modal from '../ui/feedback/Modal';

export default function OrthophotoSelectModal({
  visible,
  setVisible,
  selectOrthophotoEvent,
  orthophotos,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectOrthophotoEvent: (val: OrthophotoSchema) => void;
  orthophotos: OrthophotoSchema[];
}) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('NAME'),
      key: 'name',
      render: (record: OrthophotoSchema) => (record.name),
    },
    {
      title: t('ACTION'),
      key: 'action',
      render: (record: OrthophotoSchema) => (
        <div className="flex gap-4 items-center">
          <Button
            size="small"
            onClick={() => {
              selectOrthophotoEvent(record);
            }}
          >
            {t('SELECT')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Modal
      title={`${t('SELECT')} ${t('ORTHOPHOTO')}`}
      isOpen={visible}
      setIsOpen={setVisible}
      width={900}
    >
      <div>
        <Table
          columns={columns}
          dataSource={orthophotos}
          isLoading={false}
        />
      </div>
    </Modal>
  );
}
