import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureSpecial, MapSchema } from '@/types/types';
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useGeneratePresignedUrlToGetObjectMutation } from '../../../../features/aws';
import Button from '../../../ui/general/Button';
import { actionsNotification } from '../../../../features/notificationSlice';
import Modal from '../../../ui/feedback/Modal';

export default function MapFeatureFiles({
  map,
  selectedFeature,
  handleAddFile,
}: {
  map: MapSchema,
  selectedFeature: FeatureSpecial | undefined;
  handleAddFile: () => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoadingPresignedUrlPrev, setIsLoadingPresignedUrlPrev] = useState<boolean>(false);
  const [isImage, setIsImage] = useState<boolean>(false);

  const [generatePresignedUrlToGetObject, { data: dataPresignedUrl, isLoading: isLoadingPresignedUrl, isError: isErrorPresignedUrl }] = useGeneratePresignedUrlToGetObjectMutation();

  useEffect(() => {
    if (isErrorPresignedUrl) {
      dispatch(actionsNotification.createAlert({ message: t('DOCUMENT_HAS_NOT_BEEN_UPLOADED'), type: 'error' }));
      setIsImage(false);
    }
  }, [isErrorPresignedUrl, dispatch, t]);

  useEffect(() => {
    if (isLoadingPresignedUrlPrev && !isLoadingPresignedUrl && !isErrorPresignedUrl && dataPresignedUrl && !isImage) {
      window.open(dataPresignedUrl.presignedUrl, '_blank');
    }
    setIsLoadingPresignedUrlPrev(isLoadingPresignedUrl);
  }, [isLoadingPresignedUrl, isLoadingPresignedUrlPrev, isErrorPresignedUrl, isImage, dataPresignedUrl]);

  const handleListItem = (item: any) => {
    const listImageExtensions = ['.png', '.jpeg', '.jpg'];
    let tempIsImage = false;
    listImageExtensions.forEach((u) => {
      if (item.path.includes(u)) {
        tempIsImage = true;
      }
    });
    setIsImage(tempIsImage);
    if (selectedFeature) {
      generatePresignedUrlToGetObject({
        name: item.name,
        mapId: map.id,
        geojsonId: selectedFeature.geojson,
        registryId: selectedFeature.id,
        path: item.path,
      });
    }
  }

  return (
    <Fragment>
      {
        (selectedFeature) &&
        <Button
          size="small"
          onClick={handleAddFile}
        >
          <PlusIcon className="w-4 h-4 mr-1" /> {t('ADD')} {t('DOCUMENT')}
        </Button>
      }
      {
        (selectedFeature && selectedFeature.documents && Array.isArray(selectedFeature.documents)) &&
        <div className="flex flex-col gap-2">
          {
            selectedFeature.documents.map((item) => (
              <div
                className="cursor-pointer"
                onClick={() => { handleListItem(item); }}
              >
                {item.name}
              </div>
            ))
          }
        </div>
      }
      <Modal
        title={t('DOCUMENT')}
        isOpen={isImage}
        setIsOpen={setIsImage}
        width={900}
      >
        {
          (isLoadingPresignedUrl) &&
          <div className="flex justify-center items-center h-[200px]">
            <ArrowPathIcon className="w-5 h-5 animate-spin" />
          </div>
        }
        {
          (!isLoadingPresignedUrl && dataPresignedUrl && dataPresignedUrl.presignedUrl) &&
          <img src={(dataPresignedUrl) ? dataPresignedUrl.presignedUrl : ''} alt={t('DOCUMENT') as string} />
        }
        <Button
          className="mt-5"
          onClick={() => { setIsImage(false); }}
        >
          {t('CLOSE')}
        </Button>
      </Modal>
    </Fragment>
  );
}
