import { RadioGroup as RadioGroupHeadless } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

type Option = {
  label: string;
  value: string;
};

export default function RadioGroup({
  value,
  options,
  onChange,
  disabled = false,
}: {
  value: string;
  options: Option[];
  onChange: (value: any) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <RadioGroupHeadless
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <RadioGroupHeadless.Label className="sr-only">
          {t('OPTIONS')}
        </RadioGroupHeadless.Label>
        <div className="flex gap-1">
          {options.map((option) => (
            <RadioGroupHeadless.Option
              key={option.value}
              value={option.value}
              disabled={disabled}
              className={({ active, checked }) =>
                cx(
                  'relative flex cursor-pointer rounded px-3 h-8 shadow focus:outline-none',
                  active ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-300' : '',
                  checked || active ? 'bg-blue-600 text-white' : 'bg-white',
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroupHeadless.Label
                          as="div"
                          className={cx(checked || active ? 'text-white' : 'text-blue-900')}
                        >
                          {option.label}
                        </RadioGroupHeadless.Label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </RadioGroupHeadless.Option>
          ))}
        </div>
      </RadioGroupHeadless>
    </div>
  )
}
