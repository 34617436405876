import { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, ZoomControl, GeoJSON, LayersControl, Marker, Popup } from 'react-leaflet';
import { map as lodashMap, clone, findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon, ChatBubbleOvalLeftEllipsisIcon, HomeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import HistoryIcon from '../icons/mdi/HistoryIcon';
import L from 'leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Logo from '../icons/logo';
import MapInfo from './partials/MapInfo';
import MapActivity from './partials/MapActivity';
import MapNotes from './partials/MapNotes';
import MapCapas from './partials/MapLayers';
import MapSelectedLayer from './partials/MapSelectedLayer';
import MapFeatureDrawer from './partials/MapFeatureDrawer';
import MapEditCapa from './partials/MapEditLayer';
import MapGeocoding from './partials/MapGeocoding';
import MapMarkAddress from './partials/MapMarkAddress';
import MapResetView from './partials/MapResetView';
import LoadingPage from '../global/LoadingPage';
import { FeatureSpecial, FeatureStyles, GeojsonSchema, GeojsonsStateType, MapSchema, OrthophotoSchema, ProjectSchema, RegistriesObjectState, SelectedClassificationsType, SelectedFiltrosType } from '../../types/types';
import { FeatureCollection } from '@/types/geojson';
import { useLazyGetOrthophotosSignedUrlsQuery } from '../../features/aws';
import { useLazyGetMapBySlugQuery } from '../../features/map';
import { useLazyGetRegistriesByGeojsonsQuery } from '../../features/registry';
import { leafletDrawLanguage } from '../../utils/language';
import 'leaflet-draw';

const { BaseLayer } = LayersControl;

L.drawLocal.draw = leafletDrawLanguage.draw;
L.drawLocal.edit = leafletDrawLanguage.edit;

export default function MapLeaflet() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { slug } = useParams();

  const [pageHasLoaded, setPageHasLoaded] = useState<boolean>(false);
  const [pageHasError, setPageHasError] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [defaultLatitude, setDefaultLatitude] = useState<number>(0);
  const [defaultLongitude, setDefaultLongitude] = useState<number>(0);
  const [maxZoom, setMaxZoom] = useState<number>(0);
  const [minZoom, setMinZoom] = useState<number>(0);
  const [defaultZoom, setDefaultZoom] = useState<number>(0);
  const [capasSelectActive, setCapasSelectActive] = useState<boolean>(false);
  const [activeOrthophotos, setActiveOrthophotos] = useState<string[]>([]);
  const [activeGeojsons, setActiveGeojsons] = useState<string[]>([]);
  const [loadedOrthophotos, setLoadedOrthophotos] = useState<OrthophotoSchema[]>([]);
  const [loadedGeojsons, setLoadedGeojsons] = useState<GeojsonSchema[]>([]);
  const [oIndexes, setOIndexes] = useState<string[]>([]);
  const [gIndexes, setGIndexes] = useState<string[]>([]);
  const [registriesObject, setRegistriesObject] = useState<RegistriesObjectState>({});
  const [registriesObjectVisible, setRegistriesObjectVisible] = useState<RegistriesObjectState>({});
  const [geojsons, setGeojsons] = useState<GeojsonsStateType>({});
  const [selectedCapa, setSelectedCapa] = useState<string>('');
  const [selectedGeojson, setSelectedGeojson] = useState<GeojsonSchema>();
  const [selectedClassifications, setSelectedClassifications] = useState<SelectedClassificationsType>({});
  const [selectedFiltros, setSelectedFiltros] = useState<SelectedFiltrosType>({});
  const [opacidad, setOpacidad] = useState<boolean>(true);
  const [triggerViewporReset, setTriggerViewporReset] = useState<number>(0);

  // Partials
  const [visibleDrawer, setVisibleDrawer] = useState<'INFO' | 'NOTES' | 'ACTIVITY' | ''>('');
  const [mapClassificationsVisible, setMapClassificationsVisible] = useState<boolean>(false);

  // Feature Click
  const [featureDrawerVisible, setFeatureDrawerVisible] = useState<boolean>(false);
  const [selectedFeature, setSelectedFeature] = useState<FeatureSpecial>();

  const [mapEditCapaJSX, setMapEditCapaJSX] = useState<JSX.Element>(<Fragment />);

  // Google geocoding
  const [geocodingGeometry, setGeocodingGeometry] = useState<any>();
  const [triggerViewportChange, setTriggerViewportChange] = useState<number>(0);
  const [showGeocodeMarker, setShowGeocodeMarker] = useState<boolean>(false);
  const [addressGeocode, setAddressGeocode] = useState<string>('');
  const [geocodingFormattedAddress, setGeocodingFormattedAddress] = useState<string>('');

  const [getMapBySlug, { data: map, isFetching, isError: fetchingIsError }] = useLazyGetMapBySlugQuery();
  const [getRegistriesByGeojsons, { data: dataRegistries }] = useLazyGetRegistriesByGeojsonsQuery();
  const [getOrthophotosSignedUrls, { data: orthophotosSignedUrls }] = useLazyGetOrthophotosSignedUrlsQuery();

  useEffect(() => {
    getMapBySlug({ slug });
  }, [getMapBySlug, slug]);

  const items = [
    {
      key: 'logo',
      title: 'Logo',
      icon: <Logo className="small" size="small" type="light" />,
      label: '',
    },
    {
      key: 'home',
      title: 'Home',
      icon: <HomeIcon className="w-5" />,
      label: '',
    },
    {
      key: 'info',
      title: t('INFORMATION'),
      icon: <InformationCircleIcon className="w-5" />,
      label: '',
    },
    {
      key: 'notes',
      title: t('NOTES'),
      icon: <ChatBubbleOvalLeftEllipsisIcon className="w-5" />,
      label: '',
    },
    {
      key: 'activity',
      title: t('RECENT_ACTIVITY'),
      icon: <HistoryIcon className="w-5" />,
      label: '',
    },
    {
      key: 'salir',
      title: t('EXIT'),
      icon: <ArrowLeftIcon className="w-5" />,
      label: '',
    },
  ];

  useEffect(() => {
    if (gIndexes && gIndexes.length > 0 && dataRegistries && dataRegistries.length > 0) {
      const tempDataRegistries: RegistriesObjectState = {};
      for (let i = 0; i < dataRegistries.length; i += 1) {
        if (tempDataRegistries.hasOwnProperty(dataRegistries[i].geojson)) {
          tempDataRegistries[dataRegistries[i].geojson].push(dataRegistries[i]);
        } else {
          tempDataRegistries[dataRegistries[i].geojson] = [dataRegistries[i]];
        }
      }
      setRegistriesObject({...tempDataRegistries});
    }
  }, [dataRegistries, gIndexes]);

  useEffect(() => {
    if (!isFetching && map) {
      if (fetchingIsError) {
        setPageHasError(true);
        setPageHasLoaded(true);
      } else {
        setPageHasLoaded(true);
        setLongitude(map.longitude);
        setLatitude(map.latitude);
        setDefaultLongitude(map.longitude);
        setDefaultLatitude(map.latitude);
        setMaxZoom(map.maxZoom);
        setMinZoom(map.minZoom);
        setDefaultZoom(map.defaultZoom);
        setLoadedGeojsons(map.geojsons);
        setLoadedOrthophotos(map.orthophotos);
        setActiveGeojsons((map.activeGeojsons && map.activeGeojsons.length > 0) ? [...map.activeGeojsons] : []);
        setActiveOrthophotos((map.activeOrthophotos && map.activeOrthophotos.length > 0) ? [...map.activeOrthophotos] : []);
        if ((map.activeGeojsons && map.activeGeojsons.length > 0) || (map.activeOrthophotos && map.activeOrthophotos.length > 0)) {
          setCapasSelectActive(true);
        }
        if (map.orthophotos && map.orthophotos.length > 0) {
          const tempOIndexes: string[] = [];
          map.orthophotos.forEach((o: OrthophotoSchema) => {
            tempOIndexes.push(o.id);
          });
          setOIndexes([...tempOIndexes]);
        }
        if (map.geojsons && map.geojsons.length > 0) {
          const tempGIndexes: string[] = [];
          map.geojsons.forEach((o: GeojsonSchema) => {
            tempGIndexes.push(o.id);
          });
          setGIndexes([...tempGIndexes]);
        }
      }
    }
  }, [isFetching, fetchingIsError, map]);

  useEffect(() => {
    if (map && map.id) {
      getOrthophotosSignedUrls({ id: map.id });
    }
  }, [map, getOrthophotosSignedUrls]);

  useEffect(() => {
    if (orthophotosSignedUrls && orthophotosSignedUrls.length > 0) {
      const cloneLoadedOrthophotos = [...loadedOrthophotos];
      let updatedOrthophotos = false;
      for (let i = 0; i < orthophotosSignedUrls.length; i += 1) {
        const orthophotoIndex = findIndex(cloneLoadedOrthophotos, o => o.id === orthophotosSignedUrls[i].id && o.url !== orthophotosSignedUrls[i].protectedUrl);
        if (orthophotoIndex >= 0) {
          cloneLoadedOrthophotos[orthophotoIndex] = {...orthophotosSignedUrls[i], url: orthophotosSignedUrls[i].protectedUrl};
          updatedOrthophotos = true;
        }
      }
      if (updatedOrthophotos) {
        setLoadedOrthophotos([...cloneLoadedOrthophotos]);
      }
    }
  }, [orthophotosSignedUrls, loadedOrthophotos]);

  useEffect(() => {
    if (loadedGeojsons && loadedGeojsons.length > 0) {
      const geojsonsIds = lodashMap(loadedGeojsons, 'id');
      getRegistriesByGeojsons({ geojsons: JSON.stringify(geojsonsIds) });
    }
  }, [getRegistriesByGeojsons, loadedGeojsons]);

  const onMenuItemClick = (e: { key: string }) => {
    if (e.key === 'info') {
      setVisibleDrawer('INFO');
    } else if (e.key === 'notes') {
      setVisibleDrawer('NOTES');
    } else if (e.key === 'home') {
      resetView();
    }  else if (e.key === 'activity') {
      setVisibleDrawer('ACTIVITY');
    } else if (e.key === 'salir') {
      if (map && map.project && (map.project as ProjectSchema).id) {
        navigate(`/board/${(map.project as ProjectSchema).id}/${t('SLUG_MAPS')}`);
      } else {
        navigate('/');
      }
    }
  }

  useEffect(() => {
    if (selectedCapa) {
      setMapClassificationsVisible(true);
    } else {
      setMapClassificationsVisible(false);
    }
  }, [selectedCapa]);

  useEffect(() => {
    if (mapClassificationsVisible === false) {
      setSelectedCapa('');
    }
  }, [mapClassificationsVisible]);

  useEffect(() => {
    if (loadedGeojsons && loadedGeojsons.length > 0) {
      const tempGeojsons: GeojsonsStateType = {};
      const tempSelectedClassifications: SelectedClassificationsType = {};
      const tempSelectedFiltros: SelectedFiltrosType = {};
      for (let i = 0; i < loadedGeojsons.length; i += 1) {
        tempGeojsons[loadedGeojsons[i].id] = { ...loadedGeojsons[i], defaultColor: (loadedGeojsons[i].defaultColor) ? loadedGeojsons[i].defaultColor : '#0062b1' };
        if (loadedGeojsons[i] && loadedGeojsons[i].classificationsKeys && Object.keys(loadedGeojsons[i].classificationsKeys) && Object.keys(loadedGeojsons[i].classificationsKeys)[0]) {
          const firstKey = Object.keys(loadedGeojsons[i].classificationsKeys)[0];
          tempSelectedClassifications[loadedGeojsons[i].id] = firstKey;
          tempSelectedFiltros[loadedGeojsons[i].id] = loadedGeojsons[i].classificationsKeys[firstKey].map(o => o.color);
        } else {
          tempSelectedClassifications[loadedGeojsons[i].id] = '';
          tempSelectedFiltros[loadedGeojsons[i].id] = [];
        }
      }
      setGeojsons({...tempGeojsons});
      setSelectedClassifications({ ...tempSelectedClassifications });
      setSelectedFiltros({ ...tempSelectedFiltros });
    }
  }, [loadedGeojsons]);

  useEffect(() => {
    if (registriesObject && gIndexes && geojsons) {
      const temp: RegistriesObjectState = {};
      gIndexes.forEach((u) => {
        temp[u] = [];
        if (!registriesObject || !registriesObject[u]) {
          return;
        }
        registriesObject[u].forEach((feature) => {
          if (selectedClassifications && selectedClassifications[u] && feature && feature.classifications && feature.classifications[selectedClassifications[u]]) {
            if (selectedFiltros && selectedFiltros[u] && selectedFiltros[u].length > 0 && selectedFiltros[u].includes(feature.classifications[selectedClassifications[u]])) {
              temp[u].push({...feature});
            }
          } else {
            if (geojsons && geojsons[u] && geojsons[u].defaultColor) {
              temp[u].push({...feature});
            }
          }
        });
      });
      setRegistriesObjectVisible({...temp});
    }
  }, [gIndexes, geojsons, registriesObject, selectedClassifications, selectedFiltros]);

  useEffect(() => {
    if (selectedFeature === undefined) {
      setFeatureDrawerVisible(false);
    }
  }, [selectedFeature]);

  const setMapEditCapaJSXCallback = useCallback(() => {
    if (selectedCapa !== '') {
      setMapEditCapaJSX(
        <MapEditCapa
          idx={Math.random()}
          selectedFeature={selectedFeature}
          map={map as MapSchema}
          selectedCapa={selectedCapa}
          featureDrawerVisible={featureDrawerVisible}
          registriesObject={registriesObject}
          setRegistriesObject={setRegistriesObject}
        />
      );
    } else {
      setMapEditCapaJSX(<Fragment />);
    }
  }, [selectedFeature, map, selectedCapa, featureDrawerVisible, registriesObject]);

  useEffect(() => {
    setMapEditCapaJSXCallback();
  }, [selectedFeature, map, selectedCapa, featureDrawerVisible, registriesObject, setMapEditCapaJSXCallback]);

  const resetView = () => {
    setTriggerViewporReset(prev => prev + 1);
  }

  // Google geocoging
  useEffect(() => {
    const gg = geocodingGeometry;
    setTriggerViewportChange(prev => prev + 1);
    if (gg && gg.location && gg.location.lat && gg.location.lng) {
      setLatitude(gg.location.lat);
      setLongitude(gg.location.lng);
      setShowGeocodeMarker(true);
    }
  }, [geocodingGeometry]);

  useEffect(() => {
    setShowGeocodeMarker(false);
  }, [addressGeocode])

  if (!pageHasLoaded) {
    return (<LoadingPage />);
  }

  if (pageHasError) {
    return (<Fragment>{t('THE_PAGE_HAS_AN_ERROR')}</Fragment>);
  }

  return (
    <div className="h-screen">
      <div className="map-container relative overflow-hidden">
        <div className="pt-5 absolute top-0 left-0 w-20 h-screen z-[501] bg-white flex flex-col gap-3 items-center">
          {
            items.map((o) => (
              <div
                key={o.key}
                className="w-10 h-10 flex justify-center items-center cursor-pointer hover:text-blue-500 transition-colors"
                onClick={() => { onMenuItemClick({ key: o.key }); }}
              >
                {o.icon}
              </div>
            ))
          }
        </div>
        <div>
          {
            (false) &&
            <MapGeocoding
              map={map as MapSchema}
              address={addressGeocode}
              setAddress={setAddressGeocode}
              setGeocodingGeometry={setGeocodingGeometry}
              setGeocodingFormattedAddress={setGeocodingFormattedAddress}
            />
          }
          <MapContainer
            className="w-screen h-screen"
            center={[latitude, longitude]}
            zoom={defaultZoom}
            maxZoom={maxZoom}
            minZoom={minZoom}
            zoomControl={false}
            preferCanvas={true}
          >
            {
              (gIndexes && gIndexes.length > 0 && registriesObjectVisible) &&
              clone(gIndexes).reverse().map((u, idx) => {
                if (registriesObjectVisible && registriesObjectVisible[u] && registriesObjectVisible[u].length > 0 && activeGeojsons.includes(u)) {
                  return (
                    <GeoJSON
                      key={`${u}-${Math.random()}`}
                      pointToLayer={(e, ll) => L.circleMarker(ll)}
                      eventHandlers={{
                        click: (e) => {
                          setFeatureDrawerVisible(true);
                          setSelectedFeature(e.sourceTarget.feature);
                        }
                      }}
                      data={{
                        type: 'FeatureCollection',
                        features: [...(registriesObjectVisible[u].map(o => o))]
                      } as FeatureCollection}
                      style={(feature: FeatureSpecial | undefined) => {
                        const styles: FeatureStyles = {};
                        if (selectedClassifications && selectedClassifications[u] && feature && feature.classifications && feature.classifications[selectedClassifications[u]]) {
                          styles.fillOpacity = (opacidad) ? 1 : 0.5;
                          styles.opacity = (opacidad) ? 1 : 0.5;
                          styles.color = '#eeeeee';
                          styles.fillColor = feature.classifications[selectedClassifications[u]];
                        } else if (selectedClassifications && selectedClassifications[u] === '') {
                          if (geojsons && geojsons[u] && geojsons[u].defaultColor) {
                            styles.fillOpacity = (opacidad) ? 1 : 0.5;
                            styles.opacity = (opacidad) ? 1 : 0.5;
                            styles.color = '#eeeeee';
                            styles.fillColor = geojsons[u].defaultColor;
                          }
                        }
                        styles.weight = 1;
                        if (feature && selectedFeature && feature.id === selectedFeature.id) {
                          styles.fillOpacity = 1;
                          styles.opacity = 1;
                          styles.weight = 5;
                          styles.color = '#ffffff';
                        }
                        if (feature?.geometry?.type === 'LineString') {
                          styles.color = geojsons[u].defaultColor;
                          styles.weight = 3;
                        }
                        return ({
                          ...styles,
                        });
                      }}
                    />
                  )
                }
                return <Fragment key={idx} />;
              })
            }
            {
              (loadedOrthophotos && loadedOrthophotos.length) &&
              loadedOrthophotos.map((o, idx) => {
                const zIndex = oIndexes.length - 1 - oIndexes.indexOf(o.id);
                if (activeOrthophotos.includes(o.id)) {
                  return (
                    <TileLayer
                      key={o.id}
                      url={o.url}
                      tms={o.tms}
                      zIndex={zIndex + 1002}
                      maxZoom={maxZoom}
                      minZoom={minZoom}
                    />
                  );
                }
                return <Fragment key={idx} />;
              })
            }
            <LayersControl position="topright">
              <BaseLayer checked name="Google Maps">
                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{
                    apiKey: (process.env.REACT_APP_GOOGLE_API_KEY) ? process.env.REACT_APP_GOOGLE_API_KEY : '',
                  }}
                  zIndex={1}
                />
              </BaseLayer>
              <BaseLayer name="Google Maps Satélite">
                <ReactLeafletGoogleLayer 
                  googleMapsLoaderConf={{
                    apiKey: (process.env.REACT_APP_GOOGLE_API_KEY) ? process.env.REACT_APP_GOOGLE_API_KEY : '',
                  }}
                  zIndex={1}
                  type="satellite"
                />
              </BaseLayer>
              <BaseLayer name="OpenStreetMap">
                <TileLayer
                  attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  zIndex={1}
                  maxZoom={maxZoom}
                  minZoom={minZoom}
                />
              </BaseLayer>
            </LayersControl>
            <ZoomControl position="topright" />
            {mapEditCapaJSX}
            <MapMarkAddress
              lat={latitude}
              lng={longitude}
              triggerViewportChange={triggerViewportChange}
              defaultZoom={defaultZoom}
            />
            <MapResetView
              lat={defaultLatitude}
              lng={defaultLongitude}
              triggerViewportReset={triggerViewporReset}
              defaultZoom={defaultZoom}
            />
            {
              showGeocodeMarker &&
              <Marker position={[latitude, longitude]}>
                <Popup>{geocodingFormattedAddress}</Popup>
              </Marker>
            }
          </MapContainer>
        </div>
        <MapFeatureDrawer
          visible={featureDrawerVisible}
          setVisible={setFeatureDrawerVisible}
          selectedFeature={selectedFeature}
          selectedGeojson={selectedGeojson}
          map={map as MapSchema}
          loadedGeojsons={loadedGeojsons as GeojsonSchema[]}
          registriesObject={registriesObject}
          setRegistriesObject={setRegistriesObject}
          setSelectedFeature={setSelectedFeature}
          onClose={() => { setFeatureDrawerVisible(false); setSelectedFeature(undefined); }}
        />
        <MapInfo
          visible={(visibleDrawer === 'INFO')}
          onClose={() => { setVisibleDrawer(''); }}
          map={map as MapSchema}
        />
        <MapNotes
          visible={(visibleDrawer === 'NOTES')}
          onClose={() => { setVisibleDrawer(''); }}
          map={map as MapSchema}
        />
        <MapActivity
          visible={(visibleDrawer === 'ACTIVITY')}
          onClose={() => { setVisibleDrawer(''); }}
          map={map as MapSchema}
        />
        <MapSelectedLayer
          visible={mapClassificationsVisible}
          setVisible={setMapClassificationsVisible}
          selectedGeojson={selectedGeojson}
          selectedClassifications={selectedClassifications}
          setSelectedClassifications={setSelectedClassifications}
          selectedFiltros={selectedFiltros}
          setSelectedFiltros={setSelectedFiltros}
          opacidad={opacidad}
          setOpacidad={setOpacidad}
          registriesObject={registriesObject}
          setRegistriesObject={setRegistriesObject}
          registriesObjectVisible={registriesObjectVisible}
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
          map={map as MapSchema}
          setFeatureDrawerVisible={setFeatureDrawerVisible}
          onClose={() => setMapClassificationsVisible(false)}
        />
        <MapCapas
          active={capasSelectActive}
          setActive={setCapasSelectActive}
          orthophotos={loadedOrthophotos}
          loadedGeojsons={loadedGeojsons}
          setLoadedGeojsons={setLoadedGeojsons}
          activeOrthophotos={activeOrthophotos}
          setActiveOrthophotos={setActiveOrthophotos}
          activeGeojsons={activeGeojsons}
          setActiveGeojsons={setActiveGeojsons}
          oIndexes={oIndexes}
          setOIndexes={setOIndexes}
          gIndexes={gIndexes}
          setGIndexes={setGIndexes}
          selectedCapa={selectedCapa}
          setSelectedCapa={setSelectedCapa}
          setSelectedGeojson={setSelectedGeojson}
          geojsons={geojsons}
          selectedClassifications={selectedClassifications}
          map={map}
        />
      </div>
    </div>
  );
}
