import { useEffect, Fragment } from 'react';
import { useMap } from 'react-leaflet';

export default function MapMarkAddress({
  lat,
  lng,
  triggerViewportChange,
  defaultZoom,
}: {
  lat: number;
  lng: number;
  triggerViewportChange: number;
  defaultZoom: number;
}) {
  const map = useMap();

  useEffect(() => {
    map.flyTo([lat, lng], defaultZoom);
  }, [lat, lng, map, triggerViewportChange, defaultZoom]);

  return <Fragment />;
}
